import React  from 'react';
import {Link} from 'react-router-dom';

const imageNames = {
    golang: require('../assets/img/golang3.gif'),
    k8s: require('../assets/img/k8s.gif'),
    dockerlogo:require('../assets/img/dockerlogo.jpg'),
    strumia:require('../assets/img/physics/strumia/strumia.jpg'),
    flag:require('../assets/img/flag.jpg'),
    gauss:require('../assets/img/physics/gauss/gauss.jpg'),
    dirac:require('../assets/img/physics/bits/dirac.jpg'),
    boole2:require('../assets/img/physics/bits/George_Boole2.jpg'),
    foleybook:require('../assets/img/physics/foleyrot/cpgraphics.gif'),
    angular:require('../assets/img/angular.png'),
    orion:require('../assets/img/physics/orion/orionboon4.jpg'),
    magrealitybw:require('../assets/img/magreal/magrealbw.gif'),
    casimir:require('../assets/img/physics/casimir/casimir2.gif'),
    mossbauer:require('../assets/img/physics/mossbauer/mossbauer_opt2.jpg'),
    react:require('../assets/img/react1.gif'),
    elephant:require('../assets/img/Postgresql/Elephant.gif'),
    dotnetcore:require('../assets/img/Postgresql/DotNetCore.jpg'),
    linuxsql1:require('../assets/img/LinuxSQLServer/1.jpg'),
    khan1:require('../assets/img/khan1.jpg'),
    scottishcafe:require('../assets/img/Scottish_Cafe.jpg'),
    tuvabust:require('../assets/img/tuvabust.jpg'),
    hamilton:require('../assets/img/Hamilton.jpg'),
    ufimtsev:require('../assets/img/ufimtsev_opt.jpg'),
    eltoncover:require('../assets/img/rew1.jpg'),
    johnreynolds:require('../assets/img/JohnReynolds.png'),
    townes:require('../assets/img/Townes_opt.gif'),
    rabi:require('../assets/img/rabi_opt.gif'),
    georgew:require('../assets/img/GeorgeW.jpg'),
    santa:require('../assets/img/Santa2.jpg'),
    glauber:require('../assets/img/Glauber1.jpg'),
    turkey:require('../assets/img/turkey1.jpg'),
    shakespeare:require('../assets/img/Shakespeare.jpg')
 };

const ArticleGeneric =(props) => {
        return (<article className="blog-teaser">
                <header>
                  <img src={imageNames[props.ImageName]} alt=""/>
                   {props.Link}
                  <h3><Link to={props.ReadMoreTo}>{props.ReadMoreToText}</Link></h3> 
                 <hr/>                         
                </header>
                  <div className="body">
                    {props.Body}
                  </div>
                  <div className="clearfix"  >
                    <Link style={{display:props.displaybutton}}  className="btn btn-tales-one" to={props.ReadMoreTo}>Read more</Link>
                  </div>
                </article> );
    }

 
export default ArticleGeneric;
