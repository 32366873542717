import React  from 'react';


const GlauberDetails =(props) => {
    return (
        <div className="widewrapper main">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 blog-main">
                        <article className="blog-post">
                            <header>
                                <h1>Roy Glauber</h1>
                                <div className="lead-image">
                                    <img src={require("../assets/img/Glauber1.jpg")} alt="Roy Gauber"
                                         className="img-responsive imgframe img-rounded"/>
                                        <span></span>
                                </div>
                            </header>
                            <div className="body">
                                <h3>Photon Interference</h3>
                                <div className="indented">
                                    I have a weakness in my left eye, and recently was sitting in my ophthalmologist’s
                                    office getting my eyes examined.
                                    A technician was discussing with his colleague how to record the results of one of
                                    the measurements, and they decided to express
                                    the answer in <i>diopters</i>.
                                </div>
                                <div className="indented">
                                    Well that was very informative to the patient!
                                    In optics, diopters are a mathematically convenient method of expressing the focal
                                    length of a compound lens.
                                    So, I at least could make an educated guess as to why I had been asked to peer into
                                    one end of the ophthalmologist’s device while
                                    he, on the other end, was bringing into focus an image of a cross. I surmised that
                                    my eye and the device formed a compound lens, and using the known focal
                                    length of the device, he could calculate the focal length of my left eye’s lens
                                    (assuming he could get a sharp enough image).
                                </div>
                                <div className="indented">
                                    One of the benefits of a scientific education is you can bring to your ailments a
                                    detached attitude. I remember that Enrico Fermi, when he was
                                    sick and dying of cancer (due to the exposure to radiation in his work, presumably),
                                    took meticulous notes of his condition because
                                    he thought they could be useful to his and future doctors.
                                </div>
                                <div className="indented">
                                    So the trip to the ophthalmologist got me thinking about optics, which in turn
                                    reminded me of the work of the great Roy Glauber, who got his Nobel for work in
                                    quantum optics. While Glauber's work in quantum optics is well known, he also
                                    authored an incredibly insightful, (albeit seldom mentioned), letter in the American
                                    Journal Physics which clarified a mistaken claim sometimes made about photons --
                                    that photons interfere with themselves.
                                </div>
                                <div className="indented">

                                    The incorrect notion that a photon interferes with itself is often found in books on
                                    quantum computing.
                                    Photon interference is used to illustrate how interference in quantum mechanics
                                    differs from the classical case.
                                    Fair enough, but as stated most incorrectly state that a photon can interfere with
                                    itself, and one book gets even weirder by saying
                                    that it is the photon paths that interfere.
                                </div>
                                <div className="indented">
                                    Evidently, none of the physicist authors or co-authors were aware of Roy Glauber's
                                    January 1995 letter to the editor in
                                    the American Journal of Physics which clarified photon interference once and for
                                    all. I now partly quote from Glauber's letter:
                                    Glauber first identifies the source of the confusion, the great Dirac:
                                </div>
                                <div className="indented">


                                    <div style={{color: "darkslategray", paddingBottom: "15px"}}>"The source of
                                        trouble is a statement by Dirac...'Each photon interferes only with itself.
                                        Interference between two different photons can never occur.'"
                                    </div>
                                    And Glauber ends by summarizing what really happens:
                                    <div style={{color: "darkslategray",paddingTop: "15px"}}>
                                        "The things that interfere in quantum mechanics are not particles. They are
                                        probability amplitudes for certain events.
                                        It is the fact that probability amplitudes add up like complex numbers that is
                                        responsible for all quantum mechanical interferences.
                                        When the event in question represents the detection of a single photon, one can
                                        easily slide into the statement that the photon is interfering with itself. When
                                        one deals, on the other hand, with two-photon states, one still encounters many
                                        species of interference effects, but it would generate intolerable confusion to
                                        interpret these photons as interfering with one another. It is not difficult,
                                        for example, by means of non-linear optics, to construct situations in which
                                        interference takes place between an amplitude for a single blue photon and an
                                        amplitude for another state with two red photons. Quantum mechanics allows such
                                        interferences and a great many other varieties as well, but no one could
                                        sensibly speak of photons as interfering with themselves or any others in that
                                        sort of context.
                                        To sum it all up, these three papers are out in left field. It is time to put
                                        the famous dictum to rest,
                                        to remember Dirac and to honor him for his wonderful contribution to physics,
                                        and to forgive him for writing
                                        down in the early days of quantum mechanics a highly simplistic remark which has
                                        sowed confusion among physicists ever since"
                                    </div>
                                </div>
                                <div className="indented">
                                    Enough said. Photons do not interfere with themselves, no matter how many quantum
                                    computing books claim otherwise.
                                </div>
                            </div>
                        </article>
                    </div>
                    <aside className="col-md-4 blog-aside">
                        <div className="aside-widget">
                            <header>
                                <h3>Related</h3>
                            </header>
                            <div className="body">
                                <ul className="tales-list">
                                    <li><a href="https://www.nobelprize.org/prizes/physics/2005/glauber/facts/"
                                           target="_blank" rel="noopener noreferrer">Roy Glauber</a></li>
                                </ul>
                            </div>
                        </div>
                    </aside>
                </div>
            </div>
        </div>
    )
        ;
}
export default GlauberDetails;
