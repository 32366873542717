import React  from 'react';


const UfimtsevDetails =(props) => {
    return (
        <div className="widewrapper main">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 blog-main">
                        <article className="blog-post">
                            <header>
                                <h1>Pytor Ufimtsev</h1>
                                <div className="lead-image">
                                    <img  src={require("../assets/img/Ufimtsev/ufimtsev_opt.jpg")} alt="Ufimtsev"
                                         className="img-fluid img-responsive imgframe img-rounded"/>

                                </div>
                                <div><b>Advances in Diffraction Theory</b></div>
                            </header>
                            <div className="body">
                                <div id="content">
                                    <div className="tuva">
                                        <i>If you look at the shadow cast by an object</i>, you will see, if you look
                                        closely enough, variations in brightness in the shadow.
                                        These variations of light and dark cannot be explained by assuming a straight
                                        line propagation of light rays.
                                        This phenomenon of "diffractio", or diffraction of light, as we now call it, was
                                        first mentioned by Francesco Grimaldi in the 17th century.

                                    </div>
                                    <div className="pgImage">
                                        <figure>
                                            <img src={require("../assets/img/Ufimtsev/diffraction1.gif")} className="img-fluid img-circle" alt=""/>
                                                <figcaption>Source: "Optics" by Eugene Hecht and Alfred Zajac
                                                </figcaption>
                                        </figure>
                                    </div>
                                    <div className="tuva">
                                        The basic equations that describe diffraction, while easy to write down,
                                        are notoriously difficult to <button type="button" className="btn btn-link"
                                                                             data-toggle="modal" data-target="#myModal"
                                                                             style={{padding:"0px"}}>solve
                                        exactly</button> , and exact closed form solutions are rare.
                                        In the late 1950s, the Russian scientist Ufimtsev developed and expanded the
                                        earlier work
                                        of H.M. MacDonald (cf. <a
                                        href="http://www.archive.org/details/electricwavesbei00macdrich"
                                        target="_blank"  rel="noopener noreferrer">
                                        Electric Waves
                                    </a> and also <a href="http://plms.oxfordjournals.org/cgi/reprint/s2-10/1/91"
                                                     target="_blank"  rel="noopener noreferrer">
                                        here
                                    </a> where MacDonald's Physical Optics was first introduced), and developed a
                                        powerful <em>
                                        asymptotic
                                    </em> method for solving high-frequency diffraction problems.
                                    </div>
                                    <div className="tuva">
                                        This new method was particularly suited to stealth design,
                                        since it enabled the far field diffraction pattern of radar waves from airplanes
                                        to be calculated.
                                        In particular, it was now possible to calculate what shapes would NOT reflect
                                        the radar waves back to their source, and hence would be undetectable by radar.
                                    </div>
                                    <div className="tuva">
                                        Surprisingly, Ufimstev's work was all but ignored in the Soviet Union,
                                        but not so in the US. <b>After the US Air Force had translated his work from the
                                        original Russian</b>,
                                        it was read by Denys Overholser, a radar specialist who realized its practical
                                        implications...and in fact, in a matter
                                        of months after he had read the paper, he had translated it into a computer
                                        program, ready for stealth design at the
                                        fabled "Skunk Works" shop. Both the Lockheed F-117 Stealth Fighter and the
                                        Northrop B-2 Stealth Bomber were designed with the aid of Ufimtsev's approach.
                                    </div>
                                </div>
                            </div>
                            {/* The Modal */}

                            <div className="modal" id="myModal">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        {/* The Modal Header*/}
                                        <div className="modal-header">
                                            <h4 className="modal-title">Sommerfeld's Exact Solution</h4>
                                            <button type="button" className="close"
                                                    data-dismiss="modal">&times;</button>
                                        </div>

                                        {/*  <!-- Modal body -->*/}
                                        <div className="modal-body">
                                            <p>
                                                <strong> Exact solutions to problems in diffraction theory are
                                                    rare</strong>.

                                                In 1894,
                                                <a href="Sommerfeld.htm"
                                                   onClick="window.open('http://en.wikipedia.org/wiki/Arnold_Sommerfeld', 'Sommerfeld', ''); return false">Sommerfeld</a>
                                                succeeded in solving <em>exactly</em> the problem of diffraction from a
                                                half-plane. This
                                                was truly an amazing result.<br/>
                                            </p>

                                            <p>
                                                A (now) standard method of solving problems in electromagnetics is
                                                by using what are known as "image charges". The idea is that by
                                                adding <em>
                                                new fictitious charges
                                            </em>(i.e., the image charges) to the original problem, <em>
                                                and in a way so as to satisfy the boundary conditions of the original
                                                problem
                                            </em>, we can sometimes replace the original problem with a much easier, but
                                                equivalent
                                                problem.<br/>
                                            </p>
                                            <p>
                                                What makes the original Sommerfeld solution so outstanding
                                                is that while it is impossible to introduce an image charge(s) anywhere
                                                in real
                                                space so that boundary conditions are satisfied, Sommerfeld discovered
                                                it is possible
                                                to introduce the image charge in the second space of a
                                                <em><b>Riemann double space</b>. </em>It should also be remembered
                                                Riemann had only recently
                                                introduced the concept of Riemann surfaces in his 1851 Ph.D. thesis...
                                            </p>
                                        </div>
                                        {/*  <!-- Modal footer -->*/}
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-primary"
                                                    data-dismiss="modal">Close
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </article>

                    </div>
                    <aside className="col-md-4 blog-aside">
                        <div className="aside-widget">
                            <header>
                                <h3>Related</h3>
                            </header>
                            <div className="body">
                                <ul className="tales-list">
                                    <li><a
                                        href="https://lockheedmartin.com/en-us/who-we-are/business-areas/aeronautics/skunkworks.html"
                                        target="_blank"  rel="noopener noreferrer">Skunk Works</a></li>
                                </ul>
                            </div>
                        </div>
                    </aside>
                </div>
            </div>
        </div>
    )
        ;
}
export default UfimtsevDetails;
