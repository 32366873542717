import React  from 'react';


const FeynmanDetails =(props) => {
    return (
        <div className="widewrapper main">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 blog-main">
                        <article className="blog-post">
                            <header>
                                <h1>Feynman <i>Corrected</i></h1>
                                <div className="lead-image">
                                    <img src={require("../assets/img/Tuva/tuvabust.jpg")} alt="tuva"
                                         className="img-fluid img-responsive imgframe img-rounded"/>

                                </div>
                            </header>
                            <div className="body">
                                <div id="content">
                                    <div className="tuva">
                                        Long before there was video games, and long before there was
                                        <em>texting</em>, and long before the advent of the internet
                                        and <em>email</em>, kids used to engage in an activity called<em> stamp
                                        collecting...</em>stamps being
                                        the method we used to pay for snail mail.<br/>
                                    </div>
                                    <div className="tuva">
                                        As a kid, Feynman also used to collect stamps. Ninety nine percent of all
                                        stamps were rectangular or square, but the country formerly known as Tannu Tuva
                                        used to issue
                                        triangular and diamond shaped stamps, which made them even <em>more
                                        interesting</em>
                                        to kids -- and Feynman.&nbsp;
                                    </div>

                                    <div className="pgImage">
                                        <img src={require("../assets/img/Tuva/tuvastamp90.jpg")} alt="tuva"
                                             className="img-fluid img-responsive"/>
                                    </div>

                                    <div className= "tuva">
                                        When in 1977 he and friend Ralph Leighton discovered
                                        that the
                                        capital of Tuva was spelled K-Y-Z-Y-L (Leighton: "That's crazy. There's
                                        not a legitimate vowel anywhere!"&nbsp; Feynman: "A place that's spelled
                                        K-Y-Z-Y-L
                                        has just got to be interesting!"), they decided they must make a journey to
                                        Soviet
                                        controlled Tuva. The journey ended up taking 10 years to complete, and is told
                                        in Leighton's book
                                        "Tuva or Bust!" and also mentioned in the play "QED: a play".
                                    </div>
                                    <div className="tuva">
                                        &nbsp; &nbsp; In 1980, Leighton received a letter from Tuva -- in the Tuvan
                                        language,
                                        which neither he or Feynman spoke. However, they did have a Tuvan-Russian
                                        dictionary,
                                        and a Russian-English dictionary, and so armed, Feynman and Leighton attempted
                                        to
                                        translate...<br/>
                                    </div>

                                    <div className="tuva">
                                        Leighton describes the process of translation:
                                        <br/>
                                        <span style={{color: "#004d00"}}>
                                    &nbsp; &nbsp;&nbsp; "The third sentence came out 'Me Darma
                                    Ondar called, forty-five snowy I.'&nbsp;<br/>
                                    We couldn't make head nor tail of 'forty-five snowy I<span
                                            style={{color: "#000000"}}>'</span>.
                                    <br/>
                                            &nbsp; &nbsp;&nbsp; 'Imagine you were a Navajo living on a reservation in New Mexico,'
                                    said Richard, beginning to laugh. 'And one day, out of the blue, you get this letter
                                    written in broken Navajo from a guy in Russia using a Navajo-Spanish-English phrasebook
                                    that he got translated into Russian by a friend of his. So you write back to him
                                    in <em>real</em> Navajo...'
                                    <br/>
                                            &nbsp; &nbsp;&nbsp; "No wonder it's hard to read real Tuvan," I said. Then Richard
                                    suddenly said, 'Hey, I've got it: the guy is forty-five years old.'
                                    <br/>
                                            &nbsp; &nbsp;&nbsp; It made perfect sense. It was something like saying, 'I have
                                    survived-five winters' -- an apt phrase for Tuva, which lies between Siberia and
                                    Mongolia.
                                    <br/>
                                            &nbsp; &nbsp;&nbsp; We checked the dictionaries again. There was a second definition
                                    for 'snowy' that came out <em><span style={{color: "#ff0066"}}>letnii</span></em> in
                                    Russian -- 'summer' in English! &nbsp; &nbsp;
                                            <br/>
                                            &nbsp; &nbsp;&nbsp; 'Winters, summers, what does it matter?' said Richard. 'It still
                                    could mean he has lived forty-five years'.
                                    <br/>
                                            &nbsp; &nbsp;&nbsp; Then I looked carefully through the phrasebook again. At the
                                    bottom of page 32 was the question 'How old are you?' And at the top of page 33
                                    was the answer: 'dorten besh kharlyg men' -'forty-five snowy I'"<br/>
                                </span>

                                    </div>
                                    <div className="tuva">

                                        ....Well, sad to say, the "Man of a Thousand Tongues", as Feynman describes
                                        himself
                                        in one of his Feynman stories, <strong>got this one wrong. </strong>So, let's
                                        take
                                        a look and see what Feynman missed...To do this, you only need to learn a few
                                        Russian
                                        words! The Russian for "<em>year"</em> and the Russian for "<em>summer</em>",
                                        plus
                                        a little grammar.

                                    </div>
                                    <br/>

                                    <h5>RUSSIAN WORDS FOR <em>YEAR</em></h5>
                                    <div className="tuva">

                                        &nbsp;&nbsp; First, the Russian word for "year", <em>changes,</em> depending on
                                        the number of years. So, for example:
                                        <br/>
                                        1 year is "godt"<br/>
                                        2,3, or 4 years is "2 godta", "3 godta", and "4 godta"<br/>
                                        and for more than 4 years, we do not use godt, but instead the word "let". So
                                        according
                                        to this rule, 5 years would be "5 <span
                                        style={{color: "#ff0066"}}>let</span>"...<br/>
                                        and 40 years is 40 let, 41 years is 41 godt, 42 years is 42 godta, 43 years is
                                        43
                                        godta, 44 years is 44 godta...while 45 years is 45 <span
                                        style={{color: "#ff0066"}}>let.</span><br/>
                                        <br/>
                                        Now, here is the <em><strong>first obstacle</strong></em> that Feynman and
                                        Leighton
                                        would have to overcome...When one looks up "year" in a Russian-English
                                        dictionary,
                                        one normally finds only "godt", and not the second word "let".<br/>


                                    </div>
                                    <br/>
                                    <h5>RUSSIAN WORD FOR <em>SUMMER</em></h5><br/>
                                    <div style={{border:"solid 1px",backgroundColor: "antiquewhite",paddingLeft: "7px"}}>

                                        &nbsp; &nbsp; &nbsp;
                                        The Russian word for "summer" is "<span
                                        style={{color: "#ff0066"}}>leta</span>".<br/>

                                    </div>
                                    <br/>
                                    <h5> RUSSIAN GRAMMAR -- adjectives!</h5>
                                    <div className="tuva">

                                        Here we come to the <em><strong>second obstacle</strong></em> that Feynman
                                        and Leighton would have to overcome, and it is non-trivial and it is usually NOT
                                        emphasized in Russian language text books -- (I have many such books and have
                                        yet to
                                        see it mentioned, but I hear the usage all the time on Russian TV. So, somehow
                                        it is not stressed, but more on that later)...<br/>
                                    </div>
                                    <div style={{border:"solid 1px",backgroundColor: "antiquewhite",paddingLeft: "7px"}}>
                                        The adjective form of "summer" and the adjective form of "year"
                                        are IDENTICAL -- <span style={{color: "#ff0066"}}>letnii</span>
                                    </div>
                                    <div className="tuva">
                                        So, if I want to say "summer day", I say "letnii day" (if you want the Russian
                                        exactly:
                                        <span style={{color: "#ff0066"}}>летний</span> день)<br/>

                                        If I want to say "I am a 45 year old man", I can say "I 45 letnii
                                        man", (or in exact Russian: я 45 <span
                                        style={{color: "#ff0066"}}>летний</span> мужчина)...OK,
                                        to be precise, the Russian grammar <em>also</em> requires a change to the word
                                        45
                                        --and so <em>sorok</em> changes to <em>soroka</em>, but that is not relevant to
                                        Feynman's error.)

                                    </div>

                                    <div className="tuva">
                                        &nbsp; &nbsp;&nbsp; Again, as mentioned, <strong>the same
                                        adjective</strong>, <span style={{color: "#ff0066"}}>летний</span> or
                                        <span style={{color: "#ff0066"}}>
                                    letnii,
                                    <span style={{color: "#666666"}}>
                                        is used, and the listener/reader must determine from the
                                        <em>context</em> whether it is "summer" or "yearly" that is intended. Feynman and
                                        Leighton picked the wrong word -- "summer" instead of "yearly"!<br/>
                                        <br/>
                                        &nbsp; &nbsp; The<strong> final obstacle</strong> that Feynman and Leighton would
                                        have had to overcome, and also the reason I believe it is not easily found in the Russian
                                        textbooks... is that English does not have an adjective form of year equivalent
                                        to the Russian adjective form of year...<br/>
                                        &nbsp; &nbsp; So, in English, we use the adjective "yearly" thus -- "a yearly event"
                                        -- to indicate the <strong>frequency</strong> of something, but never as Russians
                                        do --<br/>
                                        " 45 yearly man"...to describe an objects <strong>age</strong>.
                                    </span>
                                </span>

                                    </div>

                                </div>
                            </div>

                        </article>
                    </div>
                    <aside className="col-md-4 blog-aside">
                        <div className="aside-widget">
                            <header>
                                <h3>Related</h3>
                            </header>
                            <div className="body">
                                <ul className="tales-list">
                                    <li><a href="http://www.feynman.com/fun/tuva/tuva-or-bust/" target="_blank" rel="noopener noreferrer">Tuva or
                                        Bust</a></li>
                                </ul>
                            </div>
                        </div>
                    </aside>
                </div>
            </div>
        </div>
    )
        ;
}
export default FeynmanDetails;
