import React  from 'react';
//import {Link} from 'react-router-dom';



const Mossbauer =(props) => {
    return (
    
<div className="widewrapper main">
    <div className="container">
        <div className="row">
            <div className="col-md-8 blog-main">
                <article className="blog-post">
                    <header>
                        <h1>The Mossbauer Effect</h1>
                        <div className="lead-image">
                            <img src={require("../assets/img/physics/mossbauer/mossbauer_opt.gif")} alt="Mossbauer" className="img-responsive imgframe img-rounded"/>
                        </div>
                    </header>
                    <div className="body">
                        <div id="content">
                            <p>
                                If you look at table salt (NaCl) being heated by a Bunsen burner flame, you will see a very bright yellow color in the resulting flame.
                                This yellow is a characteristic footprint of the element sodium, Na, of which salt, NaCl, is comprised.
                                <br /> <br />
                                <img src={require("../assets/img/physics/mossbauer/sodium2.jpg")} alt="sodium flame" />
                                <br />  <span>Sodium flame</span>
                            </p>
                            <p>
                                The more general technique of looking at the spectrum of light emitted (and absorbed) by a substance to identify its constituent atoms
                                and molecules is known as <i>spectroscopy</i>.
                            </p>
                            <p>
                                The light is generated when the atom makes a transition from a high energy state to a lower energy state,
                                and the difference in energy is converted into a photon (*).  Typically, it is the electrons
                                in the atom that undergo a transition, but other types of transitions are possible, as we shall see.
                            </p>

                            <p>
                                If an atom makes a transition from a state of energy E<sub>initial</sub> to a lower state of energy E<sub>final</sub>,
                                the photon that is emitted can have a frequency given by w = (E<sub>intial</sub> - E<sub>final</sub>)/hbar
                                where hbar is Planck's constant divided by 2 pi :
                                <br /><br />
                                <img src={require("../assets/img/physics/mossbauer/transition1.gif")} alt="" />
                                <br />
                                <span>Photon Emission</span>
                            </p>

                            <p>
                                Arbitrary transitions are not allowed, but only those permitted by the rules of
                                quantum mechanics, and so each element has a distinct spectrum that correspond to
                                allowed transitions between its energy states. It is for this reason that we can
                                use light spectra to uniquely identify an element.
                                Here for example is the emission spectrum of sodium (Na):
                                <br /><br />
                                <img src={require("../assets/img/physics/mossbauer/NaEmission2.gif")} alt="" />
                                <br />
                                <span > Sodium line spectrum</span>
                            </p>

                            <p>
                                Notice the lines have a finite thickness, which corresponds to an uncertainty in the frequency of the measured light.
                                There can be a number of causes for this "line broadening". <i>Natural line broadening</i>, which is unavoidable, is a result
                                of the fundamental limitation imposed by the Heisenberg  time-energy uncertainty principle. Other sources of broadening are
                                <i>Doppler</i> broadening and  <i>pressure</i> broadening.
                            </p>

                            <p>
                                Typically, the light emitted is due to the electrons undergoing a transition in the atom.
                                However, it is also possible the change in energy of the atom is due to a <b><i>nuclear</i></b> transition.
                            </p>

                            <p>
                                For nuclear transitions, the photons will normally have a larger energy (typically, the photons will correspond to gamma rays) and
                                so will have a larger momentum than the photons emitted due to electronic transitions.
                                Since conservation of energy and momentum must be obeyed, this large momentum introduces a
                                new source of broadening -- not all of the E<sub>initial</sub> - E<sub>final</sub> energy is available for the
                                emitted photon, but some of it must go to the kinetic energy of the nucleus. This transfer
                                of energy to the nucleus represents another source of line broadening.
                            </p>

                            <p>
                                <b><i>Amazingly</i></b>, Mossbauer discovered in the case of atoms bound in a crystal lattice, it is possible to have what
                                in effect appears as a <i>"<b>recoilless</b> emission"</i> of gamma rays from the nucleus, so that
                                we don't get this broadening.
                            </p>
                            <p>
                                The Mossbauer Effect was discovered by Mossbauer in 1957 (although Willis Lamb had predicted it much earlier)
                                and for this he received the Nobel Prize in 1961.
                                The Mossbauer Effect is a powerful tool in a variety of branches of physics, including general relativity.
                                <a href="http://marsrover.nasa.gov/mission/spacecraft_instru_mossbr.html" target="_blank" rel="noopener noreferrer">Here</a> and
                                also <a href="http://athena.cornell.edu/the_mission/ins_moss.html" target="_blank" rel="noopener noreferrer">here</a> you can see Mossbauer spectroscopy being used to study the Martian soil
                                and atomospheric dust from data being sent back from the Mars Exploration Rover Spirit.
                            </p>

                            <hr />
                            * There is still one other way light in which light may be "emitted" by an atom, and that is
                            by the process of  <a href="elasticscattering.html" target="_blank">
                                <em>elastic scattering</em>
                            </a> of a photon from the atom.
                        </div>
                    </div>
                </article>

            </div>
            <aside className="col-md-4 blog-aside">

                <div className="aside-widget">
                    <header>
                        <h3>Related</h3>
                    </header>
                    <div className="body">
                        <ul className="tales-list">
                            <li><a href="http://www.nobelprize.org/nobel_prizes/physics/laureates/1961/mossbauer-bio.html" target="_blank" rel="noopener noreferrer">Mossbauer Nobel Prize 1961</a></li>
                            <li><a href="https://en.wikipedia.org/wiki/Gamma_ray" target="_blank" rel="noopener noreferrer">Gamma Rays</a></li>
                        </ul>
                    </div>
                </div>
            </aside>
        </div>
    </div>
</div>


    )
    ;
};

export default Mossbauer;