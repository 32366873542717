import React  from 'react';


const EltonDetails =(props) => {
    return (
        <div className="widewrapper main">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 blog-main">
                        <article className="blog-post">
                            <header>
                                <h1>To Russia With Elton</h1>
                                <div className="lead-image">
                                    <img src={require("../assets/img/Elton/rew1.jpg")} alt="To Russia With Elton"
                                         className="img-fluid img-responsive imgframe img-rounded"/>
                                        <span></span>
                                </div>
                            </header>
                            <div className="body">
                                <h3>40 Years On...</h3>
                                <div className="indented">
                                    Elton John, in 1979, became the first western rock star to tour Russia. This was
                                    before
                                    the days of Perestroika; the USSR had not yet broken up, and Russian communism was
                                    still strong.
                                    It has been remarked
                                    that musically, Elton was at a peak during this tour, and I think that is true.
                                    Having already conquered the known rock world, maybe the desire to win over this new
                                    (for him and his music) audience
                                    provided the necessary challenge.

                                </div>
                                <div style={{paddingTop: "15px",paddingBottom: "35px"}}>
                                    <img src={require("../assets/img/Elton/bjetts.jpg")} alt="TBenny and the Jets"
                                         className="img-fluid img-responsive"/>
                                </div>
                                <div className="indented">
                                    In the clip below of this concert, Elton, great showman and consummate pianist that
                                    he is,
                                    transitions (at :28 seconds in the video) into the Russian song <i><b>Podmoskovnye
                                    Vechera</b></i>
                                    during his performance of Benny and the Jets -- to the obvious delight of the
                                    Moscow audience. Those who have seen Elton in concert, or watched a concert DVD,
                                    know this is
                                    Elton's modus operandi when performing Benny and the Jets -- he always likes to
                                    add on an improvisational like (yet well rehearsed) extra piece for the live
                                    show.
                                </div>
                                <div style={{paddingTop: "30px",paddingBottom: "50px"}}>
                                    <div className="card" style={{width:"450px"}}>
                                        <div className="card-body">
                                            <h4 className="card-title">Elton John 1979 Russia Tour</h4>
                                            <p className="card-text">Elton John plays Podmoskovnye Vechera</p>
                                            <video width="400" controls>
                                                <source src={require("../assets/media/elt.mp4")} type="video/mp4"/>
                                                    Your browser does not support HTML5 video.
                                            </video>
                                        </div>
                                    </div>
                                </div>
                                <div className="indented">
                                    I suspect that Elton was emulating Van Cliburn's decision to play Podmoskovnye
                                    Vechera at the Tchaikovsky Competition in Moscow in 1958.
                                    While Elton's arrangement is fun and entertains the audience, Van Cliburn's virtuoso
                                    arrangement (see the link on the right, under "related") is far more difficult and
                                    acurately expresses the emotion expressed in the song.
                                </div>

                            </div>
                        </article>
                    </div>
                    <aside className="col-md-4 blog-aside">
                        <div className="aside-widget">
                            <header>
                                <h3>Related</h3>
                            </header>
                            <div className="body">
                                <ul className="tales-list">
                                    <li><a href="https://www.youtube.com/watch?v=vkxSgmoqpTs" target="_blank"  rel="noopener noreferrer">Van
                                        Cliburn at the Tchaikovsky Piano Competition, Moscow in 1958, plays Podmoskovnye
                                        Vechera</a></li>
                                    <li><a href="https://www.youtube.com/watch?v=qRQA9HeXcpI" target="_blank"  rel="noopener noreferrer">Dmitri
                                        Hvorostovsky sings Podmoskovnye Vechera</a></li>
                                    <li><a href="https://www.youtube.com/watch?v=t5SlUmCdXf0" target="_blank"  rel="noopener noreferrer">Dmitri
                                        Hvorostovsky and Anna Netrebko duet, Podmoskovnye Vechera</a></li>


                                </ul>
                            </div>
                        </div>
                    </aside>
                </div>
            </div>
        </div>
    )
        ;
}
export default EltonDetails;
