import React  from 'react';
//import {Link} from 'react-router-dom';



const FoleyRotation =(props) => {
    return (
<div className="widewrapper main">
    <div className="container">
        <div className="row">
            <div className="col-md-8 blog-main">
                <article className="blog-post">
                    <header>
                        <h1>3D Computer Graphics, Rotations, and Lazy Professors</h1>
                        <div className="lead-image">
                            <img  src={require("../assets/img/physics/foleyrot/foleybook.gif")} alt="" className="img-responsive imgframe" />
                            <br /> <br /> <br />
                           
                            
                        </div>
                    </header>
                    <div className="body">
                        <div>
                            <div className="indented">
                                A standard text on computer graphics is "Introduction to Computer Graphics"
                                by Foley, Van Dam, Feiner, Hughes, and Phillips.  Even though it was published in 1997,
                                it covers many core techniques that are still useful for writing graphics programs today.
                                No book is perfect, yet Foley's treatment of rotation matrices leaves something  to be desired. Depending
                                on one's interests, this may not be a major concern. But one can easily find lecture notes
                                (on the internet) for graduate level computer graphics courses that repeat Foley's treatment of
                                rotations almost verbatim. And that is just lazy -- at the graduate level one expects, well,
                                a graduate level presentation... so I am presenting a more mathematically correct treatment
                                of rotation matrices as they apply to the computer graphics pipeline.
                            </div>
                            <table border="0" width="700px">
                                <tr>
                                    <th  colspan="2">World Coordinates and the Viewing-Reference Coordinates</th>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        <div className="indented">
                                            The situation is we have two coordinate systems, shown below. (To fix ideas, we are thinking of the
                                            coordinates as elements of R<sup>3</sup>, <i>not</i> R<sup>4</sup> as a projective space.
                                            Then standard linear algebra applies.)
                                        </div>
                                        <p>
                                            <img src={require("../assets/img/physics/foleyrot/3D_Viewing.png")} alt="3D_Viewing" />
                                            <br />&nbsp;&nbsp;&nbsp;&nbsp;
                                        </p>
                                        <div className="indented">
                                            The <i>world coordinate</i> system is how our objects
                                            are originally specified in 3 dimensions. The <i>viewing-reference coordinate system's</i> claim to fame is that it
                                            contains the uv-plane, which
                                            is the <i>viewing plane</i> (or <i>projection plane)</i>. The viewing plane is of course 2 dimensional, and
                                            ultimately part of it will be used to represent the 2D computer screen on which the user will see the original
                                            3D world rendered. The viewing plane is defined by specifying a point on the
                                            plane, the view reference
                                            point (VRP), and a direction which will be taken as normal to the viewing plane, which in the diagram is
                                            indicated by the direction along the n-axis.
                                            The v-axis specifys the up direction, and is orthogonal to n. Once the n and v
                                            directions are known, then the u-axis is defined so that u,v, and n form a
                                            right-handed coordinate system.
                                        </div>
                                        <div className="indented">
                                            So, we will be working in the viewing reference coordinate system and thus we have to perform the
                                            following operations:
                                        </div>
                                        <ul>
                                            <li>
                                                Step 1: Translate the viewing-reference axes to the world coordinate axes <br />
                                                so they share the same origin.
                                            </li>
                                            <li>
                                                Step 2:   Rotate the viewing-reference axes so that the coordinate systems axes are parallel.
                                            </li>
                                        </ul>

                                        <div className="indented">
                                            The translation indicated in step 1 is routine, and the result is shown below:
                                            <br />
                                            <img src={require("../assets/img/physics/foleyrot/both.png")} alt="both" />
                                        </div>
                                        <div className="indented">
                                            Step 2 is what concerns us and is where Foley can be improved on. Our task is to figure out how
                                            to rotate the the view coordinate system into the world coordinate system.
                                        </div>
                                        <div className="indented">
                                            To define a linear transformation (and a rotation is a linear transformation)
                                            it is sufficient to specify its action on any basis. So,
                                            <br />
                                            <br />
                                            Let <b>i</b>,<b>j</b>,<b>k</b> be the standard ordered basis of R<sup>3</sup>.
                                            <br />
                                            Let <b>u</b>,<b>v</b>,<b>n</b> be unit vectors along the u,v, and n axes. They
                                            also are a basis of R<sup>3</sup>.<br />
                                            Then the rotation T: R<sup>3</sup> &rarr; R<sup>3</sup> is defined by
                                        </div>
                                        <p >
                                            <b>u</b> |&rarr; <b>i</b>, <br />
                                            <b>v</b> |&rarr; <b>j</b>, <br />
                                            <b>n</b> |&rarr; <b>k</b> <br />
                                        </p>
                                        <div className="indented">
                                            The first thing to note is we are done. That's it. T is completely specified.
                                            Given any vector <b>&alpha;</b> &isin; R<sup>3</sup>, we can expand it as
                                            <b>&alpha;</b> = a <b>u</b> + b <b>v</b> + c <b>n</b> ,
                                            &nbsp;and the rotation acting on <b>&alpha;</b> is then
                                        </div>
                                        <p >
                                            T(<b>&alpha;</b>) = T(a <b>u</b> + b <b>v</b> + c <b>n</b>)
                                            <br />
                                            = aT(<b>u)</b> + bT(<b>v</b>) + cT(<b>n</b>)
                                            <br />
                                            = a<b>i</b> + b<b>j</b> + c<b>k</b>

                                        </p>
                                        <div className="indented">
                                            The matrix A of a linear transformaion T:R<sup>n</sup> → R<sup>n</sup> relative to an ordered basis
                                            <b>&beta;</b> = <b>&beta;</b><sub>1</sub>,<b>&beta;</b><sub>2</sub>,...,<b>&beta;</b><sub>n</sub> is defined by
                                        </div>
                                        <p >
                                            T(<b>&beta;</b><sub>j</sub>) = &Sigma; A<sub>i</sub><sub>j</sub><b> &beta;</b><sub>i</sub>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  (j=1,2,..,n)
                                        </p>
                                        <div className="indented">
                                            Notice that the matrix of the transformation is defined by specifying the
                                            action of the transformation on a basis, and as I have already indicated, we
                                            have been given that in the problem statement.&nbsp; Incidentally
                                            this is the standard method, and it is not the method Foley uses. Foley&#39;s
                                            method will work to determine the matrix with respect to an

                                            <i>orthonormal</i> basis, but it does not work for an arbitrary basis. If you
                                            can guarantee your students are only going to work with rotations in R<sup>3</sup>,
                                            and only with orthonormal bases, and not arbitrary linear transformations and bases, then perhaps
                                            Foley might be OK, but that is quite an assumption. Better to learn how to do it in
                                            the general case.
                                        </div>
                                        <div className="indented">
                                            So, let&#39;s make the construction of the matrix A explicit:
                                        </div>
                                        <p >
                                            T<sup>-1</sup>(<b>i</b>)&nbsp; = u<sub>x</sub><b>i&nbsp;</b>&nbsp; + u<sub>y</sub><b>j </b>+ u<sub>z</sub><b>k</b>
                                            <br />
                                            T<sup>-1</sup>(<b>j</b>)&nbsp; = v<sub>x</sub><b>i&nbsp;</b>&nbsp; + v<sub>y</sub><b>j </b>+ v<sub>z</sub><b>k</b>
                                            <br />
                                            T<sup>-1</sup>(<b>k</b>) = v<sub>x</sub><b>i&nbsp;</b>&nbsp; + v<sub>y</sub><b>j </b>+ v<sub>z</sub><b>k</b>
                                        </p>
                                        <div className="indented">
                                            and so the matrix of T<sup>-1</sup> (not T, but the inverse of T) relative to the standard
                                            basis is given by:
                                        </div>
                                        <p >
                                            <img src={require("../assets/img/physics/foleyrot/matrix1.png")} alt="matrix" />

                                        </p>
                                        <div className="indented">
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            Again, that is the matrix of T<sup>-1</sup>, not T. But we want the matrix of T.
                                            To get that, we finally use the fact that rotations are othogonal transformations, which, by
                                            definition means that TT<sup>t</sup>= I, where T<sup>t</sup> is the transpose of T.
                                        </div>
                                        <div className="indented">
                                            Thus,
                                        </div>
                                        <p>
                                            T<sup>t</sup> = T<sup>-1</sup>
                                        </p>
                                        <div className="indented">and so,</div>
                                        <p >
                                            T=&nbsp; (T<sup>-1</sup>)<sup>t</sup>
                                        </p>
                                        <div className="indented">
                                            Finally, taking the transpose of the matrix above for T<sup>-1</sup>
                                            , we get the desired matrix for T:<br />
                                            <br />
                                        </div>
                                        <p >
                                            <img src={require("../assets/img/physics/foleyrot/matrix2.png")} alt="" />
                                        </p>
                                        <div className="indented">
                                            and that is the result Foley gets for the rotation matrix relative to the
                                            standard basis of R<sup>3</sup>.
                                        </div>
                                    </td>
                                </tr>

                            </table>
                        </div>
                    </div>
                </article>
            </div>
            <aside className="col-md-4 blog-aside">
                <div className="aside-widget">
                    <header>
                        <h3>Related...</h3>
                    </header>
                    <div className="body">
                        <ul className="tales-list">
                            <li>
                                <a href="http://www.amazon.com/Introduction-Computer-Graphics-James-Foley/dp/0201609215" target="_blank"  rel="noopener noreferrer"> Introduction To Computer Graphics</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </aside>
        </div>
    </div>
</div>



    )
    ;
};

export default FoleyRotation;