import React  from 'react';
//import {Link} from 'react-router-dom';



const DockerDetails =(props) => {
    return (
<div className="widewrapper main">
    <div className="container">
        <div className="row">
            <div className="col-md-8 blog-main">
                <article className="blog-post">
                    <header>
                        <h1>Docker</h1>
                        <div className="lead-image">
                            <img src={require("../assets/img/dockerlogo.jpg")} alt="docker" className="img-responsive imgframe img-rounded" />
                            <span>
                                
                            </span>
                        </div>
                    </header>
                    <div className="body">

                        <div className="indented">
                            One of the advantages of .Net core is not only is it lightweight (because you inject only the services you actually use), it also runs on Linux.
                            So, this site is now using .Net core in a Digital Ocean droplet running on an Ubuntu distro Docker container .
                        </div>

                        <div className="indented">
                            The Angular 6 version of this site has <i>also</i> been containerized, and may be found <a href="http://angular.larrymusa.com" target="_blank" rel="noopener noreferrer">here</a>.
                            So I have two containers, one running .Net core, the second running Angular, in the same Digital ocean droplet. The standard Nginx reverse proxy routes requests
                            to the appropriate container.
                        </div>

                        <div className="indented">
                            And at Digital Ocean's current $5 month, that is one of the best deals currently available for .Net core hosting <i>and</i> Angular hosting...
                        </div>

                    </div>
                </article>
            </div>
            <aside className="col-md-4 blog-aside">

                <div className="aside-widget">
                    <header>
                        <h3>Related</h3>
                    </header>
                    <div className="body">
                        <ul className="tales-list">
                            <li><a href="https://hub.docker.com/" target="_blank" rel="noopener noreferrer">Docker Hub</a></li>
                            <li><a href="https://www.digitalocean.com/" target="_blank" rel="noopener noreferrer">Digital Ocean</a></li>                      
                        </ul>
                    </div>
                </div>
            </aside>
        </div>
    </div>
</div>
    );
    }

export default DockerDetails;