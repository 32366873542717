import React  from 'react';


const ThanksgivingDetails =(props) => {
    return (
        <div className="widewrapper main">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 blog-main">
                        <article className="blog-post">
                            <header>
                                <h1>Happy Thanksgiving</h1>
                                <div className="lead-image">
                                    <img src={require("../assets/img/turkey1.jpg")} alt="Thanksgiving Turkey"
                                         className="img-responsive imgframe img-rounded" />
                                        <span></span>
                                </div>
                            </header>
                            <div className="body">
                                <blockquote> Gratitude is the fruit of great cultivation; you do not find it among gross
                                    people. <cite>-- Samuel Johnson</cite></blockquote>


                            </div>
                            <div>In the movie "Captain America: The First Avenger", the redskull makes an observation
                                about Captain America: (who in some sense personifies American virtue, at least in the
                                movie) "Arrogance may not be a uniquely American trait, but I must say, you do it better
                                than anyone."
                            </div>
                            <div>To that one might add, giving thanks is not a uniquely American trait, but we really do
                                do it better than any other country. Here is wishing everyone a Happy Thanksgiving!
                            </div>


                        </article>

                    </div>
                    <aside className="col-md-4 blog-aside">
                        <div className="aside-widget">
                            <header>
                                <h3>Related</h3>
                            </header>
                            <div className="body">
                                <ul className="tales-list">
                                    <li><a
                                        href="http://historicvoices.libsyn.com/s02-e05-hv-paul-harvey-historic-origins-of-pilgrims-thanksgiving-1620"
                                        target="_blank" rel="noopener noreferrer">Paul Harvey on the Pilgrims</a></li>
                                    <li><a href="https://founders.archives.gov/documents/Washington/05-04-02-0091"
                                           target="_blank" rel="noopener noreferrer">Thanksgiving Procalamation by George Washington</a></li>
                                    <li><a href="https://youtu.be/s10TGTWXp8I" target="_blank" rel="noopener noreferrer">Rush Limbaugh: True Story
                                        of Thanksgiving</a></li>
                                </ul>
                            </div>
                        </div>
                    </aside>
                </div>
            </div>
        </div>
    )
        ;
}
export default ThanksgivingDetails;
