import React  from 'react';
import {Link} from "react-router-dom";


const ContraDetails =(props) => {
    return (
        <div className="widewrapper main">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 blog-main">
                        <article className="blog-post">
                            <header>
                                <h1>Introduction</h1>
                                <div className="lead-image">
                                    <img src={require("../assets/img/Contr/JohnReynolds.png")} alt="John C Reynolds"
                                         className="img-fluid img-responsive imgframe img-rounded"/>
                                        <br/>
                                        <span>John C. Reynolds
                                </span>
                                </div>
                            </header>
                            <div className="body">

                                <div className="indented">
                                    <b>TLDR</b>: The idea of contravariance and covariance in physics and math is
                                    usually
                                    a statement about how something (for example, a vector) <i>transforms</i> with
                                    respect
                                    to a specified group of transformations (i.e., the group of rotations, for example).
                                    Category theory (*) abstracts and generalizes the concept, and this allows it to be
                                    applied to computer language design, for the simple reason that <i> computer
                                    language design</i> is
                                    often expressed in algebraic (and hence categorical) terms.

                                </div>
                            </div>
                            <div className="indented">As the history of contravariance and covariance is somewhat long, I
                                will be breaking it down into parts..
                                <br/>
                                <ul>
                                    <li>
                                        <Link to="Contra1">Contravariance and Covariance, part 1</Link>
                                    </li>
                                    <li>
                                        <Link to="Contra2">Contravariance and Covariance, part 2</Link>
                                    </li>
                                    <li>
                                        <Link to="Contra3">Contravariance and Covariance, part 3</Link>
                                    </li>
                                    <li>
                                        <Link to="Contra4">Contravariance and Covariance, part 4</Link>
                                    </li>
                                    <li>
                                        <Link to="Contra5">Contravariance and Covariance, part 5</Link>
                                    </li>
                                    <li>
                                        <Link to="Contra6">Contravariance and Covariance, part 6</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="indented">
                                <hr/>
                                <br/>
                                <em>* Using Category Theory to Design Implicit Conversions and Generic Operators</em> --
                                John C. Reynolds, 1980
                            </div>
                        </article>
                    </div>
                    <aside className="col-md-4 blog-aside">
                        <div className="aside-widget">
                            <header>
                                <h3>Related</h3>
                            </header>
                            <div className="body">
                                <ul className="tales-list">
                                    <li><a href="https://www.cs.cmu.edu/~jcr/" target="_blank"  rel="noopener noreferrer">John C. Reynolds</a></li>
                                </ul>
                            </div>
                        </div>
                    </aside>
                </div>
            </div>
        </div>
    )
        ;
}
export default ContraDetails;
