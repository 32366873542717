import React  from 'react';
//import {Link} from 'react-router-dom';



const ComplexNumberDetails =(props) => {
    return (
<div className="widewrapper main">
    <div className="container">
        <div className="row">
            <div className="col-md-8 blog-main">
                <article className="blog-post">
                    <header>
                        <h1>Complex Numbers</h1>
                        <div className="lead-image">
                        <img src={require("../assets/img/physics/gauss/gauss.jpg")} alt="Gauss" className="img-responsive imgframe img-rounded" />
                            <span>
                                Carl Friedrich Gauss - The Prince of Mathematicians
                            </span>
                        </div>
                    </header>
                    <div className="body">
                        <h3>Introduction</h3>
                        <div className="indented">
                            Complex numbers were put on a "rigorous" mathematical foundation by Carl Gauss in 1799.
                            When a mathematician speaks of mathematical rigor, he usually means ideas are "well-defined" and
                            the important results can be proven. We will not worry about "mathematical rigor". Our approach is going to be to
                            develop an intuitive understanding of what complex numbers are, how to calculate with them,  and how they are used in science.
                            Remember, mathematics is the language of science, and we have no choice if understanding nature (and qubits) is our goal.
                            Mathematical proof, while important, is secondary to this aim...

                        </div>
                        <div className="indented">
                            Historically, this has been a fruitful approach for math. Physicists have often created new mathematical ideas in order to solve the problems they were working on....
                            ideas which would seldom have occured to the mathematician in his ivory tower. The physicist creates the new mathematical idea, and the mathematician, following
                            behind him, takes the idea and develops it into a real mathematical theory. Dirac's use of the delta function, and  Schwartz's subsequent development of
                            the theory of distributions, is perhaps the canonical example.
                        </div>

                        <div className="indented"> ...next time -- more complex numbers!</div>
                    </div>
                </article>
            </div>
            <aside className="col-md-4 blog-aside">

                <div className="aside-widget">
                    <header>
                        <h3>Related</h3>
                    </header>
                    <div className="body">
                        <ul className="tales-list">
                            <li><a href="http://scienceworld.wolfram.com/biography/Gauss.html" target="_blank" rel="noopener noreferrer">Gauss</a></li>
                        </ul>
                    </div>
                </div>
            </aside>
        </div>
    </div>
</div>



    )
    ;
    }

export default ComplexNumberDetails;