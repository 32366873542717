import React from 'react'
//import {Link} from 'react-router-dom';
import ArticleGeneric from './article-generic.jsx';

const PhysicsDetails = () => {
    return (
        <div className="widewrapper main">
        <div className="container">
            <div className="row">
                <div className="col-md-8 blog-main">
                    <div className="row">
                        <div className="col-md-6 col-sm-6">
                            <ArticleGeneric ImageName="casimir" 
                            ReadMoreTo="Casimir" 
                            ReadMoreToText="Casimir Effect" 
                            Body="IF you take a charged parallel plate capacitor and increase the distance between its plates, 
                            you will find that you have to have to do work to overcome the attractive Coulomb force between the plates. 
                            If the capacitor plates have area A, and a surface charge density sigma, the amount of work you have to do 
                            to increase the plate separation by an amount delta d is...">
                            </ArticleGeneric>
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <ArticleGeneric ImageName="mossbauer" 
                            ReadMoreTo="Mossbauer" 
                            ReadMoreToText="Mossbauer Effect" 
                            Body="If you look at table salt (NaCl) being heated by a Bunsen burner flame, 
                            you will see a very bright yellow color in the resulting flame. 
                            This yellow is a characteristic footprint of the element sodium, Na, of which salt, NaCl, is comprised. 
                            The more general technique of looking at the spectrum of light emitted (and absorbed) 
                            by a substance to identify its constituent atoms... ">
                            </ArticleGeneric>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
};

export default PhysicsDetails;
