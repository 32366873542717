import React  from 'react';
import {Link} from "react-router-dom";


const ContraPt2 =(props) => {
    return (
        <div className="widewrapper main">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 blog-main">
                        <article className="blog-post">
                            <header>
                                <h3>Contravariance and Covariance - Part 2</h3>
                                <br/>
                                <div className="lead-image">
                                    <img src={require("../assets/img/Contr/Gibbs.png")} alt="Gibbs"
                                         className="img-fluid img-responsive imgframe img-rounded"/>
                                        <br/>
                                        Josiah Willard Gibbs<br/>
                                        Vector Calculus
                                </div>
                                <div>
                                    <br/>
                                    <strong>Vector Concept 2 -- Vector Spaces</strong>

                                </div>

                            </header>
                            <div className="body">

                                <div className="indented">
                                    In the design of computer languages, the notion of contravariance and covariance
                                    have been <em>borrowed</em> from category theory to facilitate the discussion of
                                    type
                                    coercion and signature. Category theory, on the other hand, borrowed and abstracted
                                    the notion of contravariance and covariance from classical <i><b>tensor analysis</b></i> which
                                    in turn had its origins in physics.

                                </div>
                                <div className="indented">
                                    In keeping with Feynman's observation that it is useful to have different ways
                                    thinking about known ideas,
                                    part 2 will be developing vectors from the point of view of linear algebra and
                                    vector spaces, which is a branch of algebra.
                                </div>
                                <div className="indented">
                                    The axiomatic approach used in algebra requires the introduction of a number of
                                    definitions.
                                    However, only the definitions needed to illustrate contravariance and covariance in
                                    an abstract vector
                                    space will be given. So, unfortunately, for the sake of brevity, no detailed
                                    motivation of these mathematical concepts is going to be provided (*).
                                </div>
                                <div className="indented">
                                    <b> Needed Ideas From Linear Algebra</b><br/>
                                    <ul>
                                        <li>Vector Space</li>
                                        <li>Linearly Independent</li>
                                        <li>Span</li>
                                        <li>Basis</li>
                                        <li>Coordinates</li>
                                        <li>Change of Basis</li>
                                        <li>Linear Functional</li>
                                        <li>Dual Space</li>
                                    </ul>
                                </div>
                                <div className="indented">
                                    The definition of a vector space also uses the algebraic concept
                                    of a <em>field.</em> (Not to be confused with a physical field, like, for example,
                                    the gravitational field...same words, different concepts.) For those
                                    who have not studied fields and
                                    do not wish to, good examples are the real numbers R, or the complex numbers C,
                                    which can be substituted in the definition below to allow you to follow
                                    along. <strong>Boldface</strong>
                                    is used to indicate elements of the vector space, normal font is used to indicate
                                    scalars
                                </div>
                                <div className="indented">
                                    We now introduce some notation, and a convention, that will be useful.
                                    The <i>Kronecker delta</i> is defined by:
                                    <div style={{padding: "20px",textAlign: "center"}}>
                                        <img src={require("../assets/img/Contr/kronecker.png")} alt={""}/>
                                    </div>
                                    Also, the <em>Einstein summation convention</em> will be used.
                                    That is, whenever repeated indices appear in an equation, it is understood that a
                                    summation is taken over these
                                    repeated indices.
                                </div>

                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">Vector Space</h5>
                                        A <em>vector space</em> <strong>V</strong>
                                        consists of a set of objects, called vectors, and a field F, called scalars ,
                                        and a binary operation + &nbsp;on vectors, that satisfies the following axioms:
                                        <br/><br/>
                                        i) if <strong>a</strong> and <strong>b</strong> are vectors, then so
                                        is <strong>a</strong>
                                        +<strong>b</strong> for all vectors <strong>a</strong> and <strong>b</strong>
                                        <br/>
                                        ii) <strong>a</strong>+ <strong>b</strong> =<strong>b</strong> + <strong>a</strong>
                                        <br/>
                                        iii) <strong>a</strong> + (<strong>b</strong> + <strong>c</strong>) =
                                        (<strong>a</strong>
                                        + <strong>b</strong>) + <strong>c</strong>
                                        <br/>
                                        iv) There is a zero vector, <strong>0</strong>, such that <strong>0</strong> +
                                        <strong>
                                            a
                                        </strong> = <strong>a</strong> + <strong>0</strong> = <strong>a</strong> for
                                        any vector <strong>a</strong>
                                        <br/>
                                        v) There are additive inverses. For each vector <strong>a</strong>, there exists
                                        a vector -<strong>a</strong> such that <strong>a</strong> + (-<strong>a</strong>)
                                        = -<strong>a</strong> + <strong>a</strong> = <strong>0</strong>.
                                        <br/>
                                        vi) Scalar multiplication. For any scalar k and any vector <strong>a</strong>, k
                                        <strong>
                                            a
                                        </strong>is a vector.
                                        <br/>
                                        vii) k(<strong>a</strong> + <strong>b</strong>) = k<strong>a </strong>
                                        + k<strong>b</strong>
                                        <br/>
                                        viii) (k + l)<strong>a</strong> = k<strong>a</strong> + l<strong>a</strong> for
                                        any scalars k and l
                                        <br/>
                                        ix) k(l(<strong>a</strong>)) = kl(<strong>a</strong>) for any scalars k and l
                                        <br/>
                                        x) 1<strong>a</strong> = <strong>a</strong>1 = <strong>a </strong>where 1 is the
                                        scalar identity<br/>
                                    </div>
                                </div>
                                <br/>
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">Linear Independence</h5>
                                        A set of vectors &#123;
                                        <strong>v</strong><sub>1</sub>,...,<strong>v</strong><sub>k</sub>} is <em>linearly
                                        independent</em>
                                        iff
                                        <br/>
                                        a<sub>1</sub><strong>v</strong><sub>1</sub> + ... +
                                        a<sub>k</sub><strong>v</strong><sub>k</sub> = 0 implies
                                        a<sub>1</sub>=...=a<sub>k</sub><strong></strong>= 0
                                    </div>
                                </div>
                                <br/>
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">Span</h5>
                                        The <em>span</em> of a set of vectors &#123;
                                        <strong>v</strong><sub>1</sub>,...,<strong>v</strong><sub>k</sub>}
                                        is
                                        the intersection of all subspaces that contain &#123;
                                        <strong>v</strong><sub>1</sub>,...,<strong>v</strong><sub>k</sub>&#125;
                                    </div>
                                </div>
                                <br/>
                                <div className="indented">
                                    A straightforward result is that the span of a set of vectors &#123;
                                    <strong>v</strong><sub>1</sub>,...,<strong>v</strong><sub>k</sub>&#125;
                                    is given
                                    by all possible linear combinations of the &#123;
                                    <strong>v</strong><sub>1</sub>,...,<strong>v</strong><sub>k</sub>&#125;.
                                    This result is often more
                                    useful to work with than the actual definition.
                                </div>

                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">Basis</h5>
                                        A <em>basis</em> for a vector space is any set of linearly
                                        independent vectors that span <strong>V</strong>.
                                    </div>
                                </div>
                                <br/>
                                <div className="indented">
                                    Given a basis for <strong>V</strong>, the number of vectors
                                    in the basis is called the dimension of <strong>V</strong>. A <em>
                                    finite dimensional
                                    vector space
                                </em> is a vector space that is spanned by a finite number of vectors.
                                    The discussion will be limited to finite dimensional vector spaces.
                                </div>

                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">Coordinates</h5>
                                        Let <strong>v</strong> be a vector in <strong>V</strong>,
                                        and let &#946; = &#123;
                                        <strong>v</strong><sub>1</sub>,...,<strong>v</strong><sub>n</sub>&#125; be
                                        an ordered basis for <strong>V</strong>.<strong> </strong>Then
                                        if <strong>v</strong>
                                        = a<sup>1</sup><strong>v</strong><sub>1</sub> + ...
                                        + a<sup>n</sup><strong>v</strong><sub>n</sub> , the scalars
                                        a<sup>1</sup>,...,a<sup>n</sup>
                                        are called the <em>coordinates</em> of <strong>v</strong> with respect to the
                                        basis &#946;, and
                                        is written as a nx1 column matrix:
                                        <div style={{padding: "10px",textAlign: "center"}}>
                                            <img src={require("../assets/img/Contr/column1.png")} alt="column vector"/>
                                        </div>
                                    </div>
                                </div>
                                <br/>

                                <h5>Change of Basis</h5>
                                <div className="indented">
                                    We do not wish to be constrained to one basis. If we formulate an equation that uses
                                    coordinates in its formulation, we would like to know that it is true independent
                                    of the basis chosen. Therefore, we need to know how to express our equation in a
                                    new basis. Also, it is often the case that we are able to transform to a new basis
                                    where our equations take on a simpler form. For these reasons, it important to study
                                    the change of basis problem.
                                </div>
                                <div className="indented">
                                    Let
                                    <div style={{padding: "20px",textAlign: "center"}}>
                                        <img src={require("../assets/img/Contr/alphabasis.png")} alt={""}/>
                                    </div>
                                    be a basis for <strong>V</strong> and <strong>v</strong> be an arbitrary vector with
                                    coordinates
                                    <div style={{padding: "20px",textAlign: "center"}}>
                                        <img src={require("../assets/img/Contr/alphacoords.png")} alt={""}/>
                                    </div>

                                    If we now instead wish to use a different basis
                                    <div style={{padding: "20px",textAlign: "center"}}>
                                        <img src={require("../assets/img/Contr/betabasis.png")} alt={""}/>
                                    </div>
                                    how do we calculate the new coordinates?
                                    <div style={{padding: "20px",textAlign: "center"}}>
                                        <img src={require("../assets/img/Contr/betacoords.png")} alt={""}/>
                                    </div>
                                </div>
                                <div className="indented">
                                    To answer&nbsp; this question, expand v in the beta basis, using the cordinates
                                    just given:
                                    <div style={{padding: "20px",textAlign: "center"}}>
                                        <img src={require("../assets/img/Contr/vexpandedinbeta.png")} alt={""}/>
                                    </div>
                                    Then, writing this in coordinate form in the alpha basis gives
                                    <div style={{padding: "20px",textAlign: "center"}}>
                                        <img src={require("../assets/img/Contr/vcoordsincolumnvectors.png")} alt={""}/>
                                    </div>

                                    This can be re-written as a nxn matrix times a nx1 matrix as
                                    <div style={{padding: "20px",textAlign: "center"}}>
                                        <img src={require("../assets/img/Contr/matrixequation.png")} alt={""}/>
                                    </div>
                                    <br/>
                                    where the nxn matrix's columns are the coordinates of the beta basis vectors with
                                    respect to the alpha basis. Denoting the nxn matrix, by P, this can be written as
                                    <div style={{padding: "20px",textAlign: "center"}}>
                                        v<sub>&#945;</sub> = P v<sub>&#946;</sub>
                                    </div>
                                    and left multipling by P<sup>-1</sup> gives the require expression for the
                                    coordinates
                                    of <strong>v</strong> in the new basis:
                                    <div style={{padding: "20px",textAlign: "center"}}>
                                        v<sub>&#946;</sub> = P<sup>-1</sup> v<sub>&#945;</sub>
                                    </div>
                                </div>
                                <div className="indented">
                                    The matrix P is called the <em><b>transistion matrix</b></em> from the α basis to
                                    the
                                    &#946; basis.
                                </div>
                                <div className="indented">
                                    ( A word on convention: if instead of expanding v in the &#946; basis above,
                                    I had expanded v in the &#945; basis, and taken coordinates in the &#946; basis, I
                                    would
                                    have finally arrived at a slightly different
                                    form:
                                    <div style={{padding: "20px",textAlign: "center"}}>
                                        v<sub>&#946;</sub> = Q v<sub>&#945;</sub>
                                    </div>
                                    where Q is the matrix formed by the coordinate vectors of the <em>&#945;</em> basis
                                    vectors with respect to the <em>&#946;</em> basis. Occasionally, people call Q the
                                    transition matrix. If you are reading a book on linear algebra you need to check
                                    the convention being used.)
                                </div>
                                <div className="indented">
                                    Having provided most of the needed definitions, we are very close to having all
                                    language needed to state the main idea.
                                    Recall in the "directed arrow" definition of a vector,&nbsp; the contravariant
                                    components
                                    of a vector were its coordinates with respect to the reciprocal lattice, and the
                                    reciprocal lattice was defined by the
                                    requirement <strong>e</strong><sup>i</sup><sup>.</sup><strong>e</strong><sub>k</sub> =
                                    0. In the abstract vector space formulation, this notion is generalized by the
                                    concept of the dual space and dual basis, which we now introduce.
                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">Linear Functional</h5>
                                        <strong>Definition:</strong> A <em>linear functional</em> on a vector space V is
                                        a linear
                                        map from V into the scalars K.
                                    </div>
                                </div>
                                <br/>
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">Dual Space and Dual Vectors</h5>
                                        The set of all linear functionals on a vector space
                                        V is called the <em>dual space</em> of <strong>V</strong>,
                                        denoted by <strong>V<sup>*</sup></strong>. Elements of the dual space are
                                        called <em>
                                        dual vectors
                                    </em>.
                                    </div>
                                </div>
                                <br/>
                                <div className="indented">
                                    If <strong>V</strong> is a vector space of dimension n, then <strong>V<sup>* </sup></strong>
                                    is a vector space of dimension n. Given a basis <em>α</em> for <strong>V</strong>,
                                    we construct
                                    a basis for <strong>V<sup>* </sup></strong>as follows:
                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">Dual Basis</h5>
                                        For a given basis <em>α</em>,
                                        <div style={{padding: "10px",textAlign: "center"}}>
                                            <img src={require("../assets/img/Contr/alphabasis.png")} alt={""}/>
                                        </div>

                                        the set of linear functionals,
                                        <div style={{padding: "10px",textAlign: "center"}}>
                                            <img src={require("../assets/img/Contr/alphastarbasis.png")} alt={""}/>
                                        </div>
                                        defined by
                                        <div style={{padding: "10px",textAlign: "center"}}>
                                            <img src={require("../assets/img/Contr/fadelta.png")} alt={""}/>
                                        </div>
                                        is a basis for <strong>V*</strong>, called the
                                        <em>
                                            dual basis of
                                            α
                                        </em>.
                                    </div>
                                </div>
                                <br/>
                                <div className="indented">
                                    We now know how to get a basis for <strong>V<sup>*</sup></strong> given a basis
                                    for <strong>V</strong> . This allows us to write the components for an aritrary
                                    dual vector. We now want to enquire how the coordinates of our arbitary dual
                                    vector change when a change of basis is performed is performed in <strong>V</strong>.
                                    The
                                    change of basis induces a change of basis on the dual space.

                                    <div style={{paddingTop: "20px",paddingBottom: "20px"}}>
                                        <table className="table table-bordered">
                                            <tr>
                                                <td>
                                                    Old Bases
                                                </td>
                                                <td>
                                                    New Bases
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <img src={require("../assets/img/Contr/alphabasis.png")} alt={""}/>
                                                </td>
                                                <td>
                                                    <img src={require("../assets/img/Contr/betabasis.png")} alt={""}/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <img src={require("../assets/img/Contr/alphastarbasis.png")} alt={""}/>
                                                </td>
                                                <td>
                                                    <img src={require("../assets/img/Contr/betastarbasis.png")} alt={""}/>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    The key to discovering how dual vectors transform is to require that the new dual
                                    basis vectors, g<sup>i</sup>, satisfy
                                    <div style={{padding: "20px",textAlign: "center"}}>
                                        <img src={require("../assets/img/Contr/gbdelta.png")} alt={""}/>
                                    </div>
                                    Let the transformation matrix to the new dual basis be h<sub>ik </sub>.
                                    Then g<sup>i</sup>=h<sub>ik </sub>f<sup>k</sup>, where the Einstein summation
                                    convention
                                    is used to indicate a summation is taken over the indices k. If β<sub>j</sub> =
                                    Q<sub>jl</sub>α<sub>l</sub>

                                    then we have
                                    <div style={{padding: "20px",textAlign: "center"}}>
                                        &nbsp; h<sub>ik </sub>f<sup>k</sup>Q<sub>jl</sub>α
                                        <sub>
                                            l
                                            &nbsp;
                                        </sub>= δ<sup>i</sup><sub>j</sub>
                                    </div>
                                    <div className="indented">
                                        Using the summation
                                        convention and simplifying this reduces to
                                        <div style={{padding: "20px",textAlign: "center"}}>
                                            h<sub>ik </sub>Q<sub>jl</sub><sub>
                                            &nbsp;
                                        </sub>δ<sup>k</sup><sub>l</sub> = δ<sup>i</sup><sub>j</sub>&nbsp;
                                        </div>
                                        and simplifying one more time gives
                                        <div style={{padding: "20px",textAlign: "center"}}>
                                            &nbsp; h<sub>il </sub>Q<sub>jl</sub>= δ<sup>i</sup><sub>j</sub>&nbsp;
                                        </div>
                                        or in other words
                                        <div style={{padding: "20px",textAlign: "center"}}>
                                            h<sub>il </sub>Q<sup>T</sup><sub>lj</sub>= δ<sup>i</sup><sub>j</sub>&nbsp;
                                        </div>
                                        where Q<sup>T</sup> is the transpose of Q.
                                    </div>
                                    <div className="indented">
                                        Thus we have the important result that
                                        <div style={{padding: "20px",textAlign: "center"}}>
                                            h = (Q<sup>T</sup>)<sup>-1</sup>
                                        </div>

                                        So, the coordinates in the new dual basis are computed from the change of basis
                                        matrix by taking the transpose inverse of the transformation matrix and using
                                        that as the transformation matrix for the dual vectors. The dual vectors are
                                        also
                                        called <em><b>contravariant</b> vectors</em>, and the vectors in the original
                                        space are
                                        called <em><b>covariant</b> vectors</em>. The contravariant vectors are said to
                                        transform
                                        <em>contragrediently</em> and the covariant vectors
                                        transform <em>cogrediently</em>.

                                    </div>

                                    <em>
                                        Next week -- Tensor analysis and vector transformation laws.<br/>
                                    </em>
                                    <hr/>
                                    <div className="indented" style={{fontSize: "11px"}}>
                                        (*)
                                        Starting with part 2, the discussion becomes more abstract. I cover quickly
                                        what is usually covered at a more leisurely pace in advanced linear algebra
                                        coures.
                                        I recommended "Linear Algebra" by Kunze and Hoffman as a reference, and also
                                        Schaum's Linear Algebra for
                                        practice problems.
                                    </div>
                                    <div className="indented" style={{fontSize: "11px"}}>
                                        There are other possible reference books (for example, Strang's unfortunate text
                                        "Linear Algebra and Its Applications") on linear algebra, but they devote a lot
                                        of time
                                        to other topics before even getting to linear transformations, which is the
                                        heart of the linear algebra. Kunze and Hoffman is the
                                        standard reference. The problem with Strang is a student can take a linear
                                        algebra course using his text, and not understand the centrality
                                        of linear transformations because Strang spends so much time on other, and
                                        easier, topics. Solving equations using matrices with either matrix
                                        multiplication ,
                                        or Gaussian elimination techniqes, which Strang devotes much time to is rather
                                        trivial. In fact, these topics I have successfully taught to a 9 year old.
                                        Vector spaces, basis, and linear transformations is more demanding.
                                    </div>

                                </div>
                            </div>
                        </article>
                    </div>
                    <aside className="col-md-4 blog-aside">
                        <div className="aside-widget">
                            <header>
                                <h3>Related</h3>
                            </header>
                            <div className="body">
                                <ul className="tales-list">
                                    <li><a href="https://www.aps.org/programs/outreach/history/historicsites/gibbs.cfm"
                                           target="_blank" rel="noopener noreferrer">Josiah Willard Gibbs</a></li>
                                    <li><a
                                        href="https://www.amazon.com/Linear-Algebra-2nd-Kenneth-Hoffman/dp/0135367972"
                                        target="_blank" rel="noopener noreferrer">Linear Algebra (2nd Edition)</a></li>
                                    <li><Link to="Contra1">Contravariance and Covariance, part 1</Link></li>
                                </ul>
                            </div>
                        </div>
                    </aside>
                </div>
            </div>
        </div>
    )
        ;
}
export default ContraPt2;
