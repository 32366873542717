import React  from 'react';
//import {Link} from 'react-router-dom';



const BitsAndAllThat =(props) => {
    return (

<div className="widewrapper main">
    <div className="container">
        <div className="row">
            <div className="col-md-8 blog-main">
                <article className="blog-post">
                    <header>
                        <h1>Bits</h1>
                        <div className="lead-image">
                            <img src={require("../assets/img/physics/bits/George_Boole.jpg")} alt="" className="img-responsive imgframe img-rounded" />
                            <span>George Boole - Inventor of Boolean Logic</span>
                        </div>
                    </header>
                    <div className="body">
                        <h3>What is a bit?</h3>
                        <div id="content">
                            <div className="indented">
                                The concept of a bit is fundamental to computer science. A bit you might think of representing the value of something that can have only two values. If that seems unclear, think of
                                a light bulb. It is either "on" or "off". Just two possibilites. Or, the answer to a true-false question: it is either true or false.
                                Now, not everything can be modeled by just two values, of course. The two value scenario is the simplest, and as it turns out, a very useful case.
                            </div>
                            <div className="indented">
                                To illustrate even trivially that you cannot just blindly model any question's answer by a yes or no, true or false, 0 or 1 answer, you might consider
                                a bit (or boolean) field in a database. In some databases, such a field, besides the obvious values of true and false, may <i>also have a "value" of null</i>.
                                (One might prefer to say that the field has no value in that case, and a "null" indicates we don't know anything about it yet.) So a database bit may have, in some systems,
                                loosely speaking, 3 possible "values" (although with a possible abuse of terminology, the database manufacturer still calls it a bit field...)
                            </div>
                            <div className="indented">
                                In science, usually you have uncertainties in your measurements, and so you don't always get to say something
                                is exactly true or false. So a tailor measures the length of a shirt sleeve, and gets 20 inches <i>plus or minus</i> an eighth of an inch.
                                In that case, you get a whole continuum, or aleph nought, number of values.  The range of allowed values is anywhere from the low estimate of
                                19 and 7/8 of an inch to an upper bound of 20 and 1/8 of an inch. Not just two values. (Measure seven times, cut once!)
                            </div>
                            <div className="indented">
                                To summarize, we have the banal: <i>Imperfect or incomplete knowledge is not best modeled by a bit.</i>
                            </div>
                            <div className="indented">
                                What's that I hear? zzzzzzzzzzz...you are asleep? I know. But try to stay awake for just a little longer.
                                This will get interesting...
                                <a href="https://www.youtube.com/watch?v=-ak5Lr3qkW0" target="_blank" rel="noopener noreferrer">Chris Langan,aka "The World's Smartest Man"</a>,
                                believes he can reduce life's big questions to "true or false". A "yes or no". In otherwords, a bit:

                                <blockquote>
                                    "If we can base insight into God on binary logic, we got it made."
                                    <small><cite>Christopher Langan</cite></small>
                                </blockquote>

                                Binary logic, which is just another word for syllogisms based on true or a false statements, is the logic of
                                bits. And he even anounces :
                                <blockquote>
                                    "I am closer to absolute truth than any man before me."
                                    <small><cite>Christopher Langan</cite></small>
                                </blockquote>
                            </div>
                            <div className="indented">
                                I ask that you suspend judgement on Langan, and instead now consider a different
                                "World's Smartest Man" (as he was called by Omni) -- theoretical physicist Richard Feynman.
                                A young Feynman was presented with Descartes proof of the existence of god. Proof, mind you, by pure logic, similar to what Lagan is attempting to do.
                                The following is taken from Feynman's, <i>What do you care what other people think?</i>
                                <blockquote>
                                    Arlene was having trouble with her homework in philosophy class.
                                    “We're studying Descartes,” she said. “He starts out with ‘Cogito, ergo sum’ — ‘I think, therefore I am’ — and ends up proving the existence of God.”
                                    <br/> “Impossible!” I said, without stopping to think that I was doubting the great Descartes.
                                    (It was a reaction I learned from my father: have no respect whatsoever for authority; forget who
                                    said it and instead look at what he starts with, where he ends up, and ask yourself, “Is it reasonable?”)
                                    <br/>I said,
                                    “How can you deduce one from the other?”
                                    “I don't know,” she said. “Well, let's look it over,” I said. “What's the argument?”
                                    <br/>So we look it over, and we see that Descartes’ statement “Cogito, ergo sum” is supposed to mean that there is one
                                    thing that cannot be doubted — doubt itself.
                                    <br/> “Why doesn't he just say it straight?” I complained. “He just means
                                    somehow or other that he has one fact that he knows.”
                                    Then it goes on and says things like, “I can only imagine imperfect thoughts, but imperfect can only be understood as
                                    referent to the perfect. Hence the perfect must exist somewhere.” (He's workin’ his way towards God now.)
                                    <br/>“Not at all!” I say. “In science you can talk about relative degrees of approximation without having a perfect theory.
                                    I don't know what this is all about. I think it's a bunch of baloney.”
                                    <br/>Arlene understood me. She understood, when she looked at it, that no matter how impressive and important this philosophy
                                    stuff was supposed to be, it could be taken lightly — you could just think about the words,
                                    instead of worrying about the fact that Descartes said it.
                                    <small><cite>Richard Feynman</cite></small>
                                </blockquote>
                            </div>

                            <div className="indented">
                                Feynman went on to become one of the greatest physicists of all time.
                                Langan, on the other hand, has been roundly dismissed by academics on the internet as a, albeit smart, crank.

                            </div>
                            <div className="indented">
                                So, what happened? One genius goes on to do great work, and the other,  with arguably an even higher IQ, does not?
                                This disparity of expert performance is clearly explained by Anders Ericsson in his book Peak. There are a variety of things going
                                on, but a large part of the explanation can be stated as (my words, not Anders') "Domain Knowledge Trumps IQ". The person who has studied the domain,
                                and, through the slow and long process of deliberate practice, made correct internal representations for that domain, will dominate.
                            </div>
                            <div className="indented">
                                That is why in the early days of computer chess, computers were no match for humans. Humans, while vastly inferior in processing power, had learned the correct patterns
                                and representations, and could recognize, with little effort, the correct moves and strategies in a game. The computer, while much faster, understood almost nothing but the rules
                                of the game.
                            </div>
                            <div className="indented">
                                Langan had a tough childhood. He was abandoned by his father, left to fend on his own, and ignored by his teachers. Ultimately he dropped out of University. What he learned, he learned
                                only by himself. So he received no feedback which is an essential part of deliberate practice. Feynman's story is the exact opposite. He had good family support, good mentors, and was able to absorb all of the
                                knowledge of his domain.
                            </div>
                            <div className="indented">
                                ...Next time -- QUANTUM BITS
                            </div>

                        </div>
                    </div>
                </article>
            </div>
            <aside className="col-md-4 blog-aside">
                <div className="aside-widget">
                    <header>
                        <h3>Related</h3>
                    </header>
                    <div className="body">
                        <ul className="tales-list">
                            <li><a href="https://www.amazon.com/What-Care-Other-People-Think/dp/B000BKSFXI/" target="_blank" rel="noopener noreferrer">What Do You Care What Other People Think?: Further Adventures of a Curious Character</a></li>
                            <li><a href="https://www.amazon.com/Peak-Secrets-New-Science-Expertise/dp/B01F4A98WQ/" target="_blank" rel="noopener noreferrer">Peak: Secrets from the New Science of Expertise</a></li>
                        </ul>
                    </div>
                </div>
            </aside>
        </div>
    </div>
</div>



    );
};

export default BitsAndAllThat;