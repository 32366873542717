import React  from 'react';


const LinuxSQLDetails =(props) => {
    return (
        <div className="widewrapper main">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 blog-main">
                        <article className="blog-post">
                            <header>

                                <div className="lead-image">
                                    <img src={require("../assets/img/LinuxSQLServer/1.jpg")} alt="SQL Server Linux"
                                         className="img-responsive imgframe img-rounded" />
                                        <span></span>
                                </div>
                            </header>
                            <div className="body">
                                <h3>SQL Server and Linux</h3>
                                <div className="indented">
                                    That is not a typo. Microsoft has ported SQL Server to the Linux platform. A SQL
                                    Server in a Docker container running on Ubuntu can be
                                    spun up in just a minute or two. I have seen a few SQL Server installations in my
                                    day, but none that were quite <i>that</i> fast. Simply choose your desired SQL
                                    Server version, and run the corresponding docker command.
                                    So, for example, for the latest 2017 SQL Server, simply run, after changing the
                                    password, the following
                                    <pre>{'\n docker run -e "ACCEPT_EULA=Y" -e "SA_PASSWORD=yourStrong(!)Password"\n' +
                                    ' -p 1433:1433 -d mcr.microsoft.com/mssql/server:2017-latest'}


                                    </pre>

                                </div>
                                <div className="indented">
                                    So will DigitalOcean offer managed databases with SQL Server? Due to licensing
                                    issues, I would say this is highly unlikely.
                                    Nevertheless, if you are unwilling to migrate your databases from SQL Server to
                                    PostgreSQL or MySQL, you do have the option
                                    of running SQL Server in a linux container in the cloud.
                                </div>
                            </div>
                        </article>
                    </div>
                    <aside className="col-md-4 blog-aside">
                        <div className="aside-widget">
                            <header>
                                <h3>Related</h3>
                            </header>
                            <div className="body">
                                <ul className="tales-list">
                                    <li><a href="https://www.microsoft.com/en-US/sql-server/sql-server-2017-linux"
                                           target="_blank" rel="noopener noreferrer" >SQL Sever on Linux</a></li>
                                </ul>
                            </div>
                        </div>
                    </aside>
                </div>
            </div>
        </div>
    )
        ;
}
export default LinuxSQLDetails;
