import React  from 'react';


const ScottishCafeDetails =(props) => {
    return (
        <div className="widewrapper main">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 blog-main">
                        <article className="blog-post">
                            <div style={{fontSize:"small",color: "navy "}}>
                                <i>
                                    Sometime around 1933 to 1934, Banach brought into the Scottish Cafe a large notebook
                                    so that we could write statements of new problems and some of the results of our
                                    discussions
                                    in more durable form. This book was kept there permanently. A waiter would bring it
                                    on demand and
                                    we would write down problems and comments, after which the waiter would
                                    ceremoniously take it back to its secret cache.
                                    This notebook was later to become famous as "The Scottish Book."
                                </i>
                            </div>
                            <div>-- Stanislaw. M. Ulam, "Adventures of a Mathematician"</div>


                            <div className="body">
                                <h1>The Scottish Cafe</h1>


                                <hr/>
                                <div id="content">
                                    <div className="indented">
                                        <div style={{textIndent: "30px",paddingBottom:"20px",paddingTop: "30px"}}>
                                            The Polish school of mathematics, and the mathematicians of the Scottish
                                            Cafe, were responsible for major contributions to 20th century mathematics,
                                            notably in topology, set theory and measure theory.
                                            In 1939, the Polish-Ukrainian border changed -- once again (as it had in the
                                            past -- the region historically has been coveted by a
                                            number of different peoples), and Lviv and surrounding parts of Poland found
                                            themselves part of Ukraine.
                                        </div>


                                        <div style={{paddingBottom:"20px"}}>
                                            <img src={require("../assets/img/ScottishCafe/Scottish_Cafe.jpg")} className="img-fluid"
                                                 alt="Scottish Cafe" />
                                                The original Scottish Cafe
                                        </div>
                                        <div style={{textIndent: "30px",paddingBottom:"20px"}}>
                                            If you travel there now, you will find the most widely spoken language is
                                            Ukrainian, with Russian also commonly understood,
                                            (since it was a member of the USSR) but not spoken...as everybody prefers to
                                            speak their native language, Ukrainian.
                                            (By the way, this is not the case in Eastern Ukraine, where the ties to
                                            Russia are stronger.)
                                        </div>
                                        <div style={{texIndent: "30px",paddingBottom:"20px"}}>
                                            Polish is not really spoken there now...so it is interesting to ask what
                                            happened to the Polish speakers?
                                            It seems that in 1939 and after the German occupation, many either prefered
                                            or were forced to move to Poland...of course, the few that did remain are
                                            now either quite old or are deceased.
                                        </div>
                                        <div style={{textIndent: "30px",paddingBottom:"20px"}}>
                                            So, when I arrived in Lviv on this trip, I wanted to determine if the locals
                                            realized that Lviv had been a major center of mathematical activity, and did
                                            they know about the Scottish Cafe?
                                            So, I asked cab drivers, local friends, and even a high school math teacher.
                                            It appeared nobody knew this history.
                                        </div>
                                        <div style={{textIndent: "30px",paddingBottom:"20px"}}>
                                            This would have not been a surprise in America -- after all topology, set
                                            theory and measure
                                            theory are branches of pure mathematics with an extremely limited general
                                            appeal...but I thought perhaps Lviv would have been different.
                                        </div>
                                        <div style={{paddingTop: "30px",paddingBottom: "50px"}}>
                                            <div className="card" style={{width:"450px"}}>
                                                <div className="card-body">
                                                    <h4 className="card-title">The Scottish Cafe, Lviv, Ukraine</h4>
                                                    <p className="card-text">Here is a video I took of the Scottish
                                                        Cafe. Universal Bank now occupies the Scottish Cafe. Apologies
                                                        for the camera shake. The crazy driving is typical for Lviv.</p>
                                                    <video width="400" controls>
                                                        <source src={require("../assets/media/Scott1.mp4")} type="video/mp4"/>
                                                            Your browser does not support HTML5 video.
                                                    </video>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{textIndent: "30px",paddingBottom:"20px"}}>
                                            Finally, we found the bank that replaced the Scottish Cafe...and we asked
                                            the Ukrainian bank manager. At last, somebody knew about the Scottish Cafe!
                                        </div>
                                        <div style={{textIndent: "30px",paddingBottom:"20px"}}>
                                            The story the manager told was yes, he was aware that some time ago some
                                            important people had worked there...but unfortunately, there were no
                                            momentos or artificats of the Cafe....it is now, one hundred percent, just a
                                            bank.
                                            He did mention that a few years ago, some people from POLAND came to the
                                            bank and asked the same questions -- about the Cafe and the Book...I suppose
                                            this makes sense, the mathemticians were Polish and not Ukrainian, so this
                                            history is really part of the Polish culture.
                                        </div>

                                        <hr/>

                                        <h3>The Cafe Mathematicians</h3>
                                        <div>
                                            <div>
                                                <img src={require("../assets/img/ScottishCafe/Sulam2.jpg")} alt="Ulam"
                                                     className="img-fluid img-responsive imgframe"/>
                                                    <div className="caption center-block">Stanislaw Ulam</div>
                                            </div>
                                            <div>
                                                <img src={require("../assets/img/ScottishCafe/Banach.jpg")} alt="Banach"
                                                     className="img-fluid img-responsive imgframe"/>
                                                    <div className="caption center-block">Stefan Banach</div>
                                            </div>


                                        </div>


                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>
                    <aside className="col-md-4 blog-aside">
                        <div className="aside-widget">
                            <header>
                                <h3>Related</h3>
                            </header>
                            <div className="body">
                                <ul className="tales-list">
                                    <li><a
                                        href="https://www.amazon.com/Scottish-Book-Mathematics-Selected-Problems/dp/331922896X/"
                                        target="_blank" rel="noopener noreferrer" >The Scottish Book</a></li>
                                </ul>
                            </div>
                        </div>
                    </aside>
                </div>
            </div>
        </div>
    )
        ;
}
export default ScottishCafeDetails;
