import React  from 'react';


const ContraPt6 =(props) => {
    return (

        <div className="widewrapper main">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 blog-main">
                        <article className="blog-post">
                            <header>
                                <h3>Contravariance and Covariance - Part 6</h3>
                            </header>
                            <div className="body">
                                <h5>Summary</h5>
                                <div className="indented">
                                    The idea of contravariance and covariance is more an algebraic concept than a
                                    geometric one,
                                    and it expresses the relation between vectors, dual vectors, and the invariance of
                                    scalars under a transformation.
                                    I could have easily omitted parts 3 and 4 on tensor analysis and manifolds, and went
                                    straight to category theory,
                                    since the relevant definitions in 3 and 4 are motivated by and may be thought of as
                                    an extension of the algebraic concepts found in parts 1 and 2.
                                </div>
                                <div className="indented">
                                    The distinction between contravariance and covariance may even appear in a
                                    rectangular coordinate system (I alluded to this in part 1),
                                    though it is easy to find texts that mistakenly state otherwise.
                                    Therefore, no differential geometry, curved spaces, or even calculus, is required.
                                    You do however have to know how to do a coordinate transformation.
                                    This is why I started my discussion with oblique coordinates and the reciprocal
                                    lattice in part 1.
                                </div>

                                <div className="indented">
                                    (By the way, if it is not clear that contravariance and covariance can appear even
                                    in rectangular coordinates,
                                    take a look at section 1.3 of "Methods of Theoretical Physics", part 1 by Morse and
                                    Feshbach,
                                    and in particular, pages 30-31 on contravariant and covariant vectors, where they
                                    show it is sufficient to merely introduce a scale factor in the new coordinate
                                    system.)
                                </div>
                                <br/>
                                <h5>Further Study</h5>
                                <div className="indented">
                                    This coverage of contravariance/covariance has been brief, so where to
                                    go for more? Ideally, a University class (of course). If you live near a University
                                    that even <i>offers</i> these subjects, consider yourself fortunate. In this era of
                                    perpetually
                                    increasing University tuition costs, you might think finding a course in Topology,
                                    or advanced Linear Algebra,
                                    or Differential Geometry would be easy. What you tend to find however is the
                                    opposite.
                                    Usually the student interest in these courses is limited, and enrollments are small.
                                    Departments may
                                    switch to offering them alternate years, if at all.

                                </div>
                                <div className="indented">
                                    If you opt for self-study, access to good books is essential. Being able to persuse
                                    the library stacks at your local
                                    University even if they don't offer the course is a great help. Study via the
                                    internet is a little problematical, since,
                                    as Anders Ericsson says, you can find anything on the internet but quality control.
                                </div>
                                <div className="indented">
                                    Should you find yourself in the situation of having to learn these ideas on your
                                    own,
                                    I would recommend you start first with Linear Algebra. Mastery <a
                                    href="#one">(*)</a> of Linear Algebra will immediately set you apart
                                    from most of your peers and open a wide range of subjects to you. For example,
                                    Dirac's formulation of Quantum Mechanics allows you to study
                                    it as an application of Linear Algebra, rather than Partial Differential Equations
                                    (i.e.,the
                                    Schroedinger Equation) And if you have any interest in Quantum Computing, you will
                                    need it. Linear Algebra
                                    will also provide a segue into Category Theory.
                                </div>


                            </div>

                            <hr/>
                            <div style={{color: "darkolivegreen", fontSize:"8pt"}}>
                                <a name="one"></a>
                                (*) These subjects <i>are</i> advanced and self-study does always introduce the
                                possibility of missing key ideas, making mastery more difficult.
                                Here for example is <a href="https://youtu.be/rFejpH_tAHM" target="_blank"  rel="noopener noreferrer" >Rob
                                Pike</a> of Golang fame, who I shall assume is self-taught in
                                Linear Algebra, at 9:44, <i>not</i> comprehending what properties a vector space has,
                                nor what a basis is.
                            </div>
                            <div style={{color: "darkolivegreen", fontSize:"8pt"}}>I have previously given the definition of
                                a vector space in this series, part 2. There is NO notion of orthogonal. You
                                can <i>add</i> an <i>inner product</i> to the vector space, turning it
                                to an <i>inner product space</i> at which point you can in fact talk about
                                orthogonality. So Pike's statement that a basis set is orthogonal is, in general,
                                incorrect.
                            </div>
                            <div style={{color: "darkolivegreen", fontSize:"8pt"}}>
                                Pike's use of cover "a vector basis covering a solution space" is meaningless in a
                                vector space. In a vector space, one refers to the span of a vector(s).
                                The notion of cover does not exist in a vector space.
                                The notion of cover <i>does</i> exist in a Topological space. Interestingly enough, in a
                                Topological Space, there is the notion of a <i>base</i> that generates the topology.
                                Perhaps he heard cover used in the Topological sense, and did not understand the
                                difference. I can only speculate.

                                <br/>
                            </div>
                        </article>
                    </div>
                    <aside className="col-md-4 blog-aside">
                        <div className="aside-widget">
                            <header>
                                <h3>Related</h3>
                            </header>
                            <div className="body">
                                <ul className="tales-list">
                                    <li><a
                                        href="https://www.amazon.com/Methods-Theoretical-Physics-Philip-Feshbach/dp/0976202107"
                                        target="_blank"  rel="noopener noreferrer" >Methods of Theoretical Physics</a></li>
                                </ul>
                            </div>
                        </div>
                    </aside>
                </div>
            </div>
        </div>

    )
        ;
}
export default ContraPt6;
