import React  from 'react';
import {Link} from "react-router-dom";


const TownesDetails =(props) => {
    return (
        <div className="widewrapper main">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 blog-main">
                        <article className="blog-post">
                            <header>
                                <h1>Charles Townes</h1>
                                <div className="lead-image">
                                    <img src="/img/Townes/Townes_opt.gif" alt="strumia"
                                         className="img-responsive imgframe img-rounded"/>
                            <span>

                                <br/>
                                Inventor of the Laser
                                <br/>
                                Nobel Prize 1964

                            </span>
                                </div>
                            </header>
                            <div className="body">
                                <div className="indented">
                                    As a student at Caltech, Townes was told to give up physics by his doctor because
                                    all the reading was too much for his eyes.
                                    As a compromise, he concentrated on experimental physics, thinking working with
                                    instruments and equipment would be less of a strain.
                                    This turned out to be a fortuitous decision,
                                    as the development of the maser (the microwave version of the optical laser)
                                    required a unique blend of experimental technique and theoretical knowledge.
                                </div>
                                <div className="indented">
                                    In the 1940s, Townes worked on radar as part of the war effort. MIT's radiation lab
                                    had successfully produced working radar
                                    at the 10 cm wavelength, and even 3 cm, but the military told them to try and
                                    produce 1.25 cm radar.
                                    The point, of course, is that the smaller the wavelength, the more precise the
                                    resolution of what the radar can resolve.
                                    Townes told Washington that 1.25 cm radar could not work, because that wavelength of
                                    radiation coincided with a frequency
                                    at which water vapor absorbs radiation...but the military replied that the decision
                                    had already been made and they could not stop.
                                    Naturally, when it was built, it did not work. The radar beam went out a couple of
                                    miles, and then would fade out,
                                    being absorbed by water vapor which was present in the atmosphere.
                                </div>

                                <div className="indented">
                                    Building on the knowledge gained by the failed microwave radar,
                                    Townes continued on in microwave research after the war.
                                    Ammonia also has a 1.25 cm absorption line, and Townes made
                                    the first maser using ammonia as the masing material.
                                </div>
                                <div className="indented">
                                    Today, the laser is ubiquitous, and sometimes appears as if it
                                    can be made from almost anything...Arthur Schawlow, Townes
                                    brother-in-law, to illustrate this, tried to make an edible laser,
                                    from Jell-O...although that did not work, by using just regular
                                    gelatin and fluorescent dye, the required edible laser was made.
                                </div>
                                <div className="indented">
                                    Feynman once remarked to Townes privately, as they were discussing the laser,
                                    that the way to tell a great idea is that, when people hear it, they say "Gee, I
                                    could have thought of that."
                                    Perhaps. But the history suggests otherwise...
                                </div>
                                <div className="indented">
                                    Columbia theorist L.H. Thomas told Townes the maser could not work.
                                    After Townes demonstrated it, Thomas stopped talking to him! The great Bohr
                                    told Townes it could not work (due to line broadening) -- even after it had
                                    been demonstrated. Von Neumann's initial reaction was also that it could not work,
                                    but on being told it had been done, went away and after 15 minutes of thought
                                    decided that yes, it could.
                                </div>
                                <div className="indented">
                                    The reason Bohr, Von Neumann, Thomas and others thought it could not work of course
                                    is due to the
                                    time-energy uncertainty principle. (To remind you, the time-energy uncertainty
                                    principle roughly
                                    says that if a time evolving state has been in existence for a time t, then the
                                    uncertainty E in its
                                    energy is such that Et >= hbar/2) Now, the time a photon spends in a maser/laser
                                    cavity is on the
                                    order of .0001 seconds, and so the uncertainty in its energy should have been
                                    greater than what Townes
                                    was claiming (Laser light has a very high spectral purity which translates into a
                                    very low uncertainty
                                    in the energy of its photons)...However, as there are many molecules in the masing
                                    or lasing medium,
                                    we do not know which molecule has underwent a transition, and so we do not learn the
                                    energy or frequency
                                    of any particular molecule from the laser light. Hence, the uncertainty principle is
                                    not being violated.
                                    A slightly similar situation occurs in the Mossbauer effect.
                                </div>
                                <div className="indented">
                                    <a href="https://www.youtube.com/watch?v=dDFhjHv7XDU" target="_blank">Here</a> is a
                                    video of Townes discussing the history of the laser.
                                </div>
                            </div>
                        </article>
                    </div>
                    <aside className="col-md-4 blog-aside">

                        <div className="aside-widget">
                            <header>
                                <h3>Related</h3>
                            </header>
                            <div className="body">
                                <ul className="tales-list">
                                    <li><a href="https://www.nobelprize.org/prizes/physics/1964/summary/"
                                           target="_blank"  rel="noopener noreferrer" >Nobel Prize in Physics 1964</a></li>
                                    <li><Link to="Mossbauer">Mossbauer effect</Link></li>
                                </ul>

                            </div>
                        </div>
                    </aside>
                </div>
            </div>
        </div>
    )
        ;
}
export default TownesDetails;
