import React  from 'react';
//import {Link} from 'react-router-dom';



const Orion =(props) => {
    return (

<div className="widewrapper main">
    <div className="container">
        <div className="row">
            <div className="col-md-8 blog-main">
                <article className="blog-post">
                    <header>
                        <h1>Orion</h1>
                        <div className="lead-image">
                            <img src={require("../assets/img/physics/orion/orionboon2.jpg")} alt="" className="img-responsive imgframe"/>
                            <span>&nbsp;</span>
                            
                        </div>
                    </header>
                    <div className="body">
                        <h3>Cost and Benefit</h3>
                        <div className="indented">
                            The projected cost of the Orion project to send a man to Mars is estimated at $500 billion. That's BILLION.
                        </div>
                        <div className="indented">
                            From a scientific view, a MANNED spacecraft that Orion eventually hopes to be, would seem to be a waste of money.
                            That is, any scientific data that a human may collect
                            on Mars can be done far less expensively (and safely) with an unmanned craft and robots. And robots don't incur the
                            the expense of having to be returned to earth. They are quite happy to stay on Mars, collecting and sending data back to us.
                        </div>
                        <div className="indented">
                            So NASA does not promote the reason for Orion as science, but as rather a step in establishing man in space.
                            Kind of like an engineering version of climbing Everest. But to quote Steven Weinberg, NASA's fixation on
                            manned spaceflight is infantile. Forget for a moment that lack of utility.
                            He points out, we cannot even put a
                            colony on Antartica, which compared to Mars, is heaven.
                        </div>
                        <div className="indented">
                            Now, suppose you have a blank check for $500 billion, and are required to spend it in the best way to benefit society.
                            How do you spend it? Cancer research? Secure the borders? Health Insurance? Education? Universal high speed internet and laptops for everybody?
                            Or, do you spend on a project that has little practical   or scientific benefit
                            - should it actually succeed?  Andrew Carnegie, who at the time was the richest man in the world, had a terrible time trying to return
                            his money to the citizens (in a way that benefited, rather than harmed, them) before he died. Ultimately, he decided to
                            do this by building <a href="http://www.carnegie-libraries.org/" target="_blank" rel="noopener noreferrer">libraries</a>:
                        </div>
                        <blockquote>
                            The best means of benefiting the community is to place within its reach the ladders upon which the aspiring can rise.
                            The fundamental advantage of a library is that it gives nothing for nothing. Youths must acquire knowledge themselves.
                            -- Andrew Carnegie
                        </blockquote>
                    </div>
                </article>
            </div>
            <aside className="col-md-4 blog-aside">

                <div className="aside-widget">
                    <header>
                        <h3>Related...</h3>
                    </header>
                    <div className="body">
                        <ul className="tales-list">
                            <li>
                                <a href="http://www.thespacereview.com/article/1037/1" target="_blank" rel="noopener noreferrer">Steven Weinberg</a>&nbsp;
                                <i  className="fa fa-info-circle"
                                   ngbPopover="Weinberg is one of the most informed critics of NASA's manned spaceflight program."
                                   placement="left" popoverTitle="">
                                </i>
                            </li>
                            <li>
                                <a href="http://www.klru.org/texasmonthlytalks/archive/weinberg.php">Steven Weinberg Video</a>&nbsp;
                                <i  className="fa fa-info-circle"
                                   ngbPopover="..starting at 5:30 in the video, Weinberg discusses NASA."
                                   placement="left" popoverTitle="">
                                </i>
                            </li>
                        </ul>
                    </div>
                </div>


            </aside>
        </div>
    </div>
</div>



    )
    ;
}

export default Orion;