import React  from 'react';
//import {Link} from 'react-router-dom';



const DockerAndK8sDetails =(props) => {
    return ( 
<div className="widewrapper main">
    <div className="container">
        <div className="row">
            <div className="col-md-8 blog-main">
                <article className="blog-post">
                    <header>
                        <h1>Kubernetes</h1>
                        <div className="lead-image">
                            <img src={require("../assets/img/k8s.gif")} alt="kubernetes" className="img-responsive imgframe img-rounded"/>
                            <span>Kubernetes - The Helmsman</span>
                        </div>
                    </header>
                    <div className="body">
                        <h3>κυβερνήτης</h3>
                        <div id="content">
                            <div className="indented">
                                In the Greek language, kubernetes (κυβερνήτης), roughly translated, means "helmsman". 
                            </div>
                            <div className="indented">
                                Kubernetes also refers to a technology used to manage containers. In Kubernetes, containers live in pods, which in turn live a node. The collection of nodes, pods, containers, etc gives us the Kubernetes cluster.
                                Invented at Google, kubernates is used to monitor and control such containers. If you have a large number of these containers in your enterprise, the logistics of scaling them up (or down),
                                of monitoring them for errors, or just the mundane task of deploying a latest version of your container(s), can be rather complex. Kubernetes to a large extent automates
                                this.
                            </div>
                            <div className="indented">
                                Kubernetes is not the only choice of tool you have for container orchestration. Docker also ships with a native orchestration tool --Docker Swarm. If you find Kubernetes too complex or expensive, then Swarm is a possible option you may wish to look at.
                            </div>
                            <div className="indented">As Digital Ocean now offers an inexpensive Kubernetes option, this site is now running in a Digital Ocean Kubernetes cluster.</div>
                        </div>
                    </div>
                </article>
            </div>
            <aside className="col-md-4 blog-aside">
                <div className="aside-widget">
                    <header>
                        <h3>Related</h3>
                    </header>
                    <div className="body">
                        <ul className="tales-list">
                            <li><a href="https://kubernetes.io/" target="_blank" rel="noopener noreferrer">Kubernetes</a></li>
                        </ul>
                    </div>
                </div>
            </aside>
        </div>
    </div>
</div>

    );
};

export default DockerAndK8sDetails;