import React  from 'react';


const ReactDetails =(props) => {
    return (
        <div className="widewrapper main">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 blog-main">
                        <article className="blog-post">
                            <header>
                                <h1>React</h1>
                                <div className="lead-image">
                                    <img  src={require("../assets/img/react1.gif")} alt="react"/>
                                        <span></span>
                                </div>
                            </header>
                            <div className="body">
                                <h3>React</h3>
                                <div className="indented">
                                    Created at Facebook, React, or ReactJS is a Javascript frontend for building modular
                                    web apps. While it is not a complete framework like Angular, it does enjoy a huge
                                    following.


                                </div>

                                <div className="indented">
                                    So, this site now has a <a href="http://react.larrymusa.com" target="_blank" rel="noopener noreferrer">React
                                    version </a> , containerized in Docker, of course, and deployed to the Digital Ocean
                                    K8s cluster.
                                    Docker combined with K8s makes deployments speedy, robust and ... saves money too.
                                    Nice.

                                </div>
                            </div>
                        </article>
                    </div>
                    <aside className="col-md-4 blog-aside">
                        <div className="aside-widget">
                            <header>
                                <h3>Related</h3>
                            </header>
                            <div className="body">
                                <ul className="tales-list">
                                    <li><a href="https://reactjs.org/" target="_blank" rel="noopener noreferrer" >React</a></li>
                                </ul>
                            </div>
                        </div>
                    </aside>
                </div>
            </div>
        </div>
    )
        ;
}
export default ReactDetails;
