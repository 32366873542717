import React  from 'react';


const RabiDetails =(props) => {
    return (
        <div className="widewrapper main">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 blog-main">
                        <article className="blog-post">
                            <header>
                                <h1>Isidor Isaac Rabi</h1>
                                <div className="lead-image">
                                    <img src={require("../assets/img/rabi_opt.gif")} alt="Rabi"
                                         className="img-fluid img-responsive imgframe img-rounded"/>
                                </div>
                                <div>
                                    <b>Nuclear Magnetic Resonance</b>
                                    <br/>
                                    <a href="http://nobelprize.org/nobel_prizes/physics/laureates/1944/"
                                       target="_blank"  rel="noopener noreferrer" >Nobel Prize Physics
                                        1944</a>
                                </div>
                            </header>
                            <div className="body">
                                <div id="content">
                                    <div id="content">
                                        <div className="indented">
                                            Rabi was born in the town of <a
                                            href="http://en.wikipedia.org/wiki/Ryman%C3%B3w" target="_blank" rel="noopener noreferrer" >
                                            Rymanow
                                        </a>, in what is now Poland, in 1898 with the name Israel Issac Rabi.
                                            His parents immigrated to New York and during the public school enrollment,
                                            a school official mistakenly wrote down his name as Isidor, not Israel ,
                                            which is how Rabi received the distinction of being renamed by the New York
                                            public school system. Rabi kept the name.
                                        </div>
                                        <div className="indented">

                                            Rabi entered Cornell University in 1916 with the intention of becoming an
                                            engineer,
                                            but after taking a chemistry course in qualitative analysis, he switched to
                                            chemistry,
                                            in which he graduated in 1919. Unable to find steady work, he returned to
                                            Cornell in
                                            1922 as a graduate student and then to Columbia in 1923 after having failed
                                            to receive a fellowship at Cornell.
                                            At Cornell, he found he had taken all the available chemistry courses, so he
                                            enrolled in physics, where he made perhaps his first scientific
                                            discovery: <em>
                                            "I soon realized that the part of chemistry I liked was called physics. If
                                            someone had pointed that out to me before, it would have saved me many, many
                                            years."
                                        </em>
                                        </div>
                                        <div className="indented">
                                            In 1927, Rabi decided he must go to Europe to hopefully work for
                                            Schrödinger, but ultimately, ended up working for <a
                                            href="http://nobelprize.org/nobel_prizes/physics/laureates/1943/"
                                            target="_blank" rel="noopener noreferrer" >
                                            Otto Stern
                                        </a>. Stern was famous, because he and Walter Gerlach had in 1922 performed one
                                            of the truly classic experiments of physics -- the <a
                                            href="http://en.wikipedia.org/wiki/Stern%E2%80%93Gerlach_experiment"
                                            target="_blank" rel="noopener noreferrer" >
                                            Stern Gerlach experiment
                                        </a>, whose result was ultimately interpreted as showing the electron has an
                                            intrinsic angular momentum, or spin.
                                        </div>
                                        <div className="indented">
                                            The time spent with Stern was useful. Originally, Rabi had only wanted to do
                                            theoretical work, but Stern forced
                                            him to learn something about molecular beam experiments, and it was this
                                            knowledge that he later used to
                                            develop his method of <a
                                            href="http://en.wikipedia.org/wiki/Nuclear_magnetic_resonance"
                                            target="_blank"  rel="noopener noreferrer" >
                                            Nuclear Magnetic Resonance
                                        </a>, for which he received the Nobel Prize. His resonance method was developed
                                            only as a method to measure the magnetic moment
                                            of the nucleus of the atom, which was an open problem for most nuclei in
                                            those days. However, the technique turned out to
                                            have widespread applications to both chemistry and medicine, and <i>is an
                                            example of how pure research can have great practical applications.</i>
                                        </div>
                                        <hr/>
                                        <h4>On the Importance of Basic Research</h4>
                                        <div className="indented">Perhaps to some it would seem silly to write anything
                                            on the necessity of basic research.
                                            Is not the importance of such research <i>obvious</i>?
                                            Maybe not. I offer the scientific debacle that was the closing of basic
                                            research at Bell Labs (<a
                                                href="http://blog.wired.com/gadgets/2008/08/bell-labs-kills.html"
                                                target="_blank"  rel="noopener noreferrer" >Bell Labs Kills
                                                Fundamental Physics Research</a>) to argue it is not.
                                        </div>
                                        <div className="indented">
                                            Historically, innovation has flourished in the fertile environment that is
                                            provided by basic research. Research into ideas that do not immediately have
                                            any
                                            obvious applications often unexpectedly yield tremendous applications.
                                        </div>
                                        <div className="indented">
                                            Consider the ubiquitous GPS system that is responsible for your car's
                                            navigation system. It is in fact
                                            the result of basic research. In other words, research that was done simply
                                            because it was <i>interesting to think about</i>, and
                                            not because of any potential applications.
                                        </div>
                                        <div className="indented">So, the GPS system depends on the precision of atomic
                                            clocks, which
                                            relies on the hydrogen maser which in turn was dependent upon basic research
                                            into
                                            optical pumping. More fundamentally, GPS
                                            relies on Einstein's theory of <strong>
                                                <i>
                                                    general
                                                </i>
                                            </strong><em> relativity</em>, which perhaps
                                            is the ultimate example of pure research without regard to any possible
                                            applications.
                                        </div>
                                        <div className="indented">
                                            GPS is comprised of 24 satellites orbiting the earth, all of
                                            which must have synchronized atomic clocks. Determining
                                            your position on the
                                            earth requires the use of 4 of the satellites-- 3 for your longitude,
                                            latitude,
                                            and altitude, and the fourth to verify the accuracy of the clock in your GPS
                                            unit.
                                            Since clocks run at different rates depending on the strength of the
                                            gravitational
                                            field they are in -- and since gravity varies with satellite height --
                                            general relativity
                                            must be used.
                                        </div>
                                        <div className="indented">
                                            But wait, as they say, <em>there is even more fundamental research</em>
                                            present in the GPS system. The whole idea of stimulated emission, on which
                                            masers
                                            and lasers depend, was discovered by Einstein in 1916. The 1916 paper,
                                            "Emission
                                            and Absorption of Radiation in Quantum Theory" (Zur Quantentheorie der
                                            Strahlung),
                                            like <b>all of Einstein's research</b>, was basic research.
                                        </div>
                                        <div className="indented">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>
                    <aside className="col-md-4 blog-aside">
                        <div className="aside-widget">
                            <header>
                                <h3>Related</h3>
                            </header>
                            <div className="body">
                                <ul className="tales-list">
                                    <li><a
                                        href="https://chem.libretexts.org/Bookshelves/Physical_and_Theoretical_Chemistry_Textbook_Maps/Supplemental_Modules_(Physical_and_Theoretical_Chemistry)/Spectroscopy/Magnetic_Resonance_Spectroscopies/Nuclear_Magnetic_Resonance/Nuclear_Magnetic_Resonance_II"
                                        target="_blank" rel="noopener noreferrer" >Nuclear Magnetic Resonance</a></li>
                                </ul>
                            </div>
                        </div>
                    </aside>
                </div>
            </div>
        </div>
    )
        ;
}
export default RabiDetails;
