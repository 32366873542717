import React  from 'react';
import {Link} from 'react-router-dom';



const StrumiaDetails =(props) => {
    return (
    
<div className="widewrapper main">
    <div className="container">
        <div className="row">
            <div className="col-md-8 blog-main">
                <article className="blog-post">
                    <header>
                        <h1> Alessandro Strumia</h1>
                        <div className="lead-image">
                            <img src={require("../assets/img/physics/strumia/strumia.jpg")} alt="strumia" className="img-responsive imgframe img-rounded"/>
                            <span>
                                Alessandro Strumia
                            </span>
                        </div>
                    </header>
                    <div className="body">
                        <h3>Introduction</h3>
                        <div className="indented">
                            As someone who possesses degrees in physics and math, I thought I might attempt an analysis of the Strumia affair.
                            I don't personally know any of the parties involved, and I am not in the running for any HEP positions. You might say
                            I don't have the proverbial dog in this fight. Having no dog to protect, and having actually studied HEP, among other branches of physics,
                            I hope my analysis will be considered both informed and objective.
                        </div>
                        <div className="indented">
                            First, a very rough summary of what Strumia did.
                            <ul>
                                <li>
                                    Alessandro Strumia presented a talk at a high energy physics conference (HEP): "1st Workshop on High Energy Theory and Gender".
                                </li>
                                <li>
                                    The main idea Strumia presented was that there is a bias against men in HEP hiring. To illustrate this point, he used own personal data
                                    to show, in his view, a less qualified person, who was a woman, got the job he was applying for.
                                </li>
                                <li>
                                    Most of the attendees at the conference were women, and Strumia made the comment that physics was built and invented by men.
                                    Some suggest the women attendees were offended.
                                </li>
                            </ul>
                            Next, a summary of what CERN did:
                            <ul>
                                <li>
                                    CERN removed Strumia's talk from it's website.
                                </li>
                                <li>
                                    CERN stated Strumia's talk was offensive
                                </li>
                                <li>
                                    CERN has suspended Strumia.
                                </li>
                            </ul>
                            Finally, a summary of what some HEP scientists did:
                            <ul>
                                <li> A group of HEP scientists issued a statement condemning Strumia's talk.</li>
                            </ul>
                        </div>

                        <div className="indented">
                            <h3>Quick Discussion</h3>
                            <p>
                                In my view, no one behaved  admirably here. Neither Strumia, CERN, nor the HEP scientists. Most of the issues are not actually issues of science, but of politics.
                                People have agendas to advance, and so the science takes a back seat.
                                Money, government money, is involved. Identity politics and gender is involved.
                                A priori, the higher and altruistic motives of the advancement of science will tend to take
                                a backseat to motives of politics, ideology, and career.
                            </p>
                            <p>
                                Strumia should not have used his <i>own personal job as a data point</i>. If he has a strong and valid argument, and if the data supports his claim, then it could have easily been made without his own personal data.
                                The impression actually created was he had an axe to grind against the woman who got the job he wanted.
                            </p>
                            <p>
                                CERN handled the situation clumsily. The impression that CERN created is it will censor views it finds offensive. That is a problem for CERN because science is built on, among other things, free inquiry. Censorship is the opposite of free inquiry.
                            </p>
                            <p>
                                The HEP scientists who signed a document denouncing Strumia, and accused him of things he apparently did not do (they claimed Strumia belittled women and scientists of color), come across
                                as dishonest and lacking scientific integrity. (see https://www.particlesforjustice.org/)
                            </p>
                        </div>
                        <div className="indented">
                            <h3>Long Discussion</h3>
                            <p>
                                The backstory is that there currently is a big push for diversity in academia, and government programs.
                                There is also a push for diversity in student admissions. That means, essentially, that minorities and women can in theory enjoy a more advantageous status that overrides deficiencies in academics.
                                They can get a position even when a more qualified person was available for it.
                            </p>
                            <p>
                                I should like to point out that giving positions to less qualified individuals, or preferring certain groups, is not new in academia. Having studied both math and physics, one thing that I could not help
                                but notice is the dominant contributions made by Jewish scientists and mathematicians. (No, I am not Jewish.)
                            </p>
                            <p>
                                What is surprising to me is that the US had a Jewish Quota that restricted the number of Jews in academia.
                                Since the excellence of Jewish contributions to science and math was so obvious, that seemed rather counter-productive.
                            </p>
                            <p>
                                So Strumia is rallying against, possibly, an inferior quality candidate getting the job. That is part of
                                being in academia as far as I can tell. To belabor the point, even the great James Clerk Maxwell was let go when the university he was at downsized from two to one professor of natural philosophy. The inferior scientist retained
                                his position, but Maxwell got the boot.
                            </p>
                            <p>
                                The lay person, the tax payer who is supporting CERN, might think his tax dollars
                                are being spent wisely and efficiently, because scientists are supposedly objective.
                                Scientists look at all the data, analyze it, and make the logical decision.
                            </p>
                            <p>
                                Well,
                                science is a human endeavor, and scientists possess in abundance the same weaknesses as the rest of us.
                                Feynman found that out when he finally became a member of the National Academy of Sciences.
                                He did not like the behavior he saw after he became a member, so he resigned.
                            </p>
                            <blockquote className="blockquote text-center">
                                I had trouble when I became a member of the National Academy of Sciences, and I had ultimately to resign.
                                Because there was another organization, most of whose time was spent in choosing who was illustrious enough
                                to be allowed to join us in our organization. Including such questions as: 'we physicists have to stick together
                                because there's a very good chemist that they're trying to get in and we haven't got enough room...'.
                                What's the matter with chemists? The whole thing was rotten . Because the purpose was mostly to decide who could have this honor. OK? I don't like honors.
                                <footer className="blockquote-footer"> Richard Feynman <cite title="Source Title"></cite></footer>
                                </blockquote>


                            <p>
                                As Feynman mentions, part of the problem is the lack of positions -- the lack of room. And so you get this infighting. Look familiar? It took Feynman some years to successfully
                                resign, but he started the process in 1960. This is nothing new.
                            </p>
                            <div>
                                Alessandro Strumia's comment<br/>
                                <blockquote className="blockquote text-center">
                                    Physics invented and built by men, it's not by invitation
                                    <footer className="blockquote-footer">Alessandro Strumia <cite title="Source Title">at Workshop on High Energy Theory and Gender</cite></footer>
                                </blockquote>  was, it is claimed, offensive. I was not there, so cannot comment on the <i>manner</i> in which he stated it, but he is essentially correct.
                            </div>
                            <p>
                                It is not possible to get a degree in physics without learning the names of at least some of the great scientists.
                                We tend to honor these people by naming either the equations they discovered or the theories they constructed, after them.

                            </p>
                            <p>
                                So we have Newton's laws. Einstein equations. The Maxwell Equations. The Dirac Equation. The Schrödinger Equation.
                                The Drude Theory of Metals. The Sommerfeld Theory of Metals. The Fermi Surface. Yang-Mills.
                                The list is endless, but the point has been made... it is a fact
                                that the theories and equations of physics were formulated almost entirely by men. Scientists who claim otherwise are dishonest.
                                The idea that Strumia presented something not already known to the young scientists is absurd. They knew.
                            </p>
                            <p>
                                <b>Strumia could have went further.</b>
                                The language of science is mathematics. Who invented the math that is used by scientists? Men. Calculus was invented by Newton and Leibnitz. Complex numbers - Gauss. Cauchy.
                                Euler's Formula - Euler. Fourier Series and Laplace Transforms? Men again. Cartesian coordinates - Descartes.  Differential geometry - Riemann. This list is endless too, and once again, it is comprised largely of men.
                            </p>
                            <p>
                                
                                    You cannot on the one hand be a serious scholar of physics and deny
                                that it has, on the whole, been built by men. The contribution of women to math and physics has been, unfortunately, relatively small compared to men.
             
                                
                            </p>
                            <p>
                                Strumia made use of the number of citations a person has received as a way to measure how good somebody is. That is a crude metric.
                                In academia, it is publish or perish, and so there is an abundance of papers that are lightweight. There are an
                                abundance of papers that nobody actually reads. What gets read and cited does not imply quality. A famous example that comes to
                                mind is the classification of finite simple groups. Mathematicians use the end result, but the papers that supposedly proved the results were filled with errors.
                                How can this be?
                            </p>
                            <p>
                                It is easy to  understand. The papers are dense. To sit down and read a paper, and recreate and verify the author's work is time consuming and hard work.
                                So we have papers that nobody, save the author and the referee, actually read.
                                Classes have to be taught. Papers graded. Lectures prepared. And I have my own paper to write. You mean I actually have to read and check the work in somebody's paper(s)?
                                Hasn't somebody else already done that? Peer review and all that? Not necessarily.
                            </p>
                            <p>
                                If you want to know about a person's work, you probably actually have to read it, and judge for yourself. The number of cites is not
                                going to do it. G.H. Hardy preferred to spend five minutes just talking to somebody,
                                and after that he would make up his mind just how good they were.
                            </p>
                            <div>
                                Albert Einstein famously stated
                                <blockquote className="blockquote text-center">
                                    I have little patience with scientists who take a board of wood, look for its thinnest part, and drill a great number of holes where drilling is easy.
                                    <footer className="blockquote-footer">Einstein<cite title="Source Title"></cite></footer>
                            </blockquote>
                                That is a useful thing to remember. How thick is the board of the candidate? Freeman Dyson stated his style was quite different from Feynman. Feynman could work on a problem for years, until he solved it.
                                Dyson admitted he could not do that. And these types of things are not measured by the number of cites.
                            </div>
                            <br/>
                            <p>
                                Another interesting question is what are the barriers to entry to become a theoretical physicist?
                                In theory, not much, except a lot of work. Lots of paper and a big waste basket. And lots of midnight oil. Lev Landau wrote his famous course on what he considered to be the theoretical minimum needed for a theoretician.
                                These books are even free, or so I have been told, if you know where to look on the internet. Greiner Muller has an excellent collection of books for the theoretician. If you want to go old school, the Sommerfeld
                                books (Sommerfeld was not only a great physicist, but a first rate teacher) are excellent. When Landau was still alive, and before his ill-fated accident,
                                anybody could sign up to be tested by him.
                            </p>
                            <p>
                                You also have to work as many problems as you can. The more the better.
                                Charles H. Townes is a good example. Townes solved every problem in Smythe's "Static and Dynamic Electricity" and that is no trivial accomplishment.
                                The problems rival those found in J.D. Jacksons book "Classical Electrodynamics", a book that is considered by many to be the scourge of physics graduate students.
                                But the skill he acquired in doing so allowed him to go on and invent the laser, for which he received a Nobel in physics.
                            </p>
                            <p>
                                Much more can be said on The Strumia Affair.  But at a minimum we should start by understanding the facts of physics as they relate to what he said ,
                                and I have attempted to present what I consider to be some relevant ones.
                            </p>

                            <p>
                                <b>Next time:</b> We leave the world of politics and return to actual physics and math: More Complex Numbers!
                            </p>
                        </div>
                    </div>
                </article>
            </div>
            <aside className="col-md-4 blog-aside">

                <div className="aside-widget">
                    <header>
                        <h3>Related</h3>
                    </header>
                    <div className="body">
                        <ul className="tales-list">
                            <li><Link to="CargoCultScience">Cargo Cult Science</Link></li>
                        </ul>
                        <ul className="tales-list">
                            <li><a href="https://www.youtube.com/watch?v=sOCIke7zLMo" target="_blank" rel="noopener noreferrer">Physics Under SJW Attack</a></li>
                        </ul>
                       
                    </div>
                </div>
            </aside>
        </div>
    </div>
</div>


    );
}

export default StrumiaDetails;