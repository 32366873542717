import React  from 'react';


const PostgreCore =(props) => {
    return (
        <div className="widewrapper main">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 blog-main">
                        <article className="blog-post">
                            <header>
                                <h1>PostgreSQL</h1>
                                <div className="lead-image">

                                    <img src={require("../assets/img/Postgresql/DotNetCore.jpg")} alt="Core" className="img-responsive imgframe img-rounded"/>
                                        <span></span>
                                </div>
                            </header>
                            <div className="body">
                                <h3>PostgreSQL and Dot NET Core</h3>
                                <div className="indented">
                                    Previously, I demonstrated a .NET Framework Web API using PostgreSQL for the backend
                                    datastore. <br/>
                                    For deployment to DigitalOcean, in a <i>linux</i> Docker container,
                                    it is better to use .NET Core, which is cross platform.
                                    <br/>

                                </div>
                                <hr/>
                                <div className="indented">
                                    <h3>How To Setup A .NET Core Web API With PostgreSQL</h3>
                                    <div>
                                        <div>
                                            To get the show on the road, I am going to create a HelloWorld .NET Core Web
                                            API that uses PostgreSQL for the backend datastore.

                                        </div>
                                        <div className="postgresql">
                                            My setup: I am running
                                            Windows 10 with Visual Studio 2017. I am using the .NET Core 2.2 SDK, and I
                                            have PostgreSQL running in a Docker container on my local machine. The
                                            Docker container has exposed
                                            port 5433. Using pgAdmin4, I have created PostgreSQL login 'JimmyPage' with
                                            password 'Kashmir'.
                                        </div>


                                        <div className="postgresql">
                                            <b>Step 1.</b>
                                            In Visual Studio 2017, create a new ASP.NET Core Web Application project,
                                            HelloWorldAPI
                                            <div className="pgImage">
                                                <img src={require("../assets/img/Postgresql/NewProject1.jpg")} alt="" />
                                            </div>

                                        </div>
                                        <div className="postgresql">
                                            <b>Step 2.</b> Select the API project template, uncheck the Configure HTTPS
                                            checkbox, and make sure ASP.NET 2.2 Core is selected.
                                            <div className="pgImage"><img src={require("../assets/img/Postgresql/APITemplate.jpg")} alt=""
                                            /></div>
                                            Click OK.
                                        </div>
                                        <div className="postgresql">
                                            <b>Step 3.</b> Let's add the very useful Swagger. Right click the project in
                                            Visual Studio, and then click on "Manage Nuget Packages…"
                                        </div>
                                        <div className="postgresql">
                                            <b>Step 4.</b> Search for "Swashbuckle.AspNetCore" under the browse tab and
                                            install Swashbuckle.AspNetCore in your project..
                                            (Another way to install Swagger is via the Package Manager Console, in which
                                            case you would use the command: Install-Package Swashbuckle.AspNetCore)
                                        </div>
                                        <div className="postgresql">
                                            <b>Step 5.</b>
                                            In Startup.cs, we inject Swagger by adding the following to the
                                            ConfigureServices method:<br/>
                                            <pre>{'   // Register the Swagger generator, defining 1 or more Swagger documents\n' +
                                            '          services.AddSwaggerGen(c =>\n' +
                                            '    {\n' +
                                            '   c.SwaggerDoc("v1", new Info {Title = "HelloWorld API", Version = "v1"});\n' +
                                            '  });'}


                                            </pre>
                                            and also add a new using statement:
                                            <pre>{'   using Swashbuckle.AspNetCore.Swagger;'}
                                             </pre>
                                        </div>
                                        <div>
                                            <b>Step 6.</b> Having injected Swagger, now we configure it in the Configure
                                            method:

                                            After the app.UseMvc() line in Startup.cs, add the following:
                                            <br/><br/>
                                            <pre>{'//Enable middleware to serve generated Swagger as a JSON endpoint.\n' +
                                            '       app.UseSwagger();\n' +
                                            '\n' +
                                            '     // Enable middleware to serve swagger-ui (HTML, JS, CSS, etc.),\n' +
                                            '     // specifying the Swagger JSON endpoint.\n' +
                                            '     app.UseSwaggerUI(c =>\n' +
                                            ' {\n' +
                                            ' c.SwaggerEndpoint("/swagger/v1/swagger.json", "Hello World API");\n' +
                                            '   });'}
                                        </pre>
                                        </div>


                                        <div>
                                            <b>Step 7.</b>
                                            Now press F5 (or Ctrl F5), and enter /swagger after the root:
                                            (http://localhost:portnumber/swagger) to reveal the Swagger page:
                                            <div className="pgImage"><img src={require("../assets/img/Postgresql/Swagger.jpg")} alt="" /></div>
                                            The api/Values that is shown is part of hard coded boilerplate generated by
                                            Visual Studio. Useful for verifying Swagger is setup correctly, but we no
                                            longer need it.
                                            So, in the Controllers folder, delete the ValuesController.cs file. We will
                                            add our own Entity class and Controller.

                                        </div>
                                        <div className="postgresql">
                                            As before, we add a Book.cs class to represent our entity. Create a new
                                            folder, Models, and in it
                                            add a file Book.cs with the following Books class:
                                            <pre>{' using System.ComponentModel.DataAnnotations;\n' +
                                            '\n' +
                                            '    namespace HelloWorldAPI.Models\n' +
                                            '  {\n' +
                                            '   public class Book\n' +
                                            '   {\n' +
                                            '  [Key]\n' +
                                            '   public int Id {get; set;}\n' +
                                            '\n' +
                                            '  public string Title {get; set;}\n' +
                                            '\n' +
                                            '  public string Author {get; set;}\n' +
                                            ' }\n' +
                                            '  }'}

      </pre>
                                            (Please note: In an actual real word, non-Hello World API, a DTO(s) would be
                                            used here.
                                            Using an entity for a view model is brittle...Having said that, it has the
                                            advantage of keeping this
                                            article small, and quickly getting to the PostgreSQL part.)
                                        </div>
                                        <div>
                                            <div>
                                                <b>Step 8.</b> Now we can add an EntityFrameworkCore.DbContext.
                                                In the Models folder, add a new class file BookContext.cs, with the
                                                following class:
                                                <pre>{'    using Microsoft.EntityFrameworkCore;\n' +
                                                '\n' +
                                                '    namespace HelloWorldAPI.Models\n' +
                                                ' {\n' +
                                                ' public class BookContext:DbContext\n' +
                                                '  {\n' +
                                                '  public BookContext(DbContextOptions&lt;BookContext< options)\n' +
                                                ' : base(options)\n' +
                                                ' {\n' +
                                                '  }\n' +
                                                '\n' +
                                                '  public DbSet&lt;Book> Books {get; set;}\n' +
                                                ' }\n' +
                                                '  }'}

</pre>

                                            </div>
                                            <div>
                                                <b>Step 9.</b> Now we install the Npgsql package, and register the
                                                database context .
                                                As before, we have to install the Npgsql package via the Package Manager
                                                Console:
                                                <pre>{'Install-Package Npgsql.EntityFrameworkCore.PostgreSQL -Version 2.2.0'}
                                                </pre>

                                                And then update Startup.cs and add services.AddDbContext code:

                                                <pre>{'public void ConfigureServices(IServiceCollection services)\n' +
                                                '{\n' +
                                                'services.AddDbContext &lt; BookContext &gt; (opt => opt.UseNpgsql("host=localhost;port=5433;database=HelloWorldAPI;user id=JimmyPage;password=Kashmir;"));\n' +
                                                'services.AddMvc().SetCompatibilityVersion(CompatibilityVersion.Version_2_2);\n' +
                                                '\n' +
                                                '// Register the Swagger generator, defining 1 or more Swagger documents\n' +
                                                'services.AddSwaggerGen(c =>\n' +
                                                '{\n' +
                                                'c.SwaggerDoc("v1", new Info {Title = "Hello World API", Version = "v1"});\n' +
                                                '});\n' +
                                                '}'}
                                                </pre>
                                            </div>
                                            <div>
                                                <b>Step 10</b>
                                                Now we add the controller for Books.
                                                <div className="pgImage"><img alt=""
                                                                              src={require("../assets/img/Postgresql/ScaffoldBookController1.jpg")}/></div>

                                                Click Add, and on the next dialog, select the Book class, the Book
                                                context:

                                                <div className="pgImage">
                                                    <img src={require("../assets/img/Postgresql/AddAPIContollerBook1.jpg")} alt=""/>
                                                </div>

                                                Click Add.
                                            </div>

                                            <div className="postgresql">
                                                <b>Step 11.</b> In the package manager console, type Add-Migration
                                                'initial migration' and press enter.
                                                One more time, in the package manager console, type 'update-database',
                                                and press enter.
                                                This should create the database, after which the API is ready to go.
                                            </div>
                                            <div className="postgresql">
                                                <b>Step 12.</b> Finally, press F5 (or ctrl F5) to launch the API and
                                                Swagger to test it.
                                                <div className="pgImage">
                                                    <img src={require("../assets/img/Postgresql/swaggercore.jpg")} alt="" />
                                                </div>
                                            </div>
                                            <div className="postgresql">
                                                <b>Step 13. </b> Hopefully, everything works (on <i>your</i> machine!)
                                                and you should be able to post a book to the API and have it
                                                saved in PostgreSQL:
                                                <div className="pgImage">
                                                    <img src={require("../assets/img/Postgresql/swaggerpost1.jpg")} alt="" />
                                                </div>
                                            </div>
                                            <div className="postgresql">
                                                <b>Step 14.</b> Verify that indeed you are saving to PostgresSQL (and
                                                not SQL Server) by viewing the database in pgAdmin4:
                                                <div className="pgImage"><img src={require("../assets/img/Postgresql/Hobbit.jpg")} alt="" /></div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>
                    <aside className="col-md-4 blog-aside">
                        <div className="aside-widget">
                            <header>
                                <h3>Related</h3>
                            </header>
                            <div className="body">
                                <ul className="tales-list">
                                    <li><a href="https://www.postgresql.org/" target="_blank"   rel="noopener noreferrer">PostgreSQL</a></li>
                                    <li><a href="https://www.pgadmin.org/" target="_blank"  rel="noopener noreferrer">pgAdmin4</a></li>
                                    <li><a
                                        href="https://blog.digitalocean.com/announcing-managed-databases-for-postgresql/"
                                        target="_blank"  rel="noopener noreferrer"> DigitalOcean PostgreSQL</a></li>
                                </ul>
                            </div>
                        </div>
                    </aside>
                </div>
            </div>
        </div>
    )
        ;
}
export default PostgreCore;
