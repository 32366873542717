import React  from 'react';


const TestDomeDetails =(props) => {
    return (
        <div className="widewrapper main">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 blog-main">
                        <article className="blog-post">
                            <header>
                                <h1>He Tasks Me</h1>
                                <div className="lead-image">
                                    <img  src={require("../assets/img/TestDome/khan1.jpg")} alt="Wrath of Khan"/>
                                    <br/>
                                        <span>Khan</span>
                                </div>
                            </header>
                            <div className="body">
                                <div id="content">
                                    <div className="indented">
                                        Fans of Star Trek no doubt recognize the iconic picture of Khan from the movie
                                        "Star Trek: The Wrath of Khan". In the movie,
                                        Khan sets himself what ultimately ends up being an impossible task, defeating
                                        his old adversary, the great Captain Kirk.

                                    </div>
                                    <br/>
                                    <div className="indented">
                                        Recently, I learned of an excellent site for practicing computer programming
                                        problems - "tasks", if you will, TestDome.com.
                                        Their problems take about 15 or 20 minutes, and vary in difficulty from easy to
                                        difficult. The idea is, supposedly,
                                        companies can use these problems to measure a job candidates skills, and
                                        likewise, a candidate can use the tests to practice.
                                    </div>
                                    <br/>
                                    <div className="indented">
                                        I would like to solve one of their sample, <i>public</i> problems, and then make
                                        some <i>observations</i>.
                                    </div>
                                    <br/>
                                    <div className="indented">
                                        As the problem I will solve is in fact public,
                                        posting a solution should be fair game. The way the site works is you write your
                                        solution in the browser, press run,
                                        in real time your code is compiled and ran against various tests. You know
                                        immediately if your solution is
                                        "correct", or if you need to fix it. You can run and fix as many times as needed
                                        within the time constraint allotted for the problem.
                                    </div>
                                    <br/>
                                    <div className="indented">
                                        <h4>TESTDOME FOLDERS PROBLEM</h4>
                                        Your task:<br/> <i>Implement a method</i> <b>FolderNames</b>, which accepts a
                                        string containing an XML file that specifies folder
                                        structure and returns all folder names that start
                                        with startingLetter. The XML format is given in
                                        the example below.For example, for the letter 'u'
                                        and XML file:
                                    </div>
                                    <pre>{'<xml version="1.0" encoding="UTF-8" >\n' +
                                    '      <folder name="c"/>\n' +
                                    '      <folder name="program files">\n' +
                                    '      <folder name="uninstall information" />\n' +
                                    '      </folder>\n' +
                                    '      <folder name="users" />\n' +
                                    '      </folder>'}

                                    </pre>
                                    <div className="indented">
                                        the function should return "uninstall information" and "users" (in any order).
                                        Below is a screen capture of the actual problem:
                                    </div>
                                    <div className="indented">
                                        <img src={require("../assets/img/TestDome/folder1.jpg")} alt="folders"/>
                                    </div>
                                    <br/>
                                    <div className="indented">
                                        So <b>the task is to implement the method</b>:<br/><br/>
                                        <span style={{color:"blue"}}>public static</span>
                                        IEnumerable &lt;string&gt; FolderNames(string
                                        xml, char startingLetter)
                                        <br/>
                                        (
                                            <br/>
                                        <span style={{color:"blue"}}>&nbsp;&nbsp;&nbsp;&nbsp;throw new </span>
                                        NotImplementedException("Waiting to be implemented");
                                            <br />
                                        )
                                    </div>
                                    <div className="indented">
                                        I produced a solution in three statements, plus a return statement. This should
                                        not be categorized as hard. The first two statements read/parse the XML string.
                                        The the third statement creates an IEnumerable&lt;string&gt;.
                                        Finally, we return that IEnumerable.

                                    </div>
                                    <div className="indented">
                                        So, on line one of our method, we need to read the XML string:<br/>
                                        <span style={{color:"deepskyblue"}}>XDocument</span> doc =
                                        <span style={{color:"deepskyblue"}}>XDocument</span>.Parse(xml);
                                    </div>
                                    <div className="indented">
                                        On the second line, we create an IEnumerable of XElements, which we will query
                                        against:<br/><br/>
                                        var folders = doc.Descendants(<span style={{color: "brown"}}>"folder"</span>);
                                    </div>
                                    <div className="indented">
                                        On the third line, finally,
                                        we create the LINQ to XML query. How does one know to use a LINQ query? This
                                        comes from experience, and realizing LINQ queries return IEnumerable&lt;T&gt;.
                                        If you were in the unfortunate situation of not being aware of that fact, then
                                        you will most likely implement using a more complicated and error prone API.
                                        So here is the LINQ query, our third line:
                                    </div>
                                    <div>
                                <pre>{'      var query = from f in folders\n' +
                                '      where  f.Attribute("name").Value.StartsWith(startingLetter.ToString())\n' +
                                '      select f.Attribute("name").Value;'}
                                </pre>

                                    </div>
                                    <div className="indented">
                                        And that's it. Add the using statements for LINQ:
                                        <pre>{'using System.Linq;\n' +
                                        'using System.Xml.Linq;'}
                                        </pre>

                                        and you're good to go. <span style={{color:"forestgreen"}}>Copy and paste</span> the
                                        following method body into TestDome, <b>along with adding the two using
                                        statements to the top,</b>
                                        and you get a <span style={{color: "green"}}>100% pass.</span> To recap:
                                    </div>
                                    <h3> The Solution</h3>
                                    <pre>{'using System.Linq;\n' +
                                    'using System.Xml.Linq;\n' +
                                    '\n' +
                                    '  public static IEnumerable&lt;string&gt; FolderNames(string xml, char startingLetter)\n' +
                                    ' {\n' +
                                    ' XDocument doc = XDocument.Parse(xml);\n' +
                                    '\n' +
                                    ' var folders = doc.Descendants("folder");\n' +
                                    '\n' +
                                    ' var query = from f in folders\n' +
                                    'where  f.Attribute("name").Value.StartsWith(startingLetter.ToString())\n' +
                                    '  select f.Attribute("name").Value;\n' +
                                    '\n' +
                                    '  return query;\n' +
                                    ' }'}
</pre>
                                    <h3>Observations</h3>
                                    <div className="indented">
                                        <ol>
                                            <li>
                                                This problem is categorized as "hard".
                                                What is a difficult or easy problem is rather subjective. I suggest most
                                                programmers could in fact solve the problem, given more than 20 minutes.
                                                i.e., in a real-world scenario.
                                            </li>
                                            <li>
                                                <span style={{color: "red" }}>Caution:</span> The
                                                solution I provided one could argue is not optimal. Maybe even flawed.
                                                Yet it passed. 100%.
                                            </li>
                                            <li>
                                                To make the solution better, one really should check for a
                                                System.NullReference exception on the first f.Attribute call.
                                                To see why, either remove the "name" attribute from one of the XML
                                                elements, or change it to something else.
                                                In other words, there is no requirement that the XML has in fact a
                                                "name" attribute for a folder. Attributes can be optional.
                                                <span style={{color: "red" }}>Thus, if in the XML, you change the "name" attribute to, say, "nickname", run the accepted 100% solution again,  it will fail.</span> Hmmm.
                                            </li>
                                            <li>
                                                So, we have to fix the 100% solution. To do so, lets add a check for
                                                null to the query:<br/><br/>
                                                <div>
                                                    var query = from f in folders<br/>
                                                    where <b>f.Attribute("name") != null && </b>f.Attribute("name").Value.StartsWith(startingLetter.ToString())<br/>
                                                    select f.Attribute("name").Value;

                                                </div>
                                                <br/>
                                                <div style={{color:"green"}}> Using that for the query will produce a
                                                    working solution that handles the optional name attribute case.
                                                </div>


                                            </li>
                                            <li>The null check in (4) works because we are using the C# short-ciruit AND
                                                operator, &&. If f.Attribute("name") is null, the rest of the line does
                                                not execute, so no System.NullReference exception occurs.
                                            </li>
                                            <li>Checking for null on XML attributes is standard-fair...</li>
                                        </ol>


                                    </div>
                                    <div>
                                        So that's it. Testing sites are interesting tools to practice on.
                                        However, their use as an accurate, valid tool to measure the actual skill and
                                        knowledge of a candidate is more questionable.

                                    </div>
                                    <div>
                                        <hr/>
                                    </div>
                                    <div className="indented">
                                        <h3>Alternate Syntax</h3>
                                        LINQ offers two syntaxes: 1) The query syntax (so named because it resembles
                                        SQL), which I already demonstrated, and
                                        2) The method syntax (so named because it uses the LINQ extension methods, of
                                        course).
                                        <br/>The solution using the method syntax would be as follows:

                                        <pre>{' var query = folders\n' +
                                        '    .Where(f => f.Attribute("name") != null &&\n' +
                                        '    f.Attribute("name").Value.StartsWith(startingLetter.ToString()))\n' +
                                        '    .Select(f => f.Attribute("name").Value);\n' +
                                        '\n' +
                                        '  return query;'}
                                        </pre>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>
                    <aside className="col-md-4 blog-aside">
                        <div className="aside-widget">
                            <header>
                                <h3>Related</h3>
                            </header>
                            <div className="body">
                                <ul className="tales-list">
                                    <li><a href="https://www.youtube.com/watch?v=gsYT8YHL-R0" target="_blank" rel="noopener noreferrer" >Khan, He
                                        Tasks Me</a></li>
                                    <li><a
                                        href="https://www.testdome.com/questions/c-sharp/folders/7276?testId=18&testDifficulty=Hard"
                                        target="_blank" rel="noopener noreferrer" >TestDome Folder Problem</a></li>
                                </ul>
                            </div>
                        </div>
                    </aside>
                </div>
            </div>
        </div>
    )
        ;
}
export default TestDomeDetails;
