import React from 'react'
import {NavLink} from 'react-router-dom'

const NavBar = () => {
    return ( <nav className="navbar navbar-expand-sm navbar-toggleable-sm navbar-dark bg-dark border-bottom box-shadow mb-3 ">
                <div className="container">
                    <NavLink className="navbar-brand " to="/">
                    <img src={require("../assets/img/LogoDiag2.png")} alt="logo" />&nbsp;Cogitations on Physics, Math, and Computers&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </NavLink>
                    <button className="navbar-toggler " type="button" data-toggle="collapse" data-target=".navbar-collapse" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="navbar-collapse collapse d-sm-inline-flex flex-sm-row-reverse">
                        <ul className="navbar-nav flex-grow-1">               
                <li className="nav-item ">
                    <NavLink className="nav-link  text-light " to="/" id="navbardrop">
                        Home
                    </NavLink>   
                </li>
                <li className="nav-item">
                    <NavLink className="nav-link text-light"  to="/Physics">Physics</NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className="nav-link text-light"  to="/CargoCultScience">Cargo Cult Science</NavLink>
                </li>
            </ul>
        </div>
    </div>
</nav>  );
}
 
export default NavBar ;