import React  from 'react';
//import {Link} from 'react-router-dom';



const GolangDetails =(props) => {
    return ( 
      <div className="widewrapper main">
          <div className="container">
              <div className="row">
                  <div className="col-md-8 blog-main">
                      <article className="blog-post">
                          <header>
                              <h1>Golang</h1>
                              <div className="lead-image">
                                  <img src={require("../assets/img/golang3.gif")} alt="kubernetes" className="img-responsive imgframe img-rounded"/>
                                  <span></span>
                              </div>
                          </header>
                          <div className="body">
                              <h3>Golang</h3>
                              <div className="indented">
                                  Created at Google, golang, or go, is a c-like language. It even brings back pointers that .Net largely eschewed.
                                  Since Ken Thompson, inventer of c, was on the go team, the go pointers are maybe not a big surprise. 
      
                              </div>
                              <div className="indented">
                                  Designed to take advantage of the multiple cores present in 
                                  modern cpus by using concurrent goroutines and channels, go does indeed approach c program performance.
                                  Many companies have migrated their web to go because of that performance
                                  gain. Netflix and YouTube are good examples. And Docker is built in go.
                              </div>
                              <div className="indented">
                                  An interesting aspect of golang web programming is the lack of frameworks. So you implement your own routing and http server via the go standard library.
                                  As a result when you deploy to a docker container, you don't necessarily have to use an nginx web server.
                              </div>
                              <div className="indented">
                                  Go is compiled to a single binary. So you deploy your binary, and not a plethora of dlls. It is <i>almost</i> build once, deploy anywhere.
                                  If you are going to deploy on multiple operating systems, say windows and linux, then you will have to cross compile to get one windows binary, and one linux binary.
                                  
                              </div>
                              <div className="indented">
                                  So, this site now has a <a href="http://go.larrymusa.com" target="_blank" rel="noopener noreferrer">go version </a> , containerized in Docker, and deployed to the Digital Ocean K8s cluster.
      
                              </div>
                          </div>
                      </article>
                  </div>
                  <aside className="col-md-4 blog-aside">
                      <div className="aside-widget">
                          <header>
                              <h3>Related</h3>
                          </header>
                          <div className="body">
                              <ul className="tales-list">
                                  <li><a href="https://golang.org/" target="_blank" rel="noopener noreferrer">Golang</a></li>
                              </ul>
                          </div>
                      </div>
                  </aside>
              </div>
          </div>
      </div>
      
       );
}


export default GolangDetails;

