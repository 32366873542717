import React  from 'react';
import {Link} from 'react-router-dom';



const Magic =(props) => {
    return (
    
<div className="widewrapper main">
    <div className="container">
        <div className="row">
            <div className="col-md-8 blog-main">
                <article className="blog-post">
                    <header>
                        <h1>The Magic of Reality - Review</h1>
                        <div className="lead-image">
                            <img src={require("../assets/img/magreal/magrealclr.gif")} alt="" className="img-responsive"/>
                            
  
                        </div>
                    </header>
                    <div className="body">
                        <div className="summary">
                            <p>
                                <b>Overall Rating:</b> 2 out of 5<br />
                                <b>Review Synopsis:</b>
                                Dawkin's non-mathematical treatment is weakend by an incorrect understanding of the scientific method and
                                his personal bias.

                            </p>
                        </div>
                        <h3>Why a scientist may object to Dawkins' exposition</h3>
                        <p>
                            Reading the existing reviews I notice there is often a disagreement between those who are religious and those who are not.
                            I will have nothing to say in that area, and am respectful of what somebody believes or does not believe. My concern is about
                            the science...and how successfully the book describes the scientific method, and what science can say about Dawkins' topic of
                            reality and truth. That is in fact the reason I felt compelled to write this review. I take issue with Dawkins' explanation of
                            the scientific method. In this regard, I urge the reader and parents in particular, in addition to Dawkins' very fine books, to
                            consider the popular works of Richard Feynman. I know of no finer expositor of what science is and does and certainly few have
                            equaled his deep physical understanding. Feynman's " The Character of Physical Law" (available in inexpensive book form on
                            Amazon, or in a video on the web) -- which is on a level accessible to the general public -- so impressed Bill Gates that when
                            he finally discovered it a few years ago, he purchased the rights to it and made it freely available on the web (project Tuva).
                        </p>
                        <p>
                            Before getting into the details of the review there is one error that I cannot leave uncorrected. On page 15, Dawkins states that atoms have always existed.
                            While this error is partially corrected later when he discusses the formation of new and heavier elements in stars and supernovae,
                            according to the standard Big Bang model, the very early universe was simply too hot for atoms to form. Therefore, we believe atoms
                            have not always existed - at least according to our current best model. This idea is not difficult and so could be included in the book.
                            Indeed, Weinberg mentioned this last century in his book for the lay public, "The First Three Minutes", which I can speculate explains why Dawkins'
                            book is graced with an unequivocal recommendation from Krauss rather than Weinberg.
                        </p>
                        <p>
                            The first thing a scientist reading Dawkins might object to is Dawkins' title and the words "really true". Socrates stated the only thing he knew is that he didn't know anything.
                            Einstein stated those who claimed to know the truth are shipwrecked by the laughter of the gods. But Dawkins claims to know how to tell what is
                            "really true". In general, Dawkins' statement is not representative of a typical scientific statement. Scientific "truths" are of necessity tentative
                            because they are extrapolated based on our experience (known a posteriori) and the possibility of discovering a new fact tomorrow that overturns today's
                            theories is always present. Dawkins' "really true" does not quite capture this idea.
                        </p>
                        <p>
                            This tentative nature of science was highlighted recently by the voluntary <ivar-modal></ivar-modal> of Ivar Giaever, Nobel Laureate physics, from the American Physical Society (APS).
                            The APS had issued a statement on global warming, that "the evidence is incontrovertible", and Glaever objected to the word incontrovertible.
                            Now I am not arguing either for or against global warming. I am stating that the language that a scientist, like Dawkins, uses to communicate with
                            the public needs to accurately reflect what we think we know, and the degree of uncertainty of that knowledge. Personal and political agendas cannot
                            be motivations to overstate a position beyond what the evidence and the associated uncertainty of this evidence allows.
                        </p>
                        <p>
                            The phrase "Really true" has another problem, as the addition of "really" is, to a logician, meaningless. Dispense with it. Is the negation of something that is "really true" "really false"?
                            Dawkins should model the standard use of scientific language to students, not invent his own.
                        </p>

                        <p>
                            Moving on from the title, Dawkins begins by defining what he means by "reality" - reality is everything that exists. Dawkins admits that emotions are real, but qualifies
                            this by stating they are contingent on brains. Dawkins arbitrarily omits mathematics in his list of what is real. This is an unfortunate omission,
                            as perhaps the only truths that we know exactly are mathematical truths. They are known a priori, and do not require the existence of brains.
                            It would not matter if sentient life ever existed, that would not alter the fact that 1 + 1 = 2. The omission of mathematics is egregious if one
                            realizes that the "magic" Dawkins is talking about is "poetic magic", rather than supernatural magic, because mathematics rates high in "poetic magic".
                            To quote Bertrand Russell:
                        </p> <blockquote>
                            Mathematics, rightly viewed, possesses not only truth, but supreme beauty -a beauty cold and austere, like that of sculpture,
                            without appeal to any part of our weaker nature, without the gorgeous trappings of painting or music, yet sublimely pure, and capable of a stern perfection
                            such as only the greatest art can show. The true spirit of delight, the exaltation, the sense of being more than Man, which is the touchstone of the highest
                            excellence, is to be found in mathematics as surely as in poetry.
                            <small><cite title="Bertrand Russell">Bertrand Russell</cite></small>
                        </blockquote>
                        <p>
                            Next, we come to Dawkins' explanation of how we know what is real:
                        </p><blockquote>
                            We come to know what is real, then, in one of three ways. We can detect it directly, using our five senses; or indirectly,
                            using our senses aided by special instruments such as telescopes and microscopes; or even more
                            indirectly, by creating models of what might be real and then
                            testing those models to see whether they successfully predict things that we can see (or hear, etc.), with
                            or without the aid of instruments.<small><cite>Dawkins, MOR, page 18</cite></small>

                        </blockquote>
                        <p>
                            There are problems caused by Dawkins' definition. The first is minor, but it should be made explicit. There is no reason to believe that humans,
                            as a species, are intelligent enough to
                            discover all that is real, or exists. Our senses are limited, our ability to perform experiments is limited,
                            our mathematical models are idealizations,
                            and our intelligence is finite, and so it is rather anthropic to claim to know what is "really true".
                            Another, and more fundamental problem, is Dawkins' description of models in science.
                            There is, in fact, nothing in the scientific method that says our models have to reflect reality.
                            The main requirement is that the model's predictions agree with experiment.
                            Yes, Dawkins example of the discovery of DNA was a triumph for biology, but to generalize
                            the success of biological models to the atomic and subatomic domain, and indeed to all of science,
                            is not justified. You are allowed to assume anything you want in your models, as long as the
                            predictions agree with experiment, and ideally, your model should predict something new that
                            was previously unknown. As Feynman says in "The Character of Physical Law", you can have as
                            much junk in your model as you like. The idea is to make progress, to advance our knowledge:
                        </p>
                        <blockquote>
                            I should like to say a little about Heisenberg's idea that you should not talk about what you cannot measure,
                            because many people talk about this idea without really understanding it. You can interpret this in the sense that the
                            constructs or inventions that you make
                            must be of such a
                            kind that the consequences that you compute
                            are comparable with experiment -- that is, you do not compute a consequence like 'a moo must be three goos', when nobody
                            knows what a moo or goo is.  Obviously that is no good. But if the consequences can be compared to experiment, then that is all that is
                            necessary. It does not matter that moos or goos cannot appear in the guess. You can have as much junk in the guess as you like, provided
                            that the consequences can be compared to experiment. This is not always fully appreciated.
                            <small><cite>Feynman, The Character of Physical Law, page 164</cite></small>
                        </blockquote>

                        <p>
                            The target audience of Dawkins' book is younger readers. Most of the Amazon reviews have been by adults, but I did read a kids review (age 7) in the January 9, 2012 issue of Chemical & Engineering
                            News that expressed a disinterest in the myths that Dawkins insists on presenting in every chapter. I suspect many kids will likewise be bored by the myths...just show
                            them the science, which after all is the main thing. I own a rather extensive library of books on the physical sciences, but do not own even one book on mythology
                            since to understand science, it is not needed. The inclusion of myths in every chapter by Dawkins is arbitrary. However, if one insists on constantly mentioning myths
                            in every chapter, but one is not willing to discuss the positive benefits of these myths (assuming there are positive benefits) and only ridicules them, then it seems
                            to me you are not showing a scientific objectivity. You are demonstrating a bias. That is not a scientific attitude and it is not one that should be modeled to students.
                            However, it is possible for a scientist to legitimately discuss mythology in his work. A beautiful example is the work of B.F. Skinner in psychology, in his discussion
                            of the causes of human behavior. Skinner noted that progress in physics occurred with the abandonment of the notion, held by the Greeks, that Greek Gods could take possession
                            of an object. When physics began to consider that causes were external to an object, and not internal, such as a Greek God that had temporarily taken up residence inside the
                            object, only then did physics progress. And so, Skinner argued, that one of the reasons progress in understanding human behavior has been difficult is we have models that,
                            analogous to the Greek Gods, attributed causes of human behavior to an internal indwelling agent...such as an ego, superego, and id. And Skinner suggested that psychology
                            should follow the lead of physics, that to understand human behavior, look at the relation between human behaviors and the external environment, rather than internal
                            characteristics like feelings, personality, and states of mind. That to me is an excellent use of mythology in the context of science. To indicate how mythological models
                            have tended to hinder scientific progress, and rather than ridicule a belief in mythology, instead to see if there is anything we may learn, so that maybe the failures can
                            help us progress. But it is a point that only needs to be made once - if it is in fact ever made -- and not repeated in every chapter.
                        </p>
                        <p>
                            On page 239, Dawkins makes the statement:
                        </p>
                        <blockquote>
                            People who would laugh at the idea that a pumpkin could turn into a coach, and who know perfectly well that silk
                            handkerchiefs don't really turn into
                            rabbits, are quite happy to believe that a prophet turned water into wine or, as devotees of another religion would have it,
                            flew to heaven on a winged horse.
                        </blockquote>
                        <p>
                            As I said, I have nothing to
                            offer on what religious ideas a person choses to believe or not, only on what science can say. But Dawkins has switched gears here, to the consideration of human beliefs. Dawkins is stating
                            it is silly to believe things that are not true. This is not a scientific attitude. The idea in science is to suspend judgment, to take a look at the idea, in this case, humans holding false
                            beliefs, and test it. When you do that, when you maintain the attitude of a scientist, you discover a surprising thing about humans -- that beliefs that are false can be beneficial. I don't play
                            golf, but my understanding is that a sports psychologist might very well instruct a golfer seeking to improve his game to not only visualize himself shooting a 70, but instruct him to say things
                            that are false, affirmations like "I shoot a 70 golf game". An utterly false statement and belief, yet a very useful tool to have at ones disposal if the goal is to improve one's game. Similarly,
                            one may look at Alcoholics Anonymous use of a "higher power". The question is not is the existence of a higher power true. Human belief can be a tool. You don't ask if your car is true. It is a
                            tool that allows you to travel faster and easier than without it. To ask if a car is true is not a useful question, and so it is with the AA higher power. The question to ask of a tool is does
                            it work, and is it ecological? It is not enough that the belief helps one attain a goal, it should also not have any unwanted consequences. Now I understand this idea of the utility of false beliefs
                            is not news to Dawkins, since it has been studied from an evolutionary view. And it's not news to Doctors who understand the placebo effect. So I think on the topic of belief and truth, which includes
                            religious belief, Dawkins has omitted ideas that could be profitably explained to students.
                        </p>
                        <p>
                            And finally, we come to Dawkins' actual presentation of science. On the whole, Dawkins' explanations are satisfactory. There are trivial technical issues, but they are not critical.
                            I might not use the metaphor of "soldiers on parade" to describe crystals. Soldiers on parade march around the field, break off into groups, and do a variety of things not seen in a crystal lattice.
                            Crystals are rigid. The ions are fixed in the lattice, and do not march. I bought Dawkins' book twice. First I bought the kindle version, which has no diagrams. When I read about the "soldiers on parade"
                            I thought he must be trying to describe a primitive cell of the crystal, and so he must have a diagram or something to illustrate this metaphor, so I ordered the book. No, no soldiers on parade illustration
                            in the book either. And there is no mention of quasicrystals, so perhaps a discussion of crystals is really too advanced of a subject for this level. A simple discussion of the three states of matter -- solid,
                            liquids, and gasses -- is perhaps all that is needed.
                            And if one is going to discuss stars, supernovae, and the mass of atoms, electrons, and protons, then perhaps the equivalence of mass and energy, and E=MC<sup>2</sup>, could be profitably mentioned.
                            But still, I think Dawkins has done ok on this part, in his discussion of actual science.
                        </p>
                        <p>
                            Nevertheless, despite the many fine qualities of the book, such as I just mentioned his actual explanation of a few of our scientific theories, in my opinion the good is overshadowed by the
                            questionable aspects. So I would recommend Feynman first, to lay a proper foundation on the scientific method and then Dawkins as a supplement, particularly for
                            Dawkins' explanation of evolution.
                        </p>


                    </div>
                </article>
            </div>
            <aside className="col-md-4 blog-aside">

                <div className="aside-widget">
                    <header>
                        <h3>Related...</h3>
                    </header>
                    <div className="body">
                        <ul className="tales-list">
                            <li>
                                <a href="http://www.youtube.com/watch?v=pqzcCfUglws" target="_blank" rel="noopener noreferrer">Psychology of Human Misjudgment</a>&nbsp;
                                <i  className="fa fa-info-circle"
                                   ngbPopover="A highly recommended and unique analysis of how to think accurately."
                                   placement="left" popoverTitle="">
                                </i>
                            </li>
                            <li>
                                <Link to="CargoCultScience">Cargo Cult Science</Link>&nbsp;
                                <i  className="fa fa-info-circle"
                                   ngbPopover="Feynman's Cargo Cult Science is one of the finest descriptions of
                                    scientific integrity available and deserves to be widely known. It should be required reading for all physicists."
                                   placement="left" popoverTitle="">
                                </i>
                            </li>
                            <li>
                                <a href="http://research.microsoft.com/apps/tools/tuva/" target="_blank" rel="noopener noreferrer">Project Tuva</a>&nbsp;
                                <i  className="fa fa-info-circle"
                                   ngbPopover="Video of Feynman delivering his 'The Character of Physical Law' lectures. First Rate."
                                   placement="left" popoverTitle="">
                                </i>
                            </li>
                            <li>
                                <a href="http://en.wikipedia.org/wiki/Low-information_rationality" target="_blank" rel="noopener noreferrer">Low-Information Rationality</a>&nbsp;
                                <i  className="fa fa-info-circle"
                                   ngbPopover="Avoid this. Invest the cognitive sweat required to learn something. "
                                   placement="left" popoverTitle="">
                                </i>
                            </li>
                        </ul>
                    </div>
                </div>


            </aside>
        </div>
    </div>
</div>


    );
};

export default Magic;