import React  from 'react';


const ContraPt1 =(props) => {
    return (
        <div className="widewrapper main">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 blog-main">
                        <article className="blog-post">
                            <header>
                                <h3>Contravariance and Covariance - Part 1</h3>
                                <br/>
                                <div className="lead-image">
                                    <div className="table-responsive">
                                        <table className="table" style={{backgroundColor:"linen"}}>
                                            <thead>
                                            <tr style={{backgroundColor:"linen", color:"black"}}>
                                                <th colSpan="3" style={{textAlign: "center"}}>Founders of Tensor Analysis
                                                </th>
                                            </tr>
                                            </thead>
                                            <tr>
                                                <td><img src={require("../assets/img/Contr/riccicurbastro.png")} alt="Ricci"
                                                         className="img-fluid img-responsive imgframe img-rounded"/></td>
                                                <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                                <td><img src={require("../assets/img/Contr/tullio_levi_civita.png")} alt="Levi Civita"
                                                         className="img-fluid img-responsive imgframe img-rounded"/></td>
                                            </tr>
                                            <tr>
                                                <td>Gregorio Ricci Curbastro</td>
                                                <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                                <td>Tullio Levi-Civita</td>
                                            </tr>

                                        </table>
                                    </div>

                                    <br/>

                                </div>
                            </header>
                            <div className="body">

                                <div className="indented">
                                    In the design of computer languages, the notion of contravariance and covariance
                                    have been <em>borrowed</em> from category theory to facilitate the discussion of
                                    type
                                    coercion and signature. Category theory, on the other hand, borrowed and abstracted
                                    the notion of contravariance and covariance from classical <i><b>tensor analysis</b></i> which
                                    in turn had its origins in physics.

                                </div>
                                <div className="indented">
                                    Unfortunately, the people who tend to be familiar with category
                                    theory, and indeed tensor analysis, and the ideas of contravariance and covariance,
                                    are more often mathematicians, and not usually computer scientists.
                                    I therefore hope to give a gentle introduction to some of these ideas.
                                </div>
                                <div className="indented">
                                    <table>
                                        <tr>
                                            <td style={{width: "427px", height: "353px"}}>
                                                <img src={require("../assets/img/Contr/Flow3.gif")} alt=""/></td>
                                        </tr>
                                        <tr>
                                            <td style={{width: "427px", height: "34px"}}>
                                                Figure 1<em> The flow of ideas leading to the development and use of
                                                category theory in computer science.</em></td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div className="indented">
                                We begin with physics because the idea of contravariance and covariance
                                has its origins in the study of vectors. The concept of a vector has undergone a
                                great amount of refinement and generalization in its history. Depending on the
                                mathematical
                                spaces one is considering, such generalization is necessary. But additionally, it
                                is also useful to have a variety of ways of thinking about the vector concept. To
                                quote Feynman:
                                <br/>
                                <br/>
                                "<em>Theories of the known, which are described by different physical ideas, may be
                                equivalent in all their predictions and hence scientifically indistinguishable. However,
                                they are not psychologically identical when trying to move from that base into the
                                unknown. For different views suggest different kinds of modifications which might
                                be made and hence are not equivalent in the hypotheses one generates from them in
                                one's attempt to understand what is not yet understood.</em>" -- Feynman [1]
                                <br/>
                            </div>
                            <div className="indented">(That is also a good quote to keep in the back of one's mind when
                                we come to category theory.) Therefore, we will enumerate some of the common
                                vector definitions. However, no matter how much the concept is generalized, in
                                engineering
                                and physics, the geometric notion of an arrow that obeys a superposition principle
                                is fundamental. And in mathematics, the algebraic notion contained in a linear vector
                                space, is fundamental. In both the arrow and vector space notions, there is a natural
                                idea of contravariance and covariance. In computer science, the concept of a vector
                                (i.e., an ADT) refers to a completely unrelated idea and in no way should be confused
                                with the concepts of vector we are considering here.
                            </div>
                            <div className="indented">
                                <strong>Vector Concept 1 - Directed Line Segment</strong>: The vector concept one first
                                studies in school is usually that of an arrow,
                                or a <em>directed line segment</em>. Forces and displacements are examples of quantities
                                that can be
                                modeled by directed line segments. Experimentally,
                                it is found that both forces and displacements can be combined graphically by the
                                parallelogram method of addition. The discovery of this method seems to have been
                                first made by the Dutch mathematician Simon Stevinus (1548-1620).<br/>
                                <table>
                                    <tr>
                                        <td style={{width: "427px", align:"center"}}>
                                            <img src={require("../assets/img/Contr/parallelogram2.gif")} alt=""/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{width: "346px"}}>
                                            Figure 2 &nbsp;<em>Addition of vectors by the parallelogram method</em></td>

                                    </tr>
                                </table>
                            </div>
                            <div className="indented">
                                Abstracting, an elementary definition of a vector is that a vector
                                is an object that can be represented
                                by a directed line segment and that combines with other vectors via the parallelogram
                                method of addition. The magnitude of the vector is
                                given by the length of the arrow.
                            </div>
                            <div className="indented">
                                This definition is of great utility in physics and engineering,
                                but it is not capable of being used in more abstact spaces than R<sup>n&nbsp; </sup>without
                                modification. However, those abstract spaces are typically manifolds, and
                                a manifold "locally looks like" R<sup>n </sup>&nbsp;(where n is the dimension of
                                the manifold) So, even if we want to jump ahead to a more advanced definition, it
                                is quite useful to understand how vectors are defined in R<sup>n</sup>.
                            </div>
                            <div className="indented">
                                <strong>Rectangular and Oblique Coordinates Systems in R<sup>2</sup></strong><br/>
                                &nbsp; &nbsp; &nbsp; Without loss of generality, we restrict our discussion now
                                to mostly R<sup>2</sup>. Extending to R<sup>n</sup> is not difficult, but complicates
                                the example. Shown below is a rectangular coordinate system and the resolution
                                of a vector <strong>A</strong> into its rectangular coordinates.<br/>
                                <br/>
                                <table>

                                    <tr>
                                        <td style={{width: "427px"}}>
                                            <img src={require("../assets/img/Contr/RectangularComponents3.gif")} alt=""/></td>
                                    </tr>
                                    <tr>
                                        <td style={{width: "307px"}}>
                                            Figure 3 <em>Rectangular Components of Vector <strong>A</strong></em></td>
                                    </tr>
                                </table>
                            </div>
                            <div className="indented">
                                In rectangular coordinates, the contravariant and covariant
                                components of a vector are <em>usually</em> the same, as will become clear later.&nbsp;
                                <br/>
                                &nbsp; &nbsp;&nbsp; &nbsp;We now extend the class of coordinate systems to consider
                                those systems whose axes are not perpendicular, or in other words, oblique coordinates.
                                Let OX be a line through the points O and X, and OY be a line through the points
                                O and Y, chosen so that the lines are not colinear and they meet at an oblique angle.
                                Then, with O chosen as the origin, this constitutes an oblique coordinate system
                                for R<sup>2</sup>.(Figure 4)<br/>
                                <br/>
                                <table>
                                    <tr>
                                        <td style={{width: "344px"}}>
                                            <img src={require("../assets/img/Contr/ObliqueCoordinateSystem2.gif")} alt=""/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{width: "344px"}}>
                                            Figure 4 <em>An Oblique Coordinate System</em>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div className="indented">
                                The parallel projection onto the OX axis gives the x coordinate, or abscissa,
                                A<sup>x</sup>,
                                of the vector <strong>A</strong>. The parallel projection onto OY gives the y
                                coordinate, or ordinate,
                                A<sup>y</sup>, of the vector <strong>A</strong>.(Figure 5)<br/>
                                <table>
                                    <tr>
                                        <td style={{width: "339px"}}>
                                            <img src={require("../assets/img/Contr/ObliqueCoordinates3.gif")} alt=""/></td>
                                    </tr>
                                    <tr>
                                        <td style={{width: "339px"}}>
                                            Figure 5 <em>Oblique Coordinates of Vector <strong>A</strong></em></td>
                                    </tr>
                                </table>
                            </div>
                            <div className="indented">
                                The components A<sup>x</sup> and A<sup>y</sup> are called the
                                <em>contravariant components</em> of <strong>A</strong>. It is important to note
                                that x and y are not exponents, but their raised position relative to A is the standard
                                notation for contravariant components that is used to distinguish them from the
                                covariant components, which we will now introduce.<br/>
                            </div>
                            <div className="indented">
                                <strong>Reciprocal Lattice </strong><br/>
                                <br/>

                                <table>
                                    <tr>
                                        <td style={{width: "337px", height: "257px"}}>
                                            <img src={require("../assets/img/Contr/ReciprocalLattice.gif")} alt=""/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{width: "337px"}}>
                                            Figure 6 <em>The Reciprocal Lattice in R<sup>2</sup></em>
                                        </td>
                                    </tr>
                                </table>
                                <br/>
                                &nbsp; &nbsp;&nbsp;
                                To define the covariant components of <strong>A</strong>, we have to introduce the
                                concept of the reciprocal lattice. Rotating the x axis by 90 degrees counter clockwise
                                gives the<strong> y<sup>'</sup></strong>-axis of the reciprocal lattice, and rotating
                                the y axis by clockwise by 90 degrees gives the
                                <strong>x<sup><b>'</b></sup></strong>-axis
                                of the reciprocal lattice. The original coordinate system is called the <em>direct</em>
                                coordinate system, or direct lattice.
                                The reciprocal lattice is shown, in green, in figure 6.<br/>
                            </div>
                            <div className="indented">
                                This
                                procedure works in R<sup>2</sup>only.
                                Generally in R<sup>n</sup>, the reciprocal lattice is defined by introducing non-zero
                                "basis"
                                vectors, not necessarily of unit length, along each axis x<sup>1</sup>,...,x<sup>n</sup>.
                                (Because the number of axes can in general exceed the number of distinct letters
                                in the alphabet, we now switch to labeling the axis not as x,y,z but as x<sup>1</sup>,...,x<sup>n</sup>)
                                Let
                                &nbsp;<strong>e</strong><sub>1</sub> be a non-zero vector along the
                                x<sup>1</sup> axis, <strong>e</strong><sub>2</sub>&nbsp; a non-zero vector along x<sup>
                                2
                            </sup>
                                axis,..., and <strong>e</strong><sub>n</sub> be a non-zero vector along x<sup>n</sup>
                                , then this set of vectors set &#123;
                                <strong>e</strong><sub>1</sub>,...,<strong>e</strong><sub>n</sub>}
                                is a "basis" for the direct coordinate system. If we are given the basis, we can
                                form the coordinate axes, and vice-versa. The reciprocal lattice "basis" , &#123;
                                <strong>e</strong><sup>1</sup>,...,<strong>e</strong><sup>n</sup>} then
                                is defined by the requirement<br/>
                                &nbsp;e<sup>i</sup>e<sub>k </sub>cos[angle(i,k)] =1 if i=k, and 0 otherwise, where
                                angle(i,k) is the angle between&nbsp; <strong>e</strong><sup>
                                i
                            </sup>and <strong>e</strong><sub>k</sub>.<br/>

                            </div>
                            <div className="indented">
                                <table>
                                    <tr>
                                        <td style={{width: "336px"}}>
                                            <img src={require("../assets/img/Contr/CovariantComponents.gif")} alt=""/></td>
                                    </tr>
                                    <tr>
                                        <td style={{width: "336px", height: "18px"}}>
                                            Figure 7 <em>Covariant Components of Vector <strong>A</strong></em></td>
                                    </tr>
                                </table>
                                <br/>
                                &nbsp; &nbsp;&nbsp; Having constructed the reciprocal lattice, the parallel projections
                                of <strong>A </strong>onto the&nbsp; <strong>x<sup><b>'</b></sup></strong>-axis
                                and the <strong>y<sup>'</sup></strong>-axis give the <em>covariant </em>components
                                of <strong>A</strong>, as shown in Figure 7.
                            </div>
                            <div className="indented">
                                For completeness, it should be noted it is also possible to give an
                                interpretation of the covariant components as orthogonal projections on the direct
                                coordinate sytem, rather than parallel projections on the reciprocal lattice. In
                                fact, this interpretation was given by the founders Ricci and Lev-Civita. However,
                                this interpretation requires &nbsp;multiplication of the components by a factor,
                                which destroys the symmetry of the formulation.
                            </div>
                            <div className="indented">
                                The reciprocal lattice seems to have its origins in crystallography,
                                but it also has great application in solid state
                                physics.
                            </div>
                            <div className="indented">
                                <i>Next Week</i> -- Part 2 -- Contravariance and covariance in abstract vector spaces.
                                Vectors as elements of vector spaces, change of basis, multilinear algebra
                            </div>
                            <div className="indented">
                                <hr/>
                                References:<br/>
                                <br/>

                                [1] Richard Feynman, <em>The Development of the Space-Time View of Quantum Field
                                Theory</em>.
                                Nobel Lecture, 1966.
                            </div>
                        </article>
                    </div>
                    <aside className="col-md-4 blog-aside">
                        <div className="aside-widget">
                            <header>
                                <h3>Related</h3>
                            </header>
                            <div className="body">
                                <ul className="tales-list">
                                    <li><a href="https://www.cs.cmu.edu/~jcr/" target="_blank" rel="noopener noreferrer">John C. Reynolds</a></li>
                                </ul>
                            </div>
                        </div>
                    </aside>
                </div>
            </div>
        </div>
    )
        ;
}
export default ContraPt1;
