import React  from 'react';
//import {Link} from 'react-router-dom';

const PostgreSQLDetails =(props) => {
    return (

        <div className="widewrapper main">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 blog-main">
                        <article className="blog-post">
                            <header>
                                <h1>PostgreSQL</h1>
                                <div className="lead-image">
                                    <img src={require("../assets/img/Postgresql/Elephant.gif")} alt="Elephant1"
                                         className="img-responsive imgframe img-rounded" />
                                        <span></span>
                                </div>
                            </header>
                            <div className="body">
                                <h3>PostgreSQL and DigitalOcean</h3>
                                <div className="indented">
                                    DigitalOcean, in addition to its inexpensive and excellent Kubernetes offering, has
                                    just launched managed databases. Currently, they offer PostgreSQL, but
                                    are planning to expand to MySQL.

                                </div>

                                <div className="indented">
                                    Of course, you don't have to use their PostgreSQL offering, as you could just use
                                    the Docker Hub image for PostgreSQL, and run Postgre from inside a container. And
                                    that is
                                    a perfectly reasonable way to go.
                                    But then you would have the added work of
                                    maintaining your PostgreSQL server. DigitalOcean's offering does some of that for
                                    you. So they offer:
                                    <ul>
                                        <li>Fully managed PostgreSQL databases</li>
                                        <li> Free daily backups with point-in-time recovery</li>
                                        <li>Standby nodes with auto-failover</li>
                                    </ul>
                                </div>
                                <div className="indented">
                                    I don't anticipate DigitalOcean offering a similar service for SQL
                                    Server. <i>Rats.</i>
                                    Therefore, I thought I would show how to target a PostgreSQL database from a .NET
                                    Web API project.
                                    The basic assumption is that if you are using .NET, there is a reasonable chance you
                                    are currently targeting SQL Server.

                                </div>
                                <hr/>
                                <div className="indented">
                                    <h3>How To Setup A .NET Web API With PostgreSQL</h3>
                                    <div>
                                        <div>
                                            To get the show on the road, I am going to create a HelloWorld .NET Web API
                                            that uses PostgreSQL for the backend datastore.

                                        </div>
                                        <div className="postgresql"> My setup: I am running
                                            Windows 10 with Visual Studio 2017. I have installed PostgreSQL (localhost)
                                            running on the standard port 5432,
                                            and have created a login JoeUser with password HardToGuessPassword. I am
                                            using pgAdmin4 as the gui to access the PostgreSQL server.
                                        </div>


                                        <div className="postgresql">
                                            <b>Step 1.</b>
                                            In Visual Studio 2017, create a new ASP.NET Web Application project,
                                            HelloWorldAPI
                                            <br/>
                                            <img src={require("../assets/img/Postgresql/Step1a.gif")} alt=""/>
                                        </div>
                                        <div className="postgresql">
                                            <b>Step 2.</b> Select the Empty project template, but be sure to select the
                                            Web API checkbox.
                                            <br/><img src={require("../assets/img/Postgresql/Step2.gif")}  alt="" />
                                            <br/> Click OK.
                                        </div>
                                        <div className="postgresql">
                                            <b>Step 3.</b> Let's add the very useful Swagger. Right click the project in
                                            Visual Studio, and then click on "Manage Nuget Packages…"
                                        </div>
                                        <div className="postgresql">
                                            <b>Step 4.</b> Search for "swashbuckle" under the browse tab. Select
                                            Swashbuckle by Richard Morris and install it in your project.
                                        </div>
                                        <div className="postgresql">
                                            <b>Step 5.</b>
                                            Now, let's set Swagger to startup automatically.
                                            Right click on the project, select properties, and select the
                                            Web section of the properties page.
                                            Check the Specific Page radio button, and enter swagger in the textbox, and
                                            save.
                                            Now Swagger will automatically be shown.
                                            <br/>
                                            <img src={require("../assets/img/Postgresql/4.gif")} alt=""/>

                                        </div>
                                        <div className="postgresql">
                                            <b>Step 6.</b>. Verfiy: Press F5, or Ctrl F5, and you should get a swagger
                                            start page. There are no API methods yet for Swagger to consume, so I add
                                            that next.
                                        </div>

                                        <div className="postgresql">
                                            <b>Step 7.</b>
                                            Since we want to use Entity framework, I have to add a class to represent an
                                            entity. Right click on the models folder, and add a new class:
                                            <br/>
                                            <b>Books.cs</b>
                                            <br/>
                                        </div>
                                        <pre>{' using System.ComponentModel.DataAnnotations;\n' +
                                        '\n' +
                                        ' namespace HelloWorldAPI.Models\n' +
                                        '     {\n' +
                                        '      public class Books\n' +
                                        '      {\n' +
                                        '      [Key]\n' +
                                        '       public int Id {get; set;}\n' +
                                        '\n' +
                                        '     public string Title {get; set;}\n' +
                                        '\n' +
                                        '  public string Author {get; set;}\n' +
                                        '  }\n' +
                                        '  }\n'}

                                        </pre>
                                        (Please note: In an actual real word, non-Hello World API, a DTO(s) would be
                                        used here. Using an entity for a view model is brittle...Having said that,
                                        it has the advantage of keeping this article small, and quickly getting to the
                                        PostgreSQL part.)


                                        <div className="postgresql">
                                            <b>Step 8.</b>
                                            Build the solution, and then right click on the Controllers folder and add a
                                            Web API 2 controller
                                            With actions using Entity Framework:
                                            <br/>
                                            <img  src={require("../assets/img/Postgresql/5.gif")} alt=""/>

                                        </div>
                                        <div className="postgresql">
                                            <b>Step 9.</b>
                                            In the resulting dialog, select our Books class,
                                            and click the plus (+) sign to add a context. Prefer async methods (its
                                            async all the way down!)
                                            so check the "Use aysnc controller action" checkbox.
                                            <br/>
                                            <img src={require("../assets/img/Postgresql/6.gif")} alt=""/>
                                            <br/>
                                            Finally click Add.
                                        </div>
                                        <div className="postgresql">
                                            <b>Step 10.</b>
                                            After click "Add" in the last step, Visual Studio will generate and add
                                            boilerplate code to our project.
                                            In particular, the web.config should now show a connection string to a
                                            (local)\MSSQLLocalDB Data Source and a mdf file:
                                            <br/>
                                            <img src={require("../assets/img/Postgresql/7.gif")} alt=""/>
                                            <br/>

                                            As we don't want to use SQL Server, but are going to use PostgresSQL,<i>we
                                            will
                                            PostgreSQLify that connection string</i> . But first, we have to add a 3rd
                                            party library that will let .NET target PostgreSQL.

                                        </div>
                                        <div className="postgresql">
                                            <b>Step 11.</b>
                                            In the package manager console, enter:
                                            <br/>
                                            <pre>Install-Package EntityFramework6.Npgsql -Version 3.2.0 </pre>
                                            <br/>

                                            This will install the EntityFramework6.Npgsql package that is needed for
                                            PostgreSQL.
                                            <br/>
                                            In web.config change the connection string to one that contains our
                                            PostgreSQL userid (JoeUser) and the user password (HardToGuessPassword).
                                            Also, the standard PostgreSQL port of 5432 is being used...:<br/>
                                            <pre>{' <add name="HelloWorldAPIContext"\n' +
                                            '  connectionString="Server=127.0.0.1;Port=5432;User Id=JoeUser;\n' +
                                            '  Password=HardToGuessPassword;\n' +
                                            '  Database=HelloWorld;"\n' +
                                            '  providerName="Npgsql" />'}


                                </pre>

                                        </div>
                                        <div className="postgresql">
                                            <b>Step 12.</b>We are almost there...Finally, we need to add a new Npgsql
                                            class:<br/>
                                            <pre>{'    using Npgsql;\n' +
                                            '    using System.Configuration;\n' +
                                            '    using System.Data.Entity;\n' +
                                            '\n' +
                                            '\tnamespace HelloWorldAPI\n' +
                                            ' {\n' +
                                            '   public class NpgSqlConfiguration: DbConfiguration\n' +
                                            '    {\n' +
                                            '   public NpgSqlConfiguration()\n' +
                                            '   {\n' +
                                            '   var name = "Npgsql";\n' +
                                            '\n' +
                                            '  SetProviderFactory(providerInvariantName: name,providerFactory: NpgsqlFactory.Instance);\n' +
                                            '\n' +
                                            '   SetProviderServices(providerInvariantName: name,provider: NpgsqlServices.Instance);\n' +
                                            '\n' +
                                            '  SetDefaultConnectionFactory(connectionFactory: new NpgsqlConnectionFactory());\n' +
                                            '   }\n' +
                                            '  }\n' +
                                            '   }'}


                </pre>

                                        </div>
                                        <div className="postgresql">
                                            <b>Step 13.</b>
                                            Now we can enable migrations. In the package manager console, type: <br/>
                                            Enable-Migrations -Force

                                        </div>
                                        <div className="postgresql">
                                            <b>Step 14.</b> In the package manager console, type Add-Migration 'initial
                                            migration' and press enter.

                                        </div>
                                        <div className="postgresql">
                                            <b>Step 15.</b> Finally, press F5 (or ctrl F5) to launch the API and Swagger
                                            to test it.
                                        </div>
                                        <div className="postgresql">
                                            <b>Step 16. </b> Hopefully, everything works (on <i>your</i> machine!) and
                                            you should be able to post a book to the API and have it
                                            saved in PostgreSQL:
                                            <br/>
                                            <img src={require("../assets/img/Postgresql/8.gif")} alt=""/>

                                        </div>
                                        <div className="postgresql">
                                            <b>Step 17.</b> Verify that indeed you are saving to PostgresSQL (and not
                                            SQL Server) by viewing the database in pgAdmin4:
                                            <br/>
                                            <img src={require("../assets/img/Postgresql/9.gif")} alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>
                    <aside className="col-md-4 blog-aside">
                        <div className="aside-widget">
                            <header>
                                <h3>Related</h3>
                            </header>
                            <div className="body">
                                <ul className="tales-list">
                                    <li><a href="https://www.postgresql.org/" target="_blank" rel="noopener noreferrer">PostgreSQL</a></li>
                                    <li><a href="https://www.pgadmin.org/" target="_blank" rel="noopener noreferrer">pgAdmin4</a></li>
                                    <li><a
                                        href="https://blog.digitalocean.com/announcing-managed-databases-for-postgresql/"
                                        target="_blank"  rel="noopener noreferrer"> DigitalOcean PostgreSQL</a></li>
                                </ul>
                            </div>
                        </div>
                    </aside>
                </div>
            </div>
        </div>
    )
        ;
}
export default PostgreSQLDetails;
