import React  from 'react';


const ContraPt4 =(props) => {
    return (
        <div className="widewrapper main">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 blog-main">
                        <article className="blog-post">
                            <header>
                                <h3>Contravariance and Covariance - Part 4</h3>
                                <br/>
                                <table className="table">
                                    <tr>
                                        <td>
                                            <div className="lead-image">
                                                <img src={require("../assets/img/Contr/br2.jpg")} alt="Riemann"
                                                     className="img-fluid img-responsive imgframe img-rounded"/>
                                                    <br/>
                                                    Bernhard Riemann
                                            </div>
                                        </td>
                                        <td>
                                            <div className="lead-image">
                                                <img src={require("../assets/img/Contr/gauss.jpg")} alt="Gauss"
                                                     className="img-fluid img-responsive imgframe img-rounded"/>
                                                    <br/>
                                                    Carl Friedrich Gauss
                                            </div>
                                        </td>
                                    </tr>
                                </table>


                                <div>
                                    <br/>
                                    <strong>Vector Concept 4 -- Vectors on Manifolds</strong>

                                </div>

                            </header>
                            <div className="body">
                                <div className="indented">
                                    Previously, our space was R<sup>n</sup> ,which has a globaly defined rectangular
                                    coordinate system.
                                    We expand the spaces under consideration to include
                                    spaces more general than Euclidean space --manifolds. The discussion becomes
                                    somewhat
                                    more technical, and as a minimum, basic familiarity with point
                                    set topology is assumed.
                                </div>

                                <div className="indented">
                                    Our underlying goal is apparently a rather modest one--
                                    to generalize R<sup>n</sup> and
                                    allow calculus to be done on this generalized space.
                                </div>
                                <div className="indented">
                                    In some sense, mathematicians were already doing calculus on non-Euclidean spaces
                                    prior to the formal definition of a manifold, since surfaces such as S<sup>2</sup>,
                                    the sphere,
                                    are non-Euclidean.
                                    However, S<sup>2</sup> is a subset of R<sup>3</sup>, but one would like to study
                                    surfaces
                                    (and in general, manifolds) intrinsically and not as subspaces of R<sup>n</sup>.
                                    Gauss in fact initialized this method of investigation when he discovered the
                                    geometry of S<sup>2</sup> &nbsp;could be ascertained from a study of its tangent
                                    vectors and their inner product
                                </div>
                                <div className="indented">
                                    The modern notion of a manifold, which started with the researches
                                    of Gauss and Riemann, took many years to
                                    be clarified. To understand that the modern definition of a manifold is, in some
                                    sense, the correct generalization of Euclidean space, requires some mathematical
                                    sophistication.
                                </div>
                                <div className="indented">Now for the relevant definitions:</div>
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">Topological Manifold</h5>
                                        A (topological) manifold M is a topological space that satisfies the
                                        following:<br/>
                                        i) M is locally homeomorphic to R <sup>n </sup><br/> i.e., for any point p ∈ M,
                                        there exists an open set U in M, with p ∈ U and a homeomorphism φ:
                                        U &rarr; R<sup>n</sup> onto an open subset of R<sup>n</sup>.&nbsp;<br/>
                                        ii) M is Hausdorff <br/>
                                        i.e., if x and y are points in M, then there exist
                                        open sets U and V in M such that x &isin; U, y &isin; V, and U &cap; V = &empty;
                                        <br/>
                                        iii) M has a countable basis of open sets.
                                    </div>
                                </div>
                                <br/>
                                <div className="indented">
                                    In the definition of a manifold, the topological constraints
                                    ii) and iii) are often given with minimal, if any explanation, so it behooves me to
                                    break with
                                    that tradition and give some comments:
                                </div>
                                <h5>Hausdorff Property</h5>
                                <div className="indented">
                                    First, if you require your space to be Hausdorff, it is
                                    in fact necessary to explicity require it, as the conditions &nbsp;i) and
                                    iii) &nbsp;are
                                    not sufficient by themselves to imply the topology is Hausdorff.
                                </div>
                                <div className="indented">Second, if
                                    you do not add some minimal constraints on the topology,
                                    then it is possible to construct topological
                                    spaces that exhibit topological properties that are counter to what one usually
                                    expects. So, as an example of a "usual" topological
                                    property, consider the reals
                                    R<sup>1</sup> with the standard topology. The standard topology on R<sup>1</sup> is
                                    generated by all intervals of the form (a,b) where a&lt;b. In this topology,
                                    any single point &#123;a&#125; is a closed set, and since the finite union of closed sets
                                    is closed, any finite number of points is closed. If we do NOT add any additional
                                    constraint on our manifold's topology, such as the Haudorff property, then it is
                                    possible to
                                    construct manifolds in which finite point sets are <em>not</em> closed.
                                </div>
                                <div className="indented">
                                    Third, the Hausdorff condition is generally considered by topologists
                                    to be a rather mild constraint.
                                </div>
                                <h5>Countable Basis</h5>
                                <div className="indented"> The requirement of a countable basis is not always given
                                    in the definition of a manifold. The Hausdorff condition was required to avoid
                                    pathological
                                    behavior and hence is almost universal. The requirement of a countable basis
                                    is desirable but the motivation is different. The countable basis implys the
                                    manifold's
                                    topology is metrizable, which
                                    from a geometric point of view, is a very nice property
                                    to have.
                                </div>
                                <div className="indented">
                                    The topological manifold so defined is a generalization of Euclidean space.
                                    For our purposes, it is not quite complete as it stands, because while we can do
                                    calculus in R<sup>n</sup>,
                                    we
                                    cannot do calculus in a mathematically <em>consistent</em> way on our topological
                                    manifold. To do that, we have to add one more mathematical construct -- a <em>
                                    differentiable
                                    structure
                                </em>.
                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">Chart</h5>
                                        Let S be a topological space. A <em>chart</em>
                                        is a pair (U,ψ) where U
                                        is an open set in S and ψ is a homeomorphism from U onto an open set in
                                        R<sup>n</sup>.
                                    </div>
                                </div>
                                <br/>
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">C<sup>&infin;</sup> Compatible</h5>
                                        Let S be a topological space.
                                        Two charts (U,ψ) and (V,φ) on
                                        S are <em>C<sup>&infin;</sup> compatible</em> if U ∩V = &empty;, or ψ °
                                        φ<sup>-1</sup>&nbsp; and &nbsp;φ ° ψ<sup>-1</sup> are C<sup>∞ </sup>
                                        functions.
                                    </div>
                                </div>
                                <br/>
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">Atlas</h5>
                                        Let S be a topolgical space.
                                        An atlas on S is a collection of C<sup>∞
                                    </sup>compatible charts &#123;(U <sub> i </sub>,ψ<sub>i</sub>)&#125; such
                                        that the U<sub>i</sub> cover M.
                                    </div>
                                </div>
                                <br/>
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">Maximal Atlas</h5>
                                        An atlas is <em>maximal</em> if it is not
                                        contained in a larger atlas.
                                    </div>
                                </div>
                                <br/>
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">Smooth Manifold</h5>
                                        A <em>smooth manifold</em> is a topological manifold together with a
                                        maximal atlas.
                                    </div>
                                </div>
                                <br/>
                                <div className="indented">
                                    All charts (i.e., coordinates) on a smooth manifold are treated equally and there is
                                    no preferred reference frame for the manifold, which bodes
                                    well for applications to relativity.
                                </div>
                                <div className="indented">
                                    When the context is clear, the adjectives smooth
                                    or C<sup>∞</sup> are dropped, and one simply refers to a <em>manifold</em>.&nbsp;The
                                    atlas for M is also known as the <em><b>differentiable structure</b></em> for M.
                                </div>
                                <br/>
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">Smooth Function</h5>
                                        If M is a smooth manifold, then a function &fnof;:M &rarr;
                                        R is <em>differentiable</em> (or <em>smooth</em>, or
                                        <em>C</em><sup>
                                        <em>∞</em>
                                    </sup>) at
                                        p ∈M&nbsp; if &fnof; o
                                        &phi; <sup>-1</sup> is differentiable&nbsp; for all charts&nbsp;(φ,U) with p ∈U.
                                    </div>
                                </div>
                                <br/>
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title"> F(M)</h5>
                                        Let F(M)
                                        be the set of all smooth
                                        real valued functions on M.
                                    </div>
                                </div>
                                <br/>
                                <div className="indented">
                                    F(M), with the usual notions of addition and multiplication, is a commutative ring.
                                    F(M) is not a field -- multiplicative inverses in general do not exist.
                                </div>
                                <div className="indented">
                                    There is more than one way to define a vector on a manifold. The following is one
                                    of the more elegant definitions, which emphasizes the Leibnitzian
                                    character of a vector, and so defines a vector &nbsp;to be a derivation on F(M).
                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">Tangent Vector</h5>
                                        Let M be a smooth manifold. A <em>tangent vector</em>
                                        <strong>v</strong> at p
                                        ∈M is a real valued function, <strong>v</strong>: F(M) →
                                        R, that is R-linear on F(M) and satisfies the Leibnitz property. In other words
                                        let f and g be elements
                                        of F(M), and a,b be in R. Then <strong>v</strong>
                                        is a tangent vector at p if<br/>
                                        <br/>
                                        i) <strong>v</strong>(af + bg) = a<strong>v</strong>(f) + b<strong>v</strong>(g)
                                        &nbsp; &nbsp; &nbsp;<br/>
                                        ii) <strong>v</strong>(fg) =<strong> v</strong>(f)g(p) + f(p)<strong>v</strong>(g)
                                    </div>
                                </div>
                                <br/>
                                <div className="indented"> Note that the definition depends entirely on objects defined
                                    on the manifold itself. It makes no reference to any ambient space. It is therefore
                                    an intrinsic definition.
                                </div>
                                <div className="indented">
                                    To connect this definition to the notion of a directed line
                                    segment in R<sup>n</sup>, consider the directional derivative of a function in
                                    R<sup>n</sup>
                                    . Let f: R<sup>n</sup>  &nbsp;→ R be a smooth real valued function,
                                    and <strong>v</strong>
                                    ∈R<sup>n</sup> be a unit vector in the sense of a directed line segment. Then
                                    the directional derivative of f in the direction of <strong>v</strong> is given
                                    by
                                    <strong>v</strong>(f) = <strong>∇</strong>f <sup>
                                    <strong>.</strong><span style={{fontSize: "8.4pt"}}>
                                <strong>&nbsp;</strong>
                            </span>
                                </sup><strong>v</strong>, and satisfies i) and ii). Thus, a directed line
                                    segment in R<sup>n</sup> can be understood as an operator on smooth functions
                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">Tangent Space</h5>
                                        The set of all tangent vectors at p form a vector space, called the <em>
                                        tangent space
                                        to M at p,
                                    </em>and is denoted by T<sub>p</sub>(M).
                                    </div>
                                </div>
                                <br/>
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">Dual Space</h5>
                                        The dual space T<sub>p</sub>(M)<sup>
                                        *&nbsp;
                                    </sup>is the set of all linear functionals on T<sub>p</sub>(M).
                                        Elements of T<sub>p</sub>(M)<sup>*</sup>are called <em>one-forms</em>.
                                    </div>
                                </div>
                                <br/>
                                <div className="indented">
                                    Elements of T<sub>p</sub>(M) are also called <em>covariant</em> vectors, and
                                    elements of
                                    <br/>
                                    T<sub>p</sub>(M)<sup>*</sup> also called <em>contravariant </em>vectors.
                                </div>
                                <br/>
                                <em> Next week -- Category Theory.</em>
                                <hr/>
                            </div>

                        </article>
                    </div>
                    <aside className="col-md-4 blog-aside">
                        <div className="aside-widget">
                            <header>
                                <h3>Related</h3>
                            </header>
                            <div className="body">
                                <ul className="tales-list">
                                    <li><a
                                        href="https://www.amazon.com/Introduction-Differentiable-Manifolds-Riemannian-Mathematics/dp/0121160513/ref=cm_cr_arp_d_product_top?ie=UTF8"
                                        target="_blank" rel="noopener noreferrer">An Introduction to Differentiable Manifolds and Riemannian
                                        Geometry</a></li>
                                </ul>
                            </div>
                        </div>
                    </aside>
                </div>
            </div>
        </div>
    )
        ;
}
export default ContraPt4;
