import React  from 'react';
//import {Link} from 'react-router-dom';



const AngularDetails =(props) => {
    return (
<div className="widewrapper main">
    <div className="container">
        <div className="row">
            <div className="col-md-8 blog-main">
                <article className="blog-post">
                    <header>
                        <h1>Angular</h1>
                        <div className="lead-image">
                            <img src={require("../assets/img/angular.png")} alt="angular" className="img-responsive imgframe img-rounded" />
                            <span>&nbsp;</span>
                        </div>
                    </header>
                    <div className="body">
                        <h3> Learning</h3>
                        <div className="indented">
                            When I was a graduate student in physics, I noticed one of my fellow graduate students would
                            learn a new computer language not by taking a course (usually C++) in the language, but by instead taking an existing
                            program (usually in FORTRAN) and <i>converting it</i> to the new language.
                            This method is effective, as long as you are not converting a trivial program, because the converted program provides
                            the necessary feedback. You can compare your converted program with the original.
                        </div>
                        <div className="indented">
                            According to Anders Ericsson, <b>feedback</b> is one of the nessessary components of developing expert performance.  Anders Ericsson is perhaps the world's foremost
                            expertise on expertise. His book <b>Peak: Secrets from the New Science of Expertise</b> I highly recommend.
                        </div>
                    </div>
                </article>
            </div>
            <aside className="col-md-4 blog-aside">
                <div className="aside-widget">
                    <header>
                        <h3>Related</h3>
                    </header>
                    <div className="body">
                        <ul className="tales-list">
                            <li><a href="https://angular.io/" target="_blank" rel="noopener noreferrer">Angular Docs</a></li>
                        </ul>
                    </div>
                </div>
            </aside>
        </div>
    </div>
</div>
    )
    ;
    }
export default AngularDetails;
