import React  from 'react';


const ContraPt5 =(props) => {
    return (
        <div className="widewrapper main">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 blog-main">
                        <article className="blog-post">
                            <header>
                                <h3>Contravariance and Covariance - Part 5</h3>
                                <br/>
                                <table className="table">
                                    <tr>
                                        <td>
                                            <div className="lead-image">
                                                <img src={require("../assets/img/Contr/Samuel_Eilenberg.jpg")} alt="Samuel Eilenberg"
                                                     className="img-fluid img-responsive imgframe img-rounded"/>
                                                    <br/>
                                                    Samuel Eilenberg
                                            </div>
                                        </td>
                                        <td>
                                            <div className="lead-image">
                                                <img src={require("../assets/img/Contr/SaundersMacLane2.jpg")} alt="Saunders Mac Lane"
                                                     className="img-fluid img-responsive imgframe img-rounded"/>
                                                    <br/>
                                                    Saunders Mac Lane
                                            </div>
                                        </td>
                                    </tr>
                                </table>


                                <div>
                                    <br/>
                                    <strong>Category Theory</strong>

                                </div>

                            </header>
                            <div className="body">
                                <div className="indented">
                                    Category Theory was developed by Eilenberg and MacLane <sup>[<a
                                    href="#one">1</a>]</sup> in algebraic
                                    topology. In a heuristic sense, it may be considered as the “mathematics of
                                    mathematics”. Different branches of mathematics formally share structural
                                    similarities, which category
                                    theory attempts to study.
                                </div>

                                <div className="indented">
                                    As an example of how different mathematical fields can have
                                    a similar structure, consider the mathematical subjects of topology, linear algebra,
                                    group theory, and differential geometry.
                                </div>
                                <div className="indented">
                                    Given a collection of open sets that form a topology,
                                    one of the questions topologists are interested in is what maps preserve the
                                    topological
                                    structure of the topology. Such functions turn out to be the homeomorphisms. In
                                    linear algebra, we are interested in those maps which preserve the vector space
                                    structure. These maps turn out to be the isomorphisms. In group theory, we are
                                    interested
                                    in maps which preserve the group structure. These maps turn out to be the
                                    homomorphisms.
                                    In differential geometry, we are interested in those maps which preserve the
                                    differential structure of smooth manifolds, and these turn out to be the
                                    diffeomorphisms.
                                </div>
                                <div className="indented">
                                    It is evident even to the casual observer that there is a commonality
                                    between the above examples. In general, we have a set of objects, and maps defined
                                    on those objects, such that the maps preserve the mathematical structure of the
                                    objects. Category theory, among other things, abstracts this commonality and makes
                                    it precise. This generality of category theory means that it can be possible to
                                    prove a theorem in general categorical terms, and possibly have it apply to all
                                    categories.
                                </div>
                                <div className="indented">Now, on to the definition of a category. But first, a little
                                    set theory trivia. In order to avoid any paradoxes that can arise from considering
                                    sets of sets, such as Russell’s Paradox, mathematicians will sometimes circumvent
                                    the issue by talking about “classes” of sets instead. That is why the definition
                                    given below defines a category in terms of a class of sets, and not as a set of
                                    sets.
                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">Category</h5>
                                        A c<em>ategory</em>
                                        is a class <strong>C</strong> of objects such that for any two objects A and
                                        B&nbsp; in <strong>
                                        C</strong>, there is a class of disjoint sets, Mor(A,B), the elements of which
                                        are called <em>morphisms</em> from A to B. If f is a morphism
                                        from A to B, it is denoted by
                                        f : A →B.
                                        <br/>
                                        The following properties are required to hold:
                                        <br/>
                                        <br/>
                                        a) Composition: For any three objects A,B and C, if f : A →B is a morphism from
                                        A to B, and g : B →C is a morphism from B to C, then there exists a morphism
                                        from
                                        A to C, denoted by g ◦ f and called the <em>composite</em> of f with g.
                                        <br/>
                                        <br/>
                                        b) Associativity: Composition of morphisms
                                        is required to satisfy the associative law:&nbsp; f ◦ (h ◦ g) = (f ◦ h) ◦ g
                                        <br/>
                                        <br/>
                                        c) Identity: For any object A of <strong>C</strong>, there exits a morphism
                                        1<sub>A</sub> : A →A such that if
                                        f : A → B is a morphism from A to B, then f ◦ 1<sub>A</sub> = f and if g : C → A
                                        is a morphism
                                        from C to A, then 1<sub>A</sub> ◦ g = g
                                    </div>
                                </div>
                                <br/>
                                <div className="indented">
                                    Examples of categories, as already alluded to, are the category of topological
                                    spaces,
                                    the category of vector spaces, the category of groups,
                                    and the category of smooth manifolds.
                                </div>

                                <div className="indented">
                                    Once again, the power of the idea is the following...it might be possible
                                    to prove a single theorem in category theory, and if it turns out the categorical
                                    conditions of the proof were present in the category of groups and the category
                                    of vector spaces, then as a special case, you have also just proved theorems in
                                    group theory and in linear algebra. Not too bad.
                                </div>
                                <div className="indented">
                                    But wait, as they say, there is more. Not just proofs, but
                                    mathematical constructions and definitions can be
                                    formulated in general categorical
                                    terms, which can be very helpful.
                                </div>
                                <div className="indented">
                                    So, for example, say you are a topologist, and suppose
                                    you were not sure of how you should define the <em>product topology</em> of two
                                    topological spaces A and B. There are in fact a couple of plausible definitions.
                                    The most obvious definition is the <em>box topology</em>, but it turns out the box
                                    topology is not the topology mathematicians generally use for products. The box
                                    topology works OK for finite products of topological spaces, but for arbitray
                                    products
                                    (i.e., infinite), it does not. Now, if you have in your toolbox the general
                                    categorical
                                    notion of a product, then you are more likely to discover the actual, correct for
                                    infinite products, product topology ... Again, not too bad.
                                </div>

                                <br/>
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">Covariant Functor</h5>
                                        Let <strong>C</strong>
                                        and <strong>D</strong> be categories. A <em>covariant
                                        functor</em> from <strong>C</strong>
                                        to <strong>D</strong> consists of two functions, an object function that maps
                                        objects
                                        in <strong>C </strong>to objects in <strong>D</strong>, and a morphism function
                                        that maps morphisms in <strong>C</strong> to morphisms in <strong>D</strong>. By
                                        convention, the <em>same</em> symbol F is used to designate both functions.<br/>
                                        <br/>
                                        So if f ∈ Mor(A,B) is a morphism in <strong>C</strong>, then F(f) ∈ Mor(F(A),
                                        F(B))
                                        is a morphism in <strong>D</strong>. F is also required to satisfy:<br/>
                                        <br/>
                                        i) if φ ∈ Mor(A,B) and ψ ∈ Mor(B,C) are morphisms in <strong>C</strong>,
                                        then<br/>
                                        &nbsp; &nbsp; F(φ ◦ ψ) = F(φ) ◦ F(ψ)<br/>
                                        <br/>
                                        ii) F(1<sub>A</sub>) = 1<sub>F(A)</sub> , for any object A in <strong>C</strong><br/>

                                    </div>
                                </div>

                                <br/>
                                <div className="indented">
                                    By making a minor change to the composition rule in (i) and
                                    also changing the direction of the morphism F(f) (i.e., reverse the arrow), we get
                                    the definition of
                                    a contravariant functor:
                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">Contravariant Functor</h5>
                                        Let <strong>C</strong> and <strong>D</strong>
                                        be categories. A <em>contravariant
                                        functor</em> from <strong>C</strong> to <strong>D</strong>
                                        consists of two functions, an object function that maps objects
                                        in <strong>C </strong>
                                        to objects in <strong>D</strong>, and a morphism function that maps morphisms in
                                        <strong>C</strong> to morphisms in <strong>D</strong>. By convention,
                                        the <em>same</em>
                                        symbol F is used to designate both functions.<br/>
                                        <br/>
                                        So if f ∈ Mor(A,B) is a morphism in <strong>C</strong>, then F(f) ∈ Mor(F(B),
                                        F(A)) is a morphism in <strong>D</strong>.
                                        F is also required to satisfy:<br/>
                                        <br/>
                                        i) if φ ∈ Mor(A,B) and ψ ∈ Mor(B,C) are morphisms in <strong>C</strong>,
                                        then<br/>
                                        &nbsp; &nbsp; F(φ ◦ ψ) = F(ψ) ◦ F(φ)<br/>
                                        <br/>
                                        ii) F(1<sub>A</sub>) = 1<sub>F(A)</sub> , for any object A in <strong>C</strong><br/>
                                    </div>
                                </div>


                                <div className="indented">

                                    <hr/>
                                    References:<br/>
                                    <br/>
                                    <a name="one"></a>
                                    [1] S. Eilenberg and S. MacLane, <em>General Theory of Natural Equivalences</em>.
                                    Trans. Am. Math. Soc. 58, 231-294 (1945). 1968.
                                    <hr/>

                                </div>

                            </div>

                        </article>
                    </div>
                    <aside className="col-md-4 blog-aside">
                        <div className="aside-widget">
                            <header>
                                <h3>Related</h3>
                            </header>
                            <div className="body">
                                <ul className="tales-list">
                                    <li><a
                                        href="https://www.amazon.com/Categories-Working-Mathematician-Graduate-Mathematics/dp/1441931236"
                                        target="_blank">Categories for the Working Mathematician</a></li>
                                </ul>
                            </div>
                        </div>
                    </aside>
                </div>
            </div>
        </div>
    )
        ;
}
export default ContraPt5;
