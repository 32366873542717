import React, { Component } from 'react';
//import ReactDOM from 'react-dom';
//import { Router, Route, browserHistory, IndexRoute } from 'react-router';
//import {Link} from 'react-router-dom';
import ArticleGeneric from './article-generic.jsx';
//import {Link} from "react-router-dom";

class Main extends Component {
    render() { 
        return ( 
        <div className="widewrapper main">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 blog-main">
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <ArticleGeneric ImageName="georgew" ReadMoreTo=""
                                                ReadMoreToText=""
                                                Body={<div align={"center"}>
                                                    <span style={{color:"red"}}>Happy </span>
                                                    <span style={{color:"blue"}}> 4th </span>
                                                    <span style={{color:"red"}}>of </span>
                                                    <span style={{color:"blue"}}>July!</span>
                                                    </div>}
                                                displaybutton="none">
                                </ArticleGeneric>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-sm-6">
                                <ArticleGeneric ImageName="santa" ReadMoreTo=""
                                                ReadMoreToText=""
                                                Body={<div align={"center"}>
                                                    <span style={{color:"red"}}>Merry Christmas!</span>
                                                </div>}
                                                displaybutton="none">

                                </ArticleGeneric>
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <ArticleGeneric ImageName="glauber"
                                                ReadMoreTo="Glauber"
                                                ReadMoreToText="Roy Glauber"
                                                Body={"Quantum computing and photon interference"}
                                                displaybutton="block">

                                </ArticleGeneric>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-sm-6">
                                <ArticleGeneric ImageName="turkey" ReadMoreTo="Thanksgiving"
                                                ReadMoreToText="Thanksgiving"
                                                Body={<div align={"center"}>Happy Thanksgiving</div>}>

                                </ArticleGeneric>
                            </div>
                            <div className="col-md-6 col-sm-6">
                                
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <ArticleGeneric ImageName="flag" ReadMoreTo=""
                                                ReadMoreToText="Remember"
                                                displaybutton={"none"}
                                                Body={[<div align={"center"}>September 11, 2019</div>]}>
                                </ArticleGeneric>

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <ArticleGeneric ImageName="rabi" ReadMoreTo="Rabi"
                                                ReadMoreToText="Israel Issac Rabi"
                                                Body={[<i>What's in a name?<br/>
                                                That which we call a rose<br/>
                                                By any other name <br/>
                                                Would smell as sweet. </i>,<br/>,
                                                "- Shakespeare"]}>

                                </ArticleGeneric>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-sm-6">
                                <ArticleGeneric ImageName="johnreynolds" ReadMoreTo="Contra"
                                                ReadMoreToText="Contravariance and Covariance"
                                                Body={"The ideas of contravariance, and covariance, long of great utility in physics, " +
                                                      "were introduced into C# beginning with .Net Framework 3.5... At first glance there seems " +
                                                      "to be no relation between the physics and computer science usages...It might therefore be of " +
                                                      "interest to show the history of these ideas and how they found their way into the theory of computer languages..."}>

                                </ArticleGeneric>
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <ArticleGeneric ImageName="townes"
                                                ReadMoreTo="Townes"
                                                ReadMoreToText="Charles Townes"
                                                Body={"The great Charles Townes (7/28/1915 - 1/27/2015) inventor of the maser/laser, celebrates a birthday this week."}>

                                </ArticleGeneric>
                            </div>
                        </div>
                            <div className="row">
                                <div className="col-md-6 col-sm-6">
                                    <ArticleGeneric ImageName="ufimtsev" ReadMoreTo="Ufimtsev"
                                                    ReadMoreToText="Pyotr Ufimtsev"
                                                    Body={"When I was a kid, we had a neighbor whose job it was to translate Russian " +
                                                    "literature. This seemed to me at the time to be a rather peculiar job. German and French, " +
                                                   " sure, but Russian was not particularly popular. Why Russian? In retrospect, given that this " +
                                                    "was during the time of the cold war between the US and the USSR, it should have been obvious..."}>

                                    </ArticleGeneric>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                    <ArticleGeneric ImageName="eltoncover"
                                                    ReadMoreTo="Elton"
                                                    ReadMoreToText="To Russia with Elton, 40 Years On"
                                                    Body={"In keeping with the Russian/Ukrainian motif of the last two articles, here is a look back at Elton John's historic 1979 tour of Russia."}>

                                    </ArticleGeneric>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <ArticleGeneric ImageName="scottishcafe"
                                                    ReadMoreTo="ScottishCafe"
                                                    ReadMoreToText="The Scottish Cafe"
                                                    Body={["AS an undergraduate mathematics student, " +
                                                    "I had a topology professor who would tell his students " +
                                                    "about the early days of topology. There was it seemed a cafe where " +
                                                    "the Polish mathematicians would congregate anddiscuss mathematics for hours on end.",<br/>,<br/>,
                                                        "When some mathematician stumbled upon a problem or conjecture that was interesting but he could not solve, " +
                                                    " he could ask the coffee house staff for a book that was " +
                                                    " kept there just for the mathematicians...and in which the problem would be recorded as a challenge " +
                                                     " for any mathematician who visited the coffee house. From this cafe " +
                                                    " sprung many of the early results of topology and measure theory, and the book became rather famous, at least among topologists..."
                                                    ,<br/>,<br/>," Well, it's a rather romantic story, but one that students tend to put aside as they concentrate on the job of actually learning the subject of topology! "
                                                        ,<br/>,<br/>,
                                                    " But fast forward to the present -- and that cafe is now a bank...and that part of Poland is now Ukraine, and the Polish city of Lwow where the coffee house was located " +
                                                    " is now the Ukrainian city of Lviv ( 'Lvov/Lviv' translated from Russian/Ukrainian means 'Lion'. By the way, " +
                                                        " if you visit Lviv, you will discover many wonderful statues and sculptures, some quite old, of lions.) ", <br/>,<br/>,
                                    " Having visited Lviv a few times I have tended to concentrate on exploring the usual tourist attractions...but on a recent visit, I had to see if I could find this Scottish Cafe...."]}>
                                    </ArticleGeneric>
                                </div>
                            </div>
                            <div className="row">
                            <div className="col-md-6 col-sm-6">
                                <ArticleGeneric ImageName="tuvabust" ReadMoreTo="Feynman"
                                                ReadMoreToText="Richard Feynman, Corrected"
                                                Body={"A while ago, Vladimir Putin was shown on Russian television cavorting around Kyzyl, Tuva. " +
                                                "(The same Tuva after which Microsoft named its excellent Tuva Project). Tuva was made famous in the physics world in 1991 " +
                                                " by the publication of 'Tuva or Bust, Richard Feynman's Last Journey'... At the time, the Tuva media attention caused me " +
                                                " to dust off and re-read that book...leading, interestingly enough, to the discovery of a previously unknown, but unfortunately somewhat widely propagated, " +
                                                " Feynman error. Yeah, Feynman published an error. So here is the story, along with my correction..."}>

                                </ArticleGeneric>
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <ArticleGeneric ImageName="hamilton"
                                                ReadMoreTo="Hamilton"
                                                ReadMoreToText="Hamilton"
                                                Body="Recently, I talked to somebody who mentioned they had worked with coordinate systems and gimbals, which reminded me
                                                I have a backlog of old blog entries that have not been ported to .Net core... Here then is the port of an old post, on the
                                                great William Rowan Hamilton, quaternions, and gimbal lock...">

                                </ArticleGeneric>
                            </div>
                            </div>
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <ArticleGeneric ImageName="khan1" ReadMoreTo="TestDome" ReadMoreToText="He Tasks Me!" Body="The Wrath of Khan, Captain Kirk, and solving computer problems..."></ArticleGeneric>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-sm-6">
                                <ArticleGeneric ImageName="linuxsql1" ReadMoreTo="LinuxSQL" ReadMoreToText="SQL Server" Body="Linux and SQL Server"></ArticleGeneric>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                <ArticleGeneric ImageName="dotnetcore" ReadMoreTo="PostgreCore" ReadMoreToText="PostgreSQL" Body="PostgreSQL and .NET Core"></ArticleGeneric>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-sm-6">
                                <ArticleGeneric ImageName="elephant" ReadMoreTo="PostgreSQL" ReadMoreToText="PostgreSQL" Body="PostgreSQL and Digital Ocean..."></ArticleGeneric>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                <ArticleGeneric ImageName="react" ReadMoreTo="React" ReadMoreToText="React" Body="React,Docker,and K8s..."></ArticleGeneric>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-sm-6">
                                <ArticleGeneric ImageName="golang" ReadMoreTo="Golang" ReadMoreToText="Golang" Body="Golang, built for Docker, k8s, and microservices...."></ArticleGeneric>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                <ArticleGeneric ImageName="k8s" ReadMoreTo="DockerKubernetes" ReadMoreToText="Docker and Kubernetes" Body="K8s and microservices are the future of the enterprise..."></ArticleGeneric>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-sm-6">
                                <ArticleGeneric ImageName="dockerlogo" ReadMoreTo="Docker" ReadMoreToText="Containerization and .Net Core" Body=""></ArticleGeneric>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                <ArticleGeneric ImageName="strumia" ReadMoreTo="Strumia" ReadMoreToText="The Alessandro Strumia Affair" Body=""></ArticleGeneric>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 col-sm-6">
                                <ArticleGeneric ImageName="gauss" ReadMoreTo="ComplexNumbers" ReadMoreToText="Complex Numbers" Body="Our ultimate goal is to explain, simply, how the classical idea of a bit -- something that can have a value of only zero or one -- may be extended to a quantum system. Because quantum mechanics uses 'complex numbers', it is natural to start there... "></ArticleGeneric>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                <ArticleGeneric ImageName="dirac" ReadMoreTo="QuantumBits" ReadMoreToText="Quantum Bits" Body="The quantum analog of a two valued sytem gives rise to the idea of a quantum bit, also known as a qubit."></ArticleGeneric>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-sm-6">
                                <ArticleGeneric ImageName="boole2" ReadMoreTo="BitsAndAllThat" ReadMoreToText="Bits, Quantum Bits, And All That"
                                 Body="Bits are familiar to many. Quantum bits (or qubits) are perhaps not so well known. They are neeeded for quantum computation, so we will take a look...  "></ArticleGeneric>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                <ArticleGeneric ImageName="foleybook" ReadMoreTo="FoleyRotation" ReadMoreToText="Computer Graphics, Rotations, and lazy Professors"
                                 Body="A standard text on computer graphics is 'Introduction to Computer Graphics' by Foley, Van Dam, Feiner, Hughes, and Phillips. Even though it was published... "></ArticleGeneric>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-sm-6">
                                <ArticleGeneric ImageName="angular" ReadMoreTo="Angular" ReadMoreToText="Angular 6" 
                                Body="This site has been converted to Angular 6"></ArticleGeneric>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                <ArticleGeneric ImageName="orion" ReadMoreTo="Orion" ReadMoreToText="Orion and Mars" Body="Sometime ago, NASA launched its Orion rocket, with the goal of sending man to Mars. A cost and benefit analysis is interesting... "></ArticleGeneric>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-sm-6">
                                <ArticleGeneric ImageName="magrealitybw" ReadMoreTo="Magic" ReadMoreToText="Magic of Reality Review" 
                                Body="A review of Dawkins' work that he wrote for a younger audience. A careful analysis of Dawkins' work is given. Dawkins' book utlimately turned out to be a missed opportunity for Dawkins."></ArticleGeneric>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
             );
    }
}
 
export default Main;
