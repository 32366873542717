import React  from 'react';


const ShakespeareDetails =(props) => {
    return (
       <div> need to investigate Math JX for react</div>
    )
        ;
}
export default ShakespeareDetails;
