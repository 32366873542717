import React  from 'react';


const ContraPt3 =(props) => {
    return (
        <div className="widewrapper main">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 blog-main">
                        <article className="blog-post">
                            <header>
                                <h3>Contravariance and Covariance - Part 3</h3>
                                <br/>
                                <div className="lead-image">
                                    <img src={require("../assets/img/Contr/Einstein.png")} alt="Einstein"
                                         className="img-fluid img-responsive imgframe img-rounded"/>
                                        <br/>
                                        Albert Einstein<br/>
                                        Tensor Calculus
                                </div>
                                <div>
                                    <br/>
                                    <strong>Vector Concept 3 -- Classical Tensor Analysis in R<sup>n</sup></strong>

                                </div>

                            </header>
                            <div className="body">
                                <div className="indented">
                                    Our transformations of coordinates have hitherto mostly involved constants
                                    in the change of coordinates transformation matrix. We now <i>extend the allowed
                                    transformations</i> to include more
                                    general functions, from which we
                                    get yet another definition of a vector.
                                </div>

                                <div className="indented">
                                    It will turn out, for general
                                    non-cartesian coordinates, there are two possible transformation laws. One law
                                    describes
                                    <em>contravariant</em> vectors, and the other <em>covariant</em> vectors.
                                </div>
                                <div className="indented">
                                    While part 2 required some knowledge of algebra, this part will require some
                                    knowledge of
                                    multi-variable calculus. For brevity, I give only the essential concepts. As a
                                    minimum,
                                    some knowledge of the chain rule would be needed to follow along.
                                </div>
                                <div className="indented">
                                    <strong>Curvilinear Coordinates</strong><br/>
                                    <br/>
                                    Let x<sup>1</sup>,...,x<sup>n</sup> be a rectangular coordinate system in
                                    R<sup>n</sup>.
                                    (It should perhaps be remarked that it in general mathematical spaces, it is not
                                    always possible to find such a coordinate sytem. The existence of a global
                                    rectangular
                                    coordinate system is equivalent to assuming the space is Euclidean.)
                                    Consider the general transformation of coordinates to a new, not necessarily
                                    rectangular,
                                    coordinate system y<sup>1</sup>,...,y<sup>n</sup>:<br/>
                                </div>
                                <div style={{padding: "20px",textAlign: "center"}}>
                                    <table>
                                        <tr>
                                            <td style={{width: "200px"}}>
                                                y<sup>1</sup> = y<sup>1</sup>(x<sup>1</sup>,...,x<sup>n</sup>)<br/>
                                                ... &nbsp; &nbsp;&nbsp; ...............<br/>
                                                ... &nbsp; &nbsp;&nbsp; ...............<br/>
                                                ... &nbsp; &nbsp;&nbsp; ...............<br/>
                                                y<sup>n</sup> = y<sup>n</sup>(x<sup>1</sup>,...,x<sup>n</sup>)
                                                <br/>
                                            </td>
                                            <td style={{width: "200px"}}>
                                                (Eq. 1)
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <br/>
                                <div className="indented">
                                    We require certain technical conditions in order that our new coordinate system be
                                    well defined. In
                                    particular, the transformation should be differentiable
                                    and have a differentiable inverse. Sufficient conditions for this are
                                    given by the implicit function theorem -- the Jacobian of the transformation
                                    should be non-zero. The y-coordinate
                                    system so defined
                                    constitutes a <em>curvilinear coordinate system</em>.
                                </div>
                                <div className="indented">
                                    Now that curvilinear coordinates have been introduced, we can consider
                                    transformations from one curvilinear coordinate system to a different
                                    curvilinear coordinate system...
                                    which brings us to the classical idea of a tensor and the corresponding definitions:

                                </div>

                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">Scalar</h5>
                                        A function f(x<sup>1</sup>,...,x<sup>n</sup>)
                                        is a <em>scalar</em> if under a change of coordinates (1), it does not change
                                        value.
                                    </div>
                                </div>
                                <br/>
                                <div className="indented">
                                    In other words, if f<sup>&prime;</sup>, or f prime, is the value in the new
                                    y-coordinate system (f prime is not the derivative!),
                                    <div style={{padding: "20px",textAlign:"center"}}>
                                        f<sup>&prime;</sup>(y<sup>1</sup>,...,y<sup>n</sup>) =
                                        f(x<sup>1</sup>,...,x<sup>n</sup>)
                                    </div>

                                </div>
                                <div className="indented">

                                    Typical examples of scalars are mass and temperature, which are independent of
                                    choice
                                    of coordinates. A scalar is also called a <i> tensor of rank 0
                                </i>. Vectors, which we now define, are <i>tensors of rank 1</i>.
                                </div>

                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">Contravariant Vector</h5>
                                        A <em>contravariant vector</em> is a set
                                        of functions v<sup>k</sup>&nbsp; that, under a change of coordinates, transform
                                        as
                                        <div style={{padding: "20px",textAlign: "center"}}>
                                            <table>
                                                <tr>
                                                    <td style={{width: "100px"}}>
                                                        <img src={require("../assets/img/Contr/contrarule.png")} alt=""/>
                                                    </td>
                                                    <td style={{width: "100px"}}>
                                                        (Eq. 2)
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        where the Einstein summation convention has been used to indicate a summation
                                        over
                                        the repeated index l
                                    </div>
                                </div>
                                <br/>
                                <div>
                                    <div className="card">
                                        <div className="card-body">
                                            <h5 className="card-title">Covariant Vector</h5>
                                            A <em>covariant vector</em> is a set of
                                            functions v<sub>k</sub> &nbsp; that, under a change of coordinates,
                                            transform as

                                            <div style={{padding: "20px",textAlign: "center"}}>
                                                <table>
                                                    <tr>
                                                        <td style={{width: "100px"}}>
                                                            <img src={require("../assets/img/Contr/covarrule.png")} alt=""/>
                                                        </td>
                                                        <td style={{width: "100px"}}>
                                                            (Eq. 3)
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                            where the Einstein summation convention has been used to indicate a
                                            summation over
                                            the repeated index l.<br/>
                                        </div>
                                    </div>
                                </div>
                                <div className="indented">

                                </div>
                                <div className="indented">
                                    The origin of the above definitions may not at first be obvious. However, if we
                                    expand the standard position
                                    "vector" <strong>P</strong>(x<sup>1</sup>,...x<sup>n</sup>) in the standard
                                    rectangular coordinate basis &#123;
                                    <strong>i</strong><sub>1</sub>,...,<strong>i</strong><sub>n</sub>} ,the relation to
                                    our previous definitions becomes more apparent.<br/>
                                    <div style={{padding: "20px",textAlign: "center"}}>
                                        <table>
                                            <tr>
                                                <td style={{width: "244px"}}>
                                                    <img src={require("../assets/img/Contr/positionvec1.png")} alt=""/></td>
                                                <td style={{width: "225px"}}>
                                                    (Eq. 4)
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    &nbsp;we see the rectangular basis "vectors", &#123;
                                    <strong>i</strong><sub>1</sub>,...,<strong>i</strong><sub>n</sub>},
                                    &nbsp;are given by
                                    <div style={{padding: "20px",textAlign: "center"}}>
                                        <table>
                                            <tr>
                                                <td style={{width: "335px"}}>
                                                    <img src={require("../assets/img/Contr/rectbasisvec.png")} alt="" /></td>
                                                <td style={{width: "283px"}}>
                                                    (Eq. 5)
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    Now, changing coordinates to y-coordinate system, we find
                                    <div style={{padding: "20px",textAlign: "center"}}>
                                        <table>
                                            <tr>
                                                <td style={{width: "285px"}}>
                                                    <img src={require("../assets/img/Contr/ycoordvector.png")} alt="" /></td>
                                                <td style={{width: "273px"}}>
                                                    (Eq. 6)
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    Examining this equation for<strong> i</strong><sub>k</sub>, we recognize<br/>
                                    <div style={{padding: "20px",textAlign: "center"}}>
                                        <table>
                                            <tr>
                                                <td style={{width: "345px"}}>
                                                    <img src={require("../assets/img/Contr/gvectors.png")} alt=""/></td>
                                                <td style={{width: "303px"}}>
                                                    (Eq. 7)
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    as the tangent vector to the y<sup>m</sup> coordinate curve. The set of vectors
                                    <strong>g</strong><sub>m</sub> &nbsp;&nbsp; are known as the curvilinear basis
                                    vectors for the y-coordinate
                                    system, and form a basis for R<sup>n</sup> (That they form a basis
                                    is gauranteed by virtue of the fact that the Jacobian of the transformation is
                                    non-zero). Therefore, any vector <strong>v</strong> in R<sup>n</sup> can be expanded
                                    as
                                    <div style={{padding: "20px",textAlign: "center"}}>
                                        <table>
                                            <tr>
                                                <td style={{width: "383px", height: "42px"}}>
                                                    <img src={require("../assets/img/Contr/vcurvi.png")} alt="" /></td>
                                                <td style={{width: "307px", height: "42px"}}>
                                                    (Eq. 8)
                                                </td>
                                            </tr>
                                        </table>
                                    </div>

                                    <div className="indented">
                                        The v<sup>k</sup> are the contravariant components of <strong>v</strong>.
                                        Denoting the dual basis to the &#123;<strong>g</strong><sub>m</sub>&#125; by &#123;
                                        <strong>g</strong><sup>m</sup>&#125;,
                                        we can expand <strong>v</strong> in the dual basis as
                                    </div>
                                    <div style={{padding: "20px",textAlign: "center"}}>
                                        <table>
                                            <tr>
                                                <td style={{width: "405px"}}>
                                                    <img src={require("../assets/img/Contr/vcurvi2.png")} alt=""/></td>
                                                <td style={{width: "308px"}}>
                                                    (Eq. 9)
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <br/>The v<sub>k</sub> are the covariant components of <strong>v</strong>.
                                </div>
                                <div className="indented">
                                    It might come as a surprise that in the most general case in this definition, when
                                    non-orthognal transformations are allowed, a finite directed line segment will not
                                    in general obey the transformation laws (2) or (3) and so is NOT a vector.
                                    However, <i>infinitesimal</i> directed line segments
                                    are vectors.<br/>
                                    <br/>
                                    &nbsp; &nbsp;&nbsp;
                                    This whole analysis has assumed the existence of a global rectangular coordinate
                                    system, or in other words, that the space we are considering is Euclidean. Easing
                                    this restriction, and considering non-Euclidean spaces, leads us to the study of
                                    vectors defined on more general spaces called manifolds.<br/>
                                    <br/>
                                    <br/>
                                    <em> Next week -- Vectors on Manifolds.</em>
                                </div>
                            </div>
                        </article>
                    </div>
                    <aside className="col-md-4 blog-aside">
                        <div className="aside-widget">
                            <header>
                                <h3>Related</h3>
                            </header>
                            <div className="body">
                                <ul className="tales-list">
                                    <li><a
                                        href="https://www.amazon.com/Absolute-Differential-Calculus-Tensors-Mathematics-ebook/dp/B00DLYUO5I/ref=sr_1_13?keywords=tensor+analysis+dover&qid=1565321824&s=books&sr=1-13"
                                        target="_blank" rel="noopener noreferrer">The Absolute Differential Calculus</a></li>
                                    <li><a
                                        href="https://www.amazon.com/Tensor-Analysis-Theory-Applications-Sokolnikoff/dp/B00EAZYD6I/ref=sr_1_7?qid=1565321609&refinements=p_27%3AI+S+Sokolnikoff&s=books&sr=1-7&text=I+S+Sokolnikoff"
                                        target="_blank" rel="noopener noreferrer">Tensor Analysis Theory and Applications</a></li>

                                </ul>
                            </div>
                        </div>
                    </aside>
                </div>
            </div>
        </div>
    )
        ;
}
export default ContraPt3;
