import React  from 'react';
//import {Link} from 'react-router-dom';



const QuantumBitsDetails =(props) => {
    return (
<div className="widewrapper main">
    <div className="container">
        <div className="row">
            <div className="col-md-8 blog-main">
                <article className="blog-post">
                    <header>
                        <h1>Quantum Bits</h1>
                        <div className="lead-image">
                            <img src={require("../assets/img/physics/bits/diracboard.jpg")} alt="" className="img-responsive imgframe img-rounded" />
                            <span>Paul Dirac - Formulation of Quantum Mechanics</span>
                            <br />
                        </div>
                    </header>
                    <div className="body">
                        <h3>Introduction</h3>
                        <div id="content">
                            <div className="indented">
                                A discussion of quantum bits is going to require a little math and physics. The language of
                                physics is math, so this is unavoidable. However, I will attempt to fill in these prerequisites,
                                so that this could be read by a motivated junior high student.
                            </div>

                            <div className="indented">
                                The master of this type of exposition, by the way, was Feynman. When you read his book  "Quantum Electrodynamics, The Strange Theory of Light and Matter"
                                you see he is able to explain a subject by the device of simply drawing arrows on a piece of paper. That is quite an accomplishment,
                                as quantum electodynamics is normally considered an advanced and difficult part of physics, and taught to physics graduate students. It takes him over 150 pages to do it,
                                but it is still impressive. Of course, when you are the person who <i>invented</i> the theory,
                                as Feynman was, then you can do that type of exposition.
                            </div>

                            <div className="indented">
                                Here then is a list of what will be covered:
                                <ul>
                                    <li>complex numbers</li>
                                    <li>probability</li>
                                    <li>arrays and matrices</li>
                                    <li>some linear algebra</li>
                                    <li>fundamentals of quantum mechanics</li>
                                    <li>two state quantum systems</li>
                                </ul>
                            </div>

                            <div className="indented">
                                ...Next time -- complex numbers
                            </div>
                        </div>
                    </div>
                </article>
            </div>
            <aside className="col-md-4 blog-aside">
                <div className="aside-widget">
                    <header>
                        <h3>Related</h3>
                    </header>
                    <div className="body">
                        <ul className="tales-list">
                            <li><a href="https://www.amazon.com/QED-Strange-Theory-Light-Matter-ebook/dp/B00BR40XJ6" target="_blank" rel="noopener noreferrer">QED: The Strange Theory of Light and Matter</a></li>
                        </ul>
                    </div>
                </div>
            </aside>
        </div>
    </div>
</div>



    );
};


export default QuantumBitsDetails;