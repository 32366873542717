import React  from 'react';
//import {Link} from 'react-router-dom';



const Footer =(props) => {
    return (
        <footer>
        <div className="widewrapper copyright">
            <div className="container">
             
                <div>
                    <div className="row">
                        <div className="col-md-4 footer-widget">
                            <h3> <i className="icon-cog"></i>Statistics</h3>
                            <div className="stats">
                                <div className="line">
                                    <span className="counter">12</span>
                                    <span className="caption">Articles</span>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 footer-widget">
                            <h3> <i className="icon-star"></i>Links</h3>
                            <p>Interesting and clickworthy sites:</p>
                            <div>
                                <ul className="tales-list">
                                    <li><a href="http://einsteinpapers.press.princeton.edu/" target="_blank" title="Einstein Online" rel="noopener noreferrer">Collected Papers of Einstein</a></li>
                                    <li><a href="http://www.math.columbia.edu/~woit/wordpress/" target="_blank" rel="noopener noreferrer">Not Even Wrong</a></li>
                                    <li><a href="http://theahafoundation.org/" target="_blank" rel="noopener noreferrer">The AHA Foundation.</a></li>
                                    <li><a href="http://www.fnal.gov/" target="_blank" rel="noopener noreferrer">Fermi Lab</a></li>
                                    <li><a href="http://research.microsoft.com/en-us/labs/stationq/" target="_blank" rel="noopener noreferrer">Microsoft Research Station Q</a></li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-md-4 footer-widget">
                            <h3> <i className="icon-cog"></i>Contact Me</h3>

                            <span>You can reach me at:</span>

                            <span className="email">
                                <a href="mailto:larrymusa@mail.com">larrymusa@mail.com</a>
                            </span>
                        </div>
                    </div>

                </div>
                Copyright Larry Musa 2020
            </div>
        </div>
        
    </footer>
    )
    ;
};

export default Footer;
