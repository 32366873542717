import React, { Component } from 'react';
import {Route,Switch} from 'react-router-dom';
import './App.css';
import Main from './components/Main'
import PhysicsDetails from './components/PhysicsDetails'
import CargoCultScience from './components/CargoCultScience'
import NavBar from './components/navBar';
import Footer from './components/Footer';
import GolangDetails from './components/GolangDetails';
import DockerAndK8sDetails from './components/DockerAndK8sDetails';
import DockerDetails from './components/DockerDetails';
import StrumiaDetails from './components/StrumiaDetails';
import ComplexNumberDetails from './components/ComplexNumbers';
import QuantumBitsDetails from './components/QuantumBitsDetails';
import BitsAndAllThat from './components/BitsAndAllThatDetails';
import FoleyRotation from './components/FoleyRotDetails';
import AngularDetails from './components/AngularDetails';
import OrionDetails from './components/OrionDetails';
import Magic from './components/MagicDetails';
import Casimir from './components/Casimir';
import Mossbauer from './components/Mossbauer';
import PostgreSQLDetails from './components/PostgreSQLDetails';
import ReactDetails from "./components/ReactDetails";
import LinuxSQLDetails from "./components/LinuxSQLDetails";
import PostgreCore from "./components/PostgreCore";
import TestDomeDetails from "./components/TestDomeDetails";
import FeynmanDetails from "./components/FeynmanDetails";
import HamiltonDetails from "./components/HamiltonDetails";
import ScottishCafeDetails from "./components/ScottishCafeDetails";
import UfimtsevDetails from "./components/UfimtsevDetails";
import EltonDetails from "./components/EltonDetails";
import ContraDetails from "./components/ContraDetails";
import TownesDetails from "./components/TownesDetails";
import RabiDetails from "./components/RabiDetails";
import ThanksgivingDetails from "./components/ThanksgivingDetails";
import ShakespeareDetails from "./components/ShakespeareDetails";
import GlauberDetails from "./components/GlauberDetails";
import ContraPt1 from  "./components/ContraPt1";
import ContraPt2 from  "./components/ContraPt2";
import ContraPt3 from  "./components/ContraPt3";
import ContraPt4 from  "./components/ContraPt4";
import ContraPt5 from  "./components/ContraPt5";
import ContraPt6 from  "./components/ContraPt6";

class App extends Component {
  render() {
    return (
      <React.Fragment>
         <NavBar/>
      <div>
          <div className="content">
          <Switch>
              <Route path="/PostgreSQL" exact component={PostgreSQLDetails} />
              <Route path="/Golang" exact component={GolangDetails} />
              <Route path="/DockerKubernetes" exact component={DockerAndK8sDetails} />
              <Route path="/Docker" exact component={DockerDetails} />
              <Route path="/Strumia" exact component={StrumiaDetails} />
              <Route path="/ComplexNumbers" exact component={ComplexNumberDetails} />
              <Route path="/QuantumBits" exact component={QuantumBitsDetails} />
              <Route path="/BitsAndAllThat" exact component={BitsAndAllThat} />
              <Route path="/FoleyRotation" exact component={FoleyRotation} />
              <Route path="/Angular" exact component={AngularDetails} />
              <Route path="/Orion" exact component={OrionDetails} />
              <Route path="/Magic" exact component={Magic} />
              <Route path="/Physics" exact component={PhysicsDetails} />
              <Route path="/CargoCultScience" exact component={CargoCultScience} />
              <Route path="/Casimir" exact component={Casimir} />
              <Route path="/Mossbauer" exact component={Mossbauer} />
              <Route path="/React" exact component={ReactDetails}/>
              <Route path="/LinuxSQL" exact component={LinuxSQLDetails}/>
              <Route path="/PostgreCore" exact component={PostgreCore}/>
              <Route path="/TestDome" exact component={TestDomeDetails}/>
              <Route path="/Feynman" exact component={FeynmanDetails}/>
              <Route path="/Hamilton" exact component={HamiltonDetails}/>
              <Route path="/ScottishCafe" exact component={ScottishCafeDetails}/>
              <Route path="/Ufimtsev" exact component={UfimtsevDetails}/>
              <Route path="/Elton" exact component={EltonDetails}/>
              <Route path="/Contra" exact component={ContraDetails}/>
              <Route path="/Townes" exact component={TownesDetails}/>
              <Route path="/Rabi" exact component={RabiDetails}/>
              <Route path="/Thanksgiving" exact component={ThanksgivingDetails}/>
              <Route path="/Shakespeare" exact component={ShakespeareDetails}/>
              <Route path="/Glauber" exact component={GlauberDetails}/>
              <Route path="/Contra1" exact component={ContraPt1}/>
              <Route path="/Contra2" exact component={ContraPt2}/>
              <Route path="/Contra3" exact component={ContraPt3}/>
              <Route path="/Contra4" exact component={ContraPt4}/>
              <Route path="/Contra5" exact component={ContraPt5}/>
              <Route path="/Contra6" exact component={ContraPt6}/>
              <Route path="/" exact component={Main} />
            </Switch>
          </div>
      </div>
      <Footer/>
      </React.Fragment>
     
    );
  }
}

export default App;
